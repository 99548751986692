import {API_BASE_URL} from "../../constants";
import {request, requestFile} from "../../util/APIUtils";

/**
 * [관리자] 교과_통합DB 목록조회
 *
 * @param earlydStudentRequest
 * @returns {Promise<* | void>}
 */
export function requestEarlydSubjectList(earlydStudentRequest) {
    return request({
        url: API_BASE_URL + "/admin/earlyd/subject/list",
        method: 'POST',
        body: JSON.stringify(earlydStudentRequest)
    });
}


/**
 * [관리자] 교과_통합DB row 삭제
 *
 * @param earlydStudentRequest
 * @returns {Promise<* | void>}
 */
export function requestRemoveEarlydSubject(earlydStudentRequest) {
    return request({
        url: API_BASE_URL + "/admin/earlyd/subject/delete",
        method: 'POST',
        body: JSON.stringify(earlydStudentRequest)
    });
}

/**
 * [관리자] 교과_통합DB 엑셀업로드
 *
 * @param file
 * @returns {*}
 */
export function requestEarlydSubjectExcelUpload(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("columnCount", 142);

    return requestFile(
        {
            url: API_BASE_URL + "/admin/earlyd/subject/excelupload",
            method: 'POST',
        },
        formData
    )
}