import React from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import {ButtonGroup} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

/**
 * 쿠폰 관리 UI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CouponUI(props) {

    return (
        <>
            <Box sx={{display: "flex", height: 40, width: '100%', justifyContent: "flex-end", marginBottom: "10px"}}>
                <Box sx={{
                    justifyContent: 'flex-end',
                    flexDirection: "coulmn",
                    alignItems: "center",
                    marginRight: "10px"
                }}>
                    <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        variant="filled"
                        size="small"
                        onChange={props.onChangeSearchWord}
                        sx={{marginLeft: "10px", marginRight: "10px"}}
                    />
                    <Button variant="contained" onClick={props.onClickSearch} endIcon={<SearchIcon/>}>
                        검색
                    </Button>
                </Box>

                <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{paddingLeft: 3}}>
                    <Button onClick={props.handleClickedOpen} type="submit">
                        추가
                    </Button>
                    <>
                        <Dialog
                            // disableBackdropClick
                            open={props.openCouponDialog}
                            onClose={props.handleClosed}
                            aria-labelledby="form-dialog-title"
                            fullWidth={true}
                        >
                            <DialogTitle id="form-dialog-title">
                                {props.couponData.id ? "쿠폰 정보 수정" : "쿠폰 정보 등록"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {props.couponData.id ? "쿠폰 정보 수정" : "쿠폰 정보 등록"}
                                </DialogContentText>
                                <div>
                                    <TextField
                                        margin="dense"
                                        id="couponNumber"
                                        name="couponNumber"
                                        label="쿠폰번호"
                                        type="text"
                                        defaultValue={props.couponData.couponNumber}
                                        value={props.couponData.couponNumber}
                                        onChange={props.handleChanged}
                                        disabled={true}
                                        aria-readonly={true}
                                        fullWidth
                                    />
                                </div>
                                <div sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "1px solid black",
                                    padding: "7px",
                                    marginTop: "10px"
                                }}>
                                    <InputLabel id="demo-simple-select-label">적용 서비스명</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="payServiceId"
                                        name="payServiceId"
                                        value={props.couponData.payServiceId}
                                        label="상품 정보"
                                        onChange={props.handleChanged}
                                        disabled={props.couponData.id ? true : false}
                                        fullWidth
                                    >
                                        {
                                            props.serviceList !== undefined ? props.serviceList.map((item) => {
                                                return (<MenuItem key={item.id}
                                                                  value={item.id}>{item.productNm}</MenuItem>);
                                            }) : ""
                                        }
                                    </Select>
                                </div>
                                <div>
                                    <TextField
                                        margin="dense"
                                        id="discountInfo"
                                        name="discountInfo"
                                        label="쿠폰할인 정보"
                                        type="text"
                                        defaultValue={props.couponData.discountInfo}
                                        value={props.couponData.discountInfo}
                                        onChange={props.handleChanged}
                                        fullWidth
                                    />
                                </div>
                                <div>
                                    <TextField
                                        sx={{marginTop: "10px", width: 150}}
                                        id="discountValue"
                                        name="discountValue"
                                        label="쿠폰할인 값"
                                        type="number"
                                        InputProps={{inputProps: {min: 0, max: 100}}}
                                        variant="outlined"
                                        defaultValue={props.couponData.discountValue}
                                        value={props.couponData.discountValue}
                                        onChange={props.handleChanged}
                                        fullWidth
                                    />
                                </div>
                                <div>
                                    <TextField
                                        sx={{marginTop: "10px", width: 150}}
                                        id="numberOfAvailable"
                                        name="numberOfAvailable"
                                        label="쿠폰갯수"
                                        type="number"
                                        InputProps={{inputProps: {min: 1, max: 1000}}}
                                        variant="outlined"
                                        defaultValue={props.couponData.numberOfAvailable}
                                        value={props.couponData.numberOfAvailable}
                                        onChange={props.handleChanged}
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                {props.couponData.id ?
                                    <Button onClick={props.saveCoupon} variant="contained" color="warning">
                                        수정
                                    </Button>
                                    :
                                    <Button onClick={props.saveCoupon} variant="contained" color="primary">
                                        등록
                                    </Button>
                                }
                                <Button onClick={props.handleClosed} color="primary">
                                    닫기
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>

                    <Button color="error" onClick={props.onClickDeleteButton}>삭제</Button>
                </ButtonGroup>
            </Box>
            <Box sx={{height: 400, width: '100%'}}>
                <Typography>
                    {props.gridOption.title}
                </Typography>
                <DataGrid
                    sx={{
                        boxShadow: 2,
                        border: 2,
                        borderColor: 'primary.light', '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        }
                    }}
                    autoHeight
                    density={"compact"}
                    components={{Toolbar: GridToolbar}}
                    rows={props.rows}
                    rowCount={props.totalRows}
                    columns={props.gridOption.columns}
                    pageSize={props.gridOption.pageSize}
                    rowsPerPageOptions={props.gridOption.rowPerPageOptions}
                    paginationMode="server"
                    checkboxSelection
                    onPageChange={(newPage) => props.setPage(newPage)}
                    loading={props.loading}
                    onSelectionModelChange={(newSelectionModel) => {
                        props.setGridOption({
                            ...props.gridOption,
                            Ids: newSelectionModel
                        })
                    }}
                    onPageSizeChange={(data) => {
                        console.log(data)
                    }}
                />
            </Box>
        </>
    )
}
