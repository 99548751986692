import React from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import {ButtonGroup} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DataGrid} from "@mui/x-data-grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

/**
 * 대학교 코드 관리 UI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CollegeCodeUI(props) {

    return (
        <>
            <Box sx={{display: "flex", height: 40, width: '100%', justifyContent: "flex-end", marginBottom: "10px"}}>
                <Box sx={{display: "flex", flexDirection: "coulmn", alignItems: "center", minWidth: 200}}>
                    <FormControl fullWidth>
                        <InputLabel id="SearchKey-label">검색 키워드</InputLabel>
                        <Select
                            labelId="SearchKey"
                            id="SearchKey"
                            name="SearchKey"
                            label="ALL"
                            defaultValue="all"
                            onChange={props.onChangeSearchKey}
                        >
                            {props.selectList.map((item) => {
                                return (<MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{
                    justifyContent: 'flex-end',
                    flexDirection: "coulmn",
                    alignItems: "center",
                    marginRight: "10px"
                }}>
                    <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        variant="filled"
                        size="small"
                        onChange={props.onChangeSearchWord}
                        onKeyDown={(e) => props.onKeyDownEnter(e)}
                        sx={{marginLeft: "10px", marginRight: "10px"}}
                    />
                    <Button variant="contained" onClick={props.onClickSearch} endIcon={<SearchIcon/>}>
                        검색
                    </Button>
                </Box>

                <ButtonGroup variant="outlined" aria-label="outlined button group">
                    <Button onClick={null}>추가</Button>
                    <Button color="error" onClick={props.onClickDeleteButton}>삭제</Button>
                    <Button color="success" onClick={props.onClickExcelUploadFile}><i className="file excel icon"></i>엑셀
                        밀어넣기<input
                            type="file"
                            ref={props.excelUploadFile}
                            onChange={props.excelUpload}
                            hidden
                        /></Button>
                </ButtonGroup>
            </Box>
            <Box sx={{height: 400, width: '100%'}}>
                <Typography>
                    {props.gridOption.title}
                </Typography>
                <DataGrid
                    sx={{
                        boxShadow: 2,
                        border: 2,
                        borderColor: 'primary.light', '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        }
                    }}
                    autoHeight
                    checkboxSelection
                    disableColumnFilter
                    density={"compact"}
                    rows={props.rows}
                    rowCount={props.totalRows}
                    columns={props.gridOption.columns}
                    pageSize={props.gridOption.pageSize}
                    rowsPerPageOptions={props.gridOption.rowPerPageOptions}
                    paginationMode="server"
                    onPageChange={(newPage) => props.setPage(newPage)}
                    sortingMode="server"
                    onSortModelChange={props.handleSortModelChange}
                    loading={props.loading}
                    onSelectionModelChange={(newSelectionModel) => {
                        props.setGridOption({
                            ...props.gridOption,
                            Ids: newSelectionModel
                        })
                    }}
                    onPageSizeChange={(data) => {
                        console.log(data)
                    }}
                />
            </Box>
        </>
    )
}
