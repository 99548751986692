import React, {useEffect, useRef, useState} from 'react';
import Grid from "@mui/material/Grid";
import EarlydSubjectUI from "./EarlydSubject.presenter";
import {
    requestEarlydSubjectExcelUpload,
    requestEarlydSubjectList,
    requestRemoveEarlydSubject
} from "../../../api/subject/SubjectAPI";
import LoadingComponent from "../../common/loadding/LoadingComponent";
import {camelCaseToSnakeCase} from "../../../util/CommonFunction";

/**
 * 수시 교과 통합 관리
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function EarlydSubject(props) {
    const [loading, setLoading] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);

    const [searchKey, setSearchKey] = useState("all");
    const [searchWord, setSearchWord] = useState("");
    const [searchSort, setSearchSort] = useState({});
    const fileInput = useRef();

    const [earlydSubjectGridOption, setEarlydSubjectGridOption] = useState(
        {
            columns: [
                {field: 'id', headerName: 'ID', width: 70, hide: true},
                {field: 'year', headerName: '학년도', headerAlign: "center", width: 70, align: 'center'},
                {field: 'collegeCode', headerName: '대학교 코드', headerAlign: "center", width: 130, align: 'center'},
                {field: 'collegeName', headerName: '대학교 이름', headerAlign: "center", width: 130, align: 'center'},
                {field: 'classification', headerName: '정원구분', headerAlign: "center", width: 130, align: 'center'},
                {field: 'grandSeries', headerName: '대계열', headerAlign: "center", width: 180},
                {field: 'middleSeries', headerName: '중계열', headerAlign: "center", width: 180},
                {field: 'rowSeries', headerName: '소계열', headerAlign: "center", width: 180},
                {field: 'basicTypeAdmission', headerName: '전형기본유형', headerAlign: "center", width: 130},
                {field: 'applicationDetailedType', headerName: '전형세부유형', headerAlign: "center", width: 130},
                {field: 'typeName', headerName: '전형명', headerAlign: "center", width: 180},
                {field: 'regionRange', headerName: '지역인재적용범위', headerAlign: "center", width: 130},
                {field: 'centerType', headerName: '중심전형분류', headerAlign: "center", width: 130, align: 'center'},
                {field: 'line', headerName: '계열', headerAlign: "center", width: 130, align: 'center'},
                {field: 'collegeType', headerName: '단과대학', headerAlign: "center", width: 130, align: 'center'},
                {field: 'recruitmentUnit', headerName: '모집단위명', headerAlign: "center", width: 180},
                {field: 'recruitmentNum', headerName: '모집인원(정원)', headerAlign: "center", width: 130, align: 'right'},
                {field: 'selectionModel', headerName: '선발유형(선발모형)', headerAlign: "center", width: 130},
                {field: 'selectionRate', headerName: '선발 비율', headerAlign: "center", width: 130, align: 'right'},
                {field: 'subjectRate', headerName: '교과 비율', headerAlign: "center", width: 130, align: 'right'},
                {field: 'interviewRate', headerName: '면접 비율', headerAlign: "center", width: 130, align: 'right'},
                {field: 'paperRate', headerName: '서류 비율', headerAlign: "center", width: 130, align: 'right'},
                {
                    field: 'stepTwoInterviewRate',
                    headerName: '2단계 면접 비율',
                    headerAlign: "center",
                    width: 130,
                    align: 'right'
                },
                {field: 'stepTwoPaperRate', headerName: '2단계 서류 비율', headerAlign: "center", width: 130, align: 'right'},
                {field: 'nonSubjectRatio', headerName: '비교과 비율', headerAlign: "center", width: 130, align: 'right'},
                {
                    field: 'nonSubjectYn',
                    headerName: '비교과항목(포함/미포함)',
                    headerAlign: "center",
                    width: 130,
                    align: 'center'
                },
                {field: 'typeTotalScore', headerName: '전형총점', headerAlign: "center", width: 130, align: 'right'},
                {field: 'competitionRate', headerName: '작년 경쟁률', headerAlign: "center", width: 130, align: 'right'},
                {
                    field: 'cut50',
                    headerName: '50%컷(상위 합격자 등급컷)',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'cut70',
                    headerName: '70%컷(하위 합격자 등급컷)',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'gradeCut50',
                    headerName: '등급 50%컷',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'gradeCut70',
                    headerName: '등급 70%컷',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'gradeCut80',
                    headerName: '등급 80%컷',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'gradeCut90',
                    headerName: '등급 90%컷',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'gradeCut100',
                    headerName: '등급 100%컷',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'gradeCutAverage',
                    headerName: '등급 평균',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'gradeCutLowest',
                    headerName: '등급 최저',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'convertCut50',
                    headerName: '환산 50%컷 환산 값',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'convertCut70',
                    headerName: '환산 70%컷 환산 값',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'convertCut80',
                    headerName: '환산 80%컷 환산 값',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'risk90',
                    headerName: '위험도 90% 위험도 값',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'risk70',
                    headerName: '위험도 70% 위험도 값',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'risk50',
                    headerName: '위험도 50% 위험도 값',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'risk30',
                    headerName: '위험도 30% 위험도 값',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'lowestUse',
                    headerName: '최저학력기준_반영여부',
                    headerAlign: "center",
                    width: 130,
                    align: 'center',
                    sortable: false
                },
                {field: 'lowestText', headerName: '수능 최저학력기준 TEXT', headerAlign: "center", width: 180, sortable: false},
                {
                    field: 'lowestKorean',
                    headerName: '최저국어',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'lowestMath',
                    headerName: '최저수학',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'lowestMigi',
                    headerName: '최저수학(미/기)',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'lowestEnglish',
                    headerName: '최저영어',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'lowestSociety',
                    headerName: '최저사탐',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'lowestScience',
                    headerName: '최저과탐',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'lowestCal',
                    headerName: '탐계산',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'lowestCount',
                    headerName: '최저갯수',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'lowestSum',
                    headerName: '최저합 (~이내)',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'lowestHistory',
                    headerName: '한국사 (~이내)',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'interviewType',
                    headerName: '면접유형',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },//0: 면접없음, 1:생기부기반, 2:제시문기반
                {
                    field: 'recommendUse',
                    headerName: '학교장추천유무',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },//무:0 , 유 :1
                {field: 'interviewText', headerName: '면접안내TEXT', headerAlign: "center", width: 200, sortable: false},
                {
                    field: 'interviewDate',
                    headerName: '면접날짜',
                    headerAlign: "center",
                    width: 130,
                    align: 'center',
                    sortable: false
                },
                {
                    field: 'interviewTime',
                    headerName: '면접시간',
                    headerAlign: "center",
                    width: 130,
                    align: 'center',
                    sortable: false
                },
                {field: 'interviewPaper', headerName: '면접시 서류종류', headerAlign: "center", width: 150, sortable: false},
                {
                    field: 'attendance',
                    headerName: '비교과(출결)',
                    headerAlign: "center",
                    width: 130,
                    align: 'center',
                    sortable: false
                },
                {
                    field: 'volunteer',
                    headerName: '비교과(봉사활동)',
                    headerAlign: "center",
                    width: 130,
                    align: 'center',
                    sortable: false
                },
                {
                    field: 'lifeRecord',
                    headerName: '비교과(생활기록부)',
                    headerAlign: "center",
                    width: 130,
                    align: 'center',
                    sortable: false
                },
                {
                    field: 'supportQualificationText',
                    headerName: '지원자격TEXT',
                    headerAlign: "center",
                    width: 200,
                    sortable: false
                },
                {
                    field: 'firstClassRate',
                    headerName: '1학년 반영비율',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'twoClassRate',
                    headerName: '2학년 반영비율',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'thirdClassRate',
                    headerName: '3학년 반영비율',
                    headerAlign: "center",
                    width: 130,
                    align: 'right',
                    sortable: false
                },
                {
                    field: 'courseSubjectYn',
                    headerName: '진로과목 적용여부',
                    headerAlign: "center",
                    width: 130,
                    align: 'center',
                    sortable: false
                },
                {field: 'usefulGuideline', headerName: '학생부 활용지표', headerAlign: "center", width: 130, sortable: false},
                {
                    field: 'additionalScoreText',
                    headerName: '가산점TEXT',
                    headerAlign: "center",
                    width: 130,
                    sortable: false
                },
            ],
            rows: [],
            title: "> 수시 교과 통합 목록",
            rowPerPageOptions: [15],
            pageSize: 15,
            Ids: []
        }
    )
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    // select box
    const selectList = [
        {value: "all", name: "전체"},
        {value: "A.year", name: "학년도"},
        {value: "A.college_code", name: "대학교 코드"},
        {value: "A.college_name", name: "대학교 이름"},
        {value: "A.classification", name: "정원구분"},
        {value: "A.grand_series", name: "대계열"},
        {value: "A.middle_series", name: "중계열"},
        {value: "A.row_series", name: "소계열"},
        {value: "A.basic_type_admission", name: "전형기본유형"},
        {value: "A.application_detailed_type", name: "전형세부유형"},
        {value: "A.type_name", name: "전형명"},
        {value: "A.region_range", name: "지역인재적용범위"},
        {value: "A.center_type", name: "중심전형분류"},
        {value: "A.college_type", name: "단과대학"},
        {value: "A.recruitment_unit", name: "모집단위명"},
        {value: "A.recruitment_num", name: "모집인원(정원)"},
        {value: "A.selection_model", name: "선발유형(선발모형)"}
    ];

    /** 검색조건 변경 이벤트 **/
    const onChangeSearchKey = (e) => {
        setSearchKey(e.target.value);
    }

    /** 검색어 입력 이벤트 **/
    const onChangeSearchWord = (e) => {
        setSearchWord(e.target.value);
    }

    /** 검색 버튼 이벤트 **/
    const onClickSearch = async () => {
        const newRows = await searchList();
        setRows(newRows.list);
        setTotalRows(newRows.totalCount);
    }

    const onKeyDownEnter = e => {
        if (e.key === 'Enter') {
            onClickSearch(); // Enter 입력이 되면 클릭 이벤트 실행
        }
    };

    /** 검색목록 조회 **/
    function searchList() {
        return new Promise((resolve) => {
            setTimeout(() => {
                const body = {
                    searchKey: searchKey === 'all' ? selectList.filter(item => item.value !== 'all').map(item => {
                        return item.value
                    }).join('`') : searchKey,
                    searchWord: searchWord,
                    searchSort: searchSort.sortModel ? searchSort.sortModel[0] : null,
                    page: page * earlydSubjectGridOption.pageSize,
                    pageSize: earlydSubjectGridOption.pageSize
                }
                requestEarlydSubjectList(body).then((response) => {
                    resolve(response);
                })
            }, Math.random() * 100 + 100)
        });
    }

    /**
     * 삭제 버튼 클릭 이벤트
     */
    const onClickDeleteButton = e => {
        if (earlydSubjectGridOption.Ids.length) {
            requestRemoveEarlydSubject({ids: earlydSubjectGridOption.Ids}).then(response => {
                if (response.status < 400) {
                    alert('삭제가 완료되었습니다.');
                } else {
                    alert('삭제가 실패하였습니다.\n실패 사유 : ' + response.message);
                }
            }).finally(async () => {
                const newRows = await searchList();
                setRows(newRows.list);
                setTotalRows(newRows.totalCount);
            });
        } else {
            alert('1건 이상 선택해 주세요!');
        }
    }

    /**
     * 엑셀 업로드 이벤트
     */
    const excelUpload = e => {
        console.log(e.target.files[0]);
        const formData = new FormData();
        formData.append("file", fileInput.length && fileInput[0].uploadedFile);
        setLoadingOpen(true);
        requestEarlydSubjectExcelUpload(e.target.files[0]).then(response => {
            if (response.data.status) {
                alert("엑셀 업로드가 완료되었습니다.")
            } else {
                alert('엑셀 업로드가 실패하였습니다.\n실패 사유 : ' + response.data.message)
            }
            setLoadingOpen(false);
        }).finally(async () => {
            fileInput.current.value = "";
            const newRows = await searchList();
            setRows(newRows.list);
            setTotalRows(newRows.totalCount);
        });
    }

    /**
     * 엑셀 업로드 파일 클릭 이벤트
     */
    const onClickExcelUploadFile = e => {
        fileInput.current.click();
    };

    /**
     * 그리드 헤더 정렬 이벤트
     * @type {(function(*): void)|*}
     */
    const handleSortModelChange = async (sortModel) => {
        // Here you save the data you need from the sort model
        setSearchSort({
            sortModel: [
                {
                    field: camelCaseToSnakeCase(sortModel[0].field),
                    sort: sortModel[0].sort
                }
            ]
        })
    };

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            const newRows = await searchList();

            if (!active) {
                return;
            }

            setRows(newRows.list);
            setTotalRows(newRows.totalCount);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchSort]);

    return (
        <Grid container spacing={1}>
            <LoadingComponent open={loadingOpen}/>
            <EarlydSubjectUI
                gridOption={earlydSubjectGridOption}
                rows={rows}
                totalRows={totalRows}
                setPage={setPage}
                handleSortModelChange={handleSortModelChange}
                loading={loading}
                setGridOption={setEarlydSubjectGridOption}
                onChangeSearchKey={onChangeSearchKey}
                onChangeSearchWord={onChangeSearchWord}
                onKeyDownEnter={onKeyDownEnter}
                onClickSearch={onClickSearch}
                onClickDeleteButton={onClickDeleteButton}
                excelUpload={excelUpload}
                excelUploadFile={fileInput}
                onClickExcelUploadFile={onClickExcelUploadFile}
                selectList={selectList}
            />
        </Grid>
    );
}