import React, {Component} from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {ButtonGroup} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from "@mui/material/Button";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import SearchIcon from '@mui/icons-material/Search';
import LoadingComponent from "../common/loadding/LoadingComponent";

class BasicSearchListComponent extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            searchKey1: '',//검색조건1 값
            searchWord: '',//검색어 값
            columns: props.columns,
            rows: [],
            title: props.title,
            loading: true,
            totalRows: 0,
            rowPerPageOptions: [15],
            pageSize: 15,
            page: 1,
            Ids: [],
            addButtonUse: props.addButtonUse === false ? false : true,//addButtonUse == false 시 버튼 미제공
            searchKey1Use: props.searchKey1Use ? true : false,//searchKey1Use == true 시 검색조건1 제공
            searchKey1Label: props.searchKey1Use === true ? props.searchKey1Label : '',
            searchKey1Arrays: [],
            loadingOpen: false
        }
    }

    componentDidMount() {
        /**
         * 데이터는 list로 받는다
         */
        if (this.props.searchKey1Use) {//검색조건1
            this.searchKey1();
        }
        this.searchList();//검색-start
    }

    // render update 전에 호출됩니다. 호출시에 true,false를 반환하여 view를 업데이트 할지 안할지 결정하는 함수입니다. 예) 반환값이 false 일경우 뷰 렌더링 x , true 일경우 뷰 렌더링 o
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.page !== this.state.page) {
            this.searchList();
        }
    }

    /** 공통 검색조건1 조회 **/
    searchKey1() {
        const body = {}
        this.props.searchKey1Api(body).then((response) => {
            this.setState({
                ...this.state,
                searchKey1Arrays: response.list
            })

            console.log(response)
        });
    }

    /**
     * 공통 목록 조회
     *  - 검색조건1 활성화 시 : 검색조건1 적용
     */
    searchList() {
        const body = {
            searchKey1: this.state.searchKey1 ? this.state.searchKey1 : '',
            searchWord: this.state.searchWord,
            page: (this.state.page - 1) * this.state.pageSize,
            pageSize: this.state.pageSize
        }
        this.props.init(body).then((response) => {
            this.setState({
                ...this.state,
                rows: response.list,
                totalRows: response.totalCount
            })
            console.log(response)
        });
    }

    render() {
        /** 검색조건1 변경 이벤트 **/
        const onChangeSearchKey1 = (e) => {
            this.setState({
                searchKey1: e.target.value,
            })
        }

        /** 검색어 입력 이벤트 **/
        const handleValue = (e) => {
            this.setState({
                searchWord: e.target.value,
            })
        }

        /** 검색버튼 클릭 이벤트 **/
        const onClickSearch = () => {
            this.searchList();
        }

        const fileInput = React.createRef();
        const handleButtonClick = e => {
            fileInput.current.click();
        };

        /** 삭제 버튼 클릭 이벤트 **/
        const handleRemoveClick = e => {
            if (this.state.Ids.length) {
                this.props.removeEvent({ids: this.state.Ids}).then(response => {
                    if (response.status < 400) {
                        alert('삭제가 완료되었습니다.')
                    } else {
                        alert('삭제가 실패하였습니다.\n실패 사유 : ' + response.message);
                    }
                }).finally(() => {
                    this.searchList();
                });
            } else {
                alert('1건 이상 선택해 주세요!')
            }
        }

        /** 엑셀 업로드 이벤트 **/
        const excelUploadFile = (event) => {
            //exceluploadRequireSearchKey1 : 엑셀업로드 시 검색조건1 선택이 필수값인 경우 true
            if (this.props.exceluploadRequireSearchKey1 && this.state.searchKey1 === '') {
                alert(this.state.searchKey1Label + "을 선택해 주세요.")
            } else {
                console.log(event.target.files[0]);
                const formData = new FormData();
                formData.append("file", fileInput.length && fileInput[0].uploadedFile);
                this.setState({...this.state, loadingOpen: true});
                this.props.excelupload(event.target.files[0], this.state.searchKey1).then(response => {
                    if (response.data.status) {
                        alert("업로드가 완료되었습니다.")
                    } else {
                        alert('엑셀 업로드가 실패하였습니다.\n실패 사유 : ' + response.data.message)
                    }
                    this.setState({...this.state, loadingOpen: false});
                }).finally(() => {
                    fileInput.current.value = "";
                    this.searchList();
                });
            }
        }

        return (
            <>
                <LoadingComponent open={this.state.loadingOpen}/>
                <Box
                    sx={{display: "flex", height: 40, width: '100%', justifyContent: "flex-end", marginBottom: "10px"}}>
                    {this.state.searchKey1Use ?
                        <Box sx={{display: "flex", flexDirection: "coulmn", alignItems: "center", minWidth: 200}}>
                            <FormControl fullWidth>
                                <InputLabel id="SearchKey1-label">{this.state.searchKey1Label}</InputLabel>
                                <Select
                                    labelId="SearchKey1"
                                    id="SearchKey1"
                                    name="SearchKey1"
                                    value={this.state.searchKey1}
                                    label="ALL"
                                    onChange={onChangeSearchKey1}
                                >
                                    <MenuItem value={""}>전체</MenuItem>
                                    {this.state.searchKey1Arrays.map((array) => (
                                        <MenuItem value={array.id}>{array.searchText}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box> : ''
                    }
                    <Box sx={{
                        justifyContent: 'flex-end',
                        flexDirection: "coulmn",
                        alignItems: "center",
                        marginRight: "10px"
                    }}>
                        <TextField
                            hiddenLabel
                            id="filled-hidden-label-small"
                            variant="filled"
                            size="small"
                            onChange={handleValue}
                            sx={{marginLeft: "10px", marginRight: "10px"}

                            }
                        />
                        <Button variant="contained" onClick={onClickSearch} endIcon={<SearchIcon/>}>
                            검색
                        </Button>
                    </Box>
                    <Box sx={{justifyContent: 'flex-end'}}>
                        <ButtonGroup variant="outlined" aria-label="outlined button group">
                            {this.state.addButtonUse ? <Button>추가</Button> : ''}
                            <Button color="error" onClick={handleRemoveClick}>삭제</Button>
                            <Button color="success" onClick={handleButtonClick}><i className="file excel icon"></i>엑셀
                                밀어넣기<input
                                    type="file"
                                    ref={fileInput}
                                    onChange={excelUploadFile}
                                    hidden
                                /></Button>
                        </ButtonGroup>
                    </Box>
                </Box>
                <Box sx={{height: 400, width: '100%'}}>
                    <Typography>
                        {this.state.title}
                    </Typography>
                    <DataGrid
                        sx={{
                            boxShadow: 2,
                            border: 2,
                            borderColor: 'primary.light', '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                            },
                        }}
                        autoHeight
                        density={"compact"}
                        components={{Toolbar: GridToolbar}}
                        rows={this.state.rows}
                        columns={this.state.columns}
                        pageSize={this.state.pageSize}
                        page={this.state.page - 1}
                        rowCount={this.state.totalRows}
                        rowsPerPageOptions={this.state.rowPerPageOptions}
                        paginationMode="server"
                        checkboxSelection
                        onPageChange={(pageNum) => {
                            this.setState({
                                ...this.state,
                                page: pageNum + 1,
                            })
                        }}
                        onSelectionModelChange={(newSelectionModel) => {
                            this.setState({
                                Ids: newSelectionModel
                            })
                        }}
                        onPageSizeChange={(data) => {
                            console.log(data)
                        }}
                    />
                </Box>
            </>
        );
    }
}

BasicSearchListComponent.propTypes = {};

export default BasicSearchListComponent;