import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {
    requestAddCoupon,
    requestCouponList,
    requestRemoveCoupon,
    requestServiceList,
    requestUpdateCoupon
} from "../../../api/pay/PayAPI";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import CouponUI from "./Coupon.presenter";

/**
 * 쿠폰 관리
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Coupon(props) {
    //다이얼로그 값세팅
    const [serviceList, setServiceList] = React.useState([]);
    //그리드 설정
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchWord, setSearchWord] = useState("");//검색어 입력
    //그리드 옵션
    const [couponGridOption, setCouponGridOption] = useState(
        {
            columns: [
                {field: 'id', headerName: '쿠폰 ID', width: 70, hide: true},
                {field: 'payServiceId', headerName: '쿠폰적용 서비스ID', hide: true},
                {
                    field: "actions",
                    headerClassName: "super-app-theme--header",
                    headerName: "Actions",
                    headerAlign: "center",
                    width: 80,
                    disableColumnMenu: true,
                    editable: false,
                    renderCell: (params) => (
                        <>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <IconButton color="primary" onClick={() => editCoupon(params.row)}>
                                    <EditIcon/>
                                </IconButton>
                            </Box>
                        </>
                    )
                },
                {field: 'couponNumber', headerName: '쿠폰번호', headerAlign: "center", width: 230, align: 'center'},
                {field: 'discountInfo', headerName: '쿠폰할인 정보', headerAlign: "center", width: 200},
                {field: 'discountValue', headerName: '쿠폰할인 값', headerAlign: "center", width: 100, align: 'right'},
                {field: 'numberOfAvailable', headerName: '쿠폰갯수', headerAlign: "center", width: 100, align: 'right'},
                {field: 'productNm', headerName: '서비스이름', headerAlign: "center", width: 180},
                {
                    field: 'deleteFlag', headerName: '삭제여부', headerAlign: "center", width: 130, align: 'center',
                    renderCell: (params) => (<>{params.row.deleteFlag === false ? "서비스중" : "중지"}</>)
                },
                {field: 'productPrice', headerName: '상품가격', headerAlign: "center", width: 130, align: 'right'},
                {field: 'productPaymentType', headerName: '상품결제방식', headerAlign: "center", width: 130, align: 'center'},
                {field: 'promotionDiscount', headerName: '프로모션 할인율', headerAlign: "center", width: 130, align: 'right'},
            ],
            rows: [],
            title: "> 쿠폰 목록 (조회조건 : 서비스명)",
            rowPerPageOptions: [15],
            pageSize: 15,
            Ids: []
        }
    )

    /**
     * 초기화 데이터
     */
    let initialFormData = {
        id: null,
        payServiceId: "",//쿠폰적용 서비스ID
        couponNumber: "",//쿠폰번호
        discountInfo: "",//쿠폰할인 정보
        discountValue: "",//쿠폰할인 값
        numberOfAvailable: ""//쿠폰갯수
    };
    // 다이얼로그 데이터 초기화
    const [couponData, setCouponData] = useState(initialFormData);
    // 다이얼로그 Open 여부 초기화
    const [openCouponDialog, setOpenCouponDialog] = useState(false);

    /**
     * 검색어 입력 변경 이벤트
     */
    const onChangeSearchWord = (e) => {
        setSearchWord(e.target.value);
    }

    /**
     * 검색 버튼 클릭 이벤트
     */
    const onClickSearch = async () => {
        const newRows = await searchList();
        setRows(newRows.list);
        setTotalRows(newRows.totalCount);
    }

    /**
     * 검색목록 조회
     */
    function searchList() {
        return new Promise((resolve) => {
            setTimeout(() => {
                const body = {
                    searchWord: searchWord,
                    page: page * couponGridOption.pageSize,
                    pageSize: couponGridOption.pageSize
                }
                requestCouponList(body).then((response) => {
                    resolve(response);
                })
            }, Math.random() * 100 + 100)
        });
    }

    /**
     * 상품서비스 데이터 체크박스에 적용
     */
    const getServiceList = () => {
        const body = {
            searchWord: "",
            page: "",
            pageSize: ""
        }
        requestServiceList(body).then((response) => {
            setServiceList(response.list);
        })
    }

    /**
     * 다이얼로그 추가/수정 이벤트
     */
    const saveCoupon = async (e) => {
        try {
            e.preventDefault();

            if (couponData.id) {
                requestUpdateCoupon(couponData).then(response => {
                    if (response.status < 400) {
                        alert('수정이 완료되었습니다.');
                    } else {
                        alert('수정에 실패하였습니다.\n실패 사유 : ' + response.message);
                    }
                }).finally(async () => {
                    setOpenCouponDialog(false);
                    await clearCouponData();
                    const newRows = await searchList();
                    setRows(newRows.list);
                    setTotalRows(newRows.totalCount);
                });
            } else {
                requestAddCoupon(couponData).then(response => {
                    if (response.status < 400) {
                        alert('등록이 완료되었습니다.');
                    } else {
                        alert('등록에 실패하였습니다.\n실패 사유 : ' + response.message);
                    }
                }).finally(async () => {
                    setOpenCouponDialog(false);
                    await clearCouponData();
                    const newRows = await searchList();
                    setRows(newRows.list);
                    setTotalRows(newRows.totalCount);
                });
            }
        } catch (err) {
            alert(err.message);
        }
    };

    /**
     * 다이얼로그 수정창 띄우기 이벤트
     */
    const editCoupon = async (couponData) => {
        setOpenCouponDialog(true);
        getServiceList();

        // const base64 = await convertBase64();
        setCouponData({
            ...couponData
        });
    };

    /**
     * 삭제 버튼 클릭 이벤트
     */
    const onClickDeleteButton = e => {
        if (couponGridOption.Ids.length) {
            requestRemoveCoupon({ids: couponGridOption.Ids}).then(response => {
                if (response.status < 400) {
                    alert('삭제가 완료되었습니다.');
                } else {
                    alert('삭제가 실패하였습니다.\n실패 사유 : ' + response.message);
                }
            }).finally(async () => {
                const newRows = await searchList();
                setRows(newRows.list);
                setTotalRows(newRows.totalCount);
            });
        } else {
            alert('1건 이상 선택해 주세요!');
        }
    }

    /**
     * 다이얼로그 값 초기화
     */
    const clearCouponData = async () => {
        setCouponData(initialFormData);
    };

    /**
     * 다이얼로그 값 변경 이벤트
     */
    const handleChanged = (event, value) => {
        setCouponData({
            ...couponData,
            [event.target.name]: event.target.value
        });
    };

    /**
     * 다이얼로그 열기 이벤트
     */
    const handleClickedOpen = () => {
        setOpenCouponDialog(true);
        getServiceList();
    };

    /**
     * 다이얼로그 닫기 이벤트
     */
    const handleClosed = () => {
        setOpenCouponDialog(false);
        clearCouponData();
    };

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            const newRows = await searchList();

            if (!active) {
                return;
            }

            setRows(newRows.list);
            setTotalRows(newRows.totalCount);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <Grid container spacing={1}>
            <CouponUI
                gridOption={couponGridOption}
                rows={rows}
                totalRows={totalRows}
                setPage={setPage}
                loading={loading}
                setGridOption={setCouponGridOption}
                onChangeSearchWord={onChangeSearchWord}
                onClickSearch={onClickSearch}
                couponData={couponData}
                handleClickedOpen={handleClickedOpen}
                handleClosed={handleClosed}
                handleChanged={handleChanged}
                openCouponDialog={openCouponDialog}
                saveCoupon={saveCoupon}
                onClickDeleteButton={onClickDeleteButton}
                serviceList={serviceList}
            />
        </Grid>
    );
}