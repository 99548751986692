import React, {useEffect} from 'react';
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {Radio, RadioGroup} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import {reqeustUpdateSetting, requestGetSettingInfo} from "../../api/common/commonAPI";

function MockExamSetting(props) {
    const [mockScore, setMockScore] = React.useState('0');

    const handleChange = (event) => {
        setMockScore(event.currentTarget.value);
    };
    useEffect(()=>{
        getSettingInfo();
    },[])
    const getSettingInfo = () =>{
        requestGetSettingInfo().then((result)=>{
            setMockScore(result);
        })
    }


    return (
        <Paper elevation={0}>
            <div>
                <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">원점수/표준점수</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        style={{display:"flex",flexDirection:"row"}}
                        value={mockScore}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="0" control={<Radio />} label="원점수" />
                        <FormControlLabel value="1" control={<Radio />} label="표준점수" />
                    </RadioGroup>
                </FormControl>
            </div>
            <div>
                <Button variant="contained" onClick={()=>reqeustUpdateSetting("MOCKSCORE",mockScore)}>저장</Button>
            </div>
        </Paper>
            );
            }

            export default MockExamSetting;