import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import UserLifeRecordUI from "./UserLifeRecord.presenter";
import {requestUserLifeRecordInfo, requestUserLifeRecordList} from "../../../api/lifeRecord/LifeRecordAPI";
import {camelCaseToSnakeCase} from "../../../util/CommonFunction";

/**
 * 생기부 등록현황 관리
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function UserLifeRecord(props) {
    const [loading, setLoading] = useState(false);

    const [searchKey, setSearchKey] = useState("all");
    const [searchWord, setSearchWord] = useState("");
    const [searchSort, setSearchSort] = useState({});

    const [userLifeRecordGridOption, setUserLifeRecordGridOption] = useState(
        {
            columns: [
                {field: 'id', headerName: '유저 ID', width: 70, headerAlign: "center", hide: true},
                {field: 'email', headerName: '이메일', width: 200, headerAlign: "center"},
                {field: 'nickname', headerName: '닉네임', width: 130, headerAlign: "center", align: 'center'},
                {
                    field: 'major', headerName: '문과/이과 구분', width: 130, headerAlign: "center", align: 'center',
                    renderCell: (params, cellValues) => {
                        if (params.row.major === 'LiberalArts') {
                            return '문과'
                        } else if (params.row.major === 'NaturalSciences') {
                            return '이과'
                        }
                    }
                },
                {field: 'graduateYear', headerName: '졸업예정년도', width: 130, headerAlign: "center", align: 'center'},
                {
                    field: 'phone',
                    headerName: '휴대폰번호',
                    width: 130,
                    headerAlign: "center",
                    align: 'center',
                    sortable: false
                },
                {
                    field: 'humanisticYn', headerName: '인적사항', width: 130, headerAlign: "center", align: 'center',
                    renderCell: (params) => (<>{params.row.humanisticYn === 'Y' ? "등록" : "미등록"}</>),
                    sortable: false
                },
                {
                    field: 'subjectLearningYn', headerName: '일반교과목', width: 130, headerAlign: "center", align: 'center',
                    renderCell: (params) => (<>{params.row.subjectLearningYn === 'Y' ? "등록" : "미등록"}</>),
                    sortable: false
                },
                {
                    field: 'selectSubjectYn', headerName: '진로선택과목', width: 130, headerAlign: "center", align: 'center',
                    renderCell: (params) => (<>{params.row.selectSubjectYn === 'Y' ? "등록" : "미등록"}</>),
                    sortable: false
                },
                {
                    field: 'attendanceDetailYn', headerName: '출결상황', width: 130, headerAlign: "center", align: 'center',
                    renderCell: (params) => (<>{params.row.attendanceDetailYn === 'Y' ? "등록" : "미등록"}</>),
                    sortable: false
                },
                {
                    field: 'creativeExperienceYn',
                    headerName: '창의적 체험활동',
                    width: 130,
                    headerAlign: "center",
                    align: 'center',
                    renderCell: (params) => (<>{params.row.creativeExperienceYn === 'Y' ? "등록" : "미등록"}</>),
                    sortable: false
                },
                {
                    field: 'subjectSportsArtYn',
                    headerName: '체육*예술 과목',
                    width: 130,
                    headerAlign: "center",
                    align: 'center',
                    renderCell: (params) => (<>{params.row.subjectSportsArtYn === 'Y' ? "등록" : "미등록"}</>),
                    sortable: false
                },
                {
                    field: 'specialAbilitiesYn', headerName: '세부능력', width: 130, headerAlign: "center", align: 'center',
                    renderCell: (params) => (<>{params.row.specialAbilitiesYn === 'Y' ? "등록" : "미등록"}</>),
                    sortable: false
                },
                {
                    field: 'awardsYn', headerName: '수상경력', width: 130, headerAlign: "center", align: 'center',
                    renderCell: (params) => (<>{params.row.awardsYn === 'Y' ? "등록" : "미등록"}</>),
                    sortable: false
                },
                {
                    field: 'volunteerYn', headerName: '봉사활동실적', width: 130, headerAlign: "center", align: 'center',
                    renderCell: (params) => (<>{params.row.volunteerYn === 'Y' ? "등록" : "미등록"}</>),
                    sortable: false
                },
                {
                    field: 'readingActivityYn', headerName: '독서활동', width: 130, headerAlign: "center", align: 'center',
                    renderCell: (params) => (<>{params.row.readingActivityYn === 'Y' ? "등록" : "미등록"}</>),
                    sortable: false
                },
                {
                    field: 'generalOpinionYn',
                    headerName: '행동특성 및 종합의견',
                    width: 130,
                    headerAlign: "center",
                    align: 'center',
                    renderCell: (params) => (<>{params.row.generalOpinionYn === 'Y' ? "등록" : "미등록"}</>),
                    sortable: false
                }
            ],
            title: "> 생기부 등록자 목록",
            rowPerPageOptions: [15],
            pageSize: 15
        }
    )
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    const [subjectLearningGridOption, setSubjectLearningGridOption] = useState(
        {
            columns: [
                {field: 'id', headerName: 'ID', width: 70, headerAlign: "center", hide: true},
                {field: 'grade', headerName: '학년', width: 80, headerAlign: "center", align: 'center'},
                {field: 'semester', headerName: '학기', width: 80, headerAlign: "center", align: 'center'},
                {field: 'mainSubjectCode', headerName: '교과코드', width: 130, headerAlign: "center", align: 'center'},
                {field: 'mainSubjectName', headerName: '교과명', width: 150, headerAlign: "center"},
                {field: 'subjectCode', headerName: '과목코드', width: 130, headerAlign: "center", align: 'center'},
                {field: 'subjectName', headerName: '과목명', width: 150, headerAlign: "center"},
                {field: 'unit', headerName: '단위수', width: 80, headerAlign: "center", align: 'right'},
                {field: 'rawScore', headerName: '원점수', width: 80, headerAlign: "center", align: 'right'},
                {field: 'subSubjectAverage', headerName: '과목평균', width: 80, headerAlign: "center", align: 'right'},
                {field: 'standardDeviation', headerName: '표준편차', width: 80, headerAlign: "center", align: 'right'},
                {field: 'achievement', headerName: '성취도', width: 80, headerAlign: "center", align: 'center'},
                {field: 'studentsNum', headerName: '수강자수', width: 80, headerAlign: "center", align: 'right'},
                {field: 'ranking', headerName: '석차등급', width: 80, headerAlign: "center", align: 'right'},
                {field: 'etc', headerName: '비고', width: 200, headerAlign: "center", align: 'right'},
            ],
            title: "> 일반교과목 목록"
        }
    )
    const [subjectLearningRows, setSubjectLearningRows] = useState([]);

    // select box
    const selectList = [
        {value: "all", name: "전체"},
        {value: "A.email", name: "이메일"},
        {value: "A.nickname", name: "닉네임"},
        {
            value: "CASE WHEN A.major = 'NaturalSciences' THEN '이과' WHEN A.major = 'LiberalArts' THEN '문과' END",
            name: "문과/이과"
        },
        {value: "A.graduate_year", name: "졸업예정년도"}
    ];

    /**
     * 초기화 데이터
     */
    let initialFormData = {
        id: "",
        email: "",
        name: ""
    };

    // 다이얼로그 데이터 초기화
    const [lifeRecordData, setLifeRecordData] = useState(initialFormData);
    // 다이얼로그 Open 여부 초기화
    const [openLifeRecordDialog, setOpenLifeRecordDialog] = useState(false);

    /** 검색조건1 변경 이벤트 **/
    const onChangeSearchKey = (e) => {
        setSearchKey(e.target.value);
    }

    /** 검색어 입력 이벤트 **/
    const onChangeSearchWord = (e) => {
        setSearchWord(e.target.value);
    }

    /** 검색 버튼 이벤트 **/
    const onClickSearch = async () => {
        const newRows = await searchList();
        setRows(newRows.list);
        setTotalRows(newRows.totalCount);
    }

    const onKeyDownEnter = e => {
        if (e.key === 'Enter') {
            onClickSearch(); // Enter 입력이 되면 클릭 이벤트 실행
        }
    };

    /** 검색목록 조회 **/
    function searchList() {
        return new Promise((resolve) => {
            setTimeout(() => {
                const body = {
                    searchKey: searchKey === 'all' ? selectList.filter(item => item.value !== 'all').map(item => {
                        return item.value
                    }).join('`') : searchKey,
                    searchWord: searchWord,
                    searchSort: searchSort.sortModel ? searchSort.sortModel[0] : null,
                    page: page * userLifeRecordGridOption.pageSize,
                    pageSize: userLifeRecordGridOption.pageSize
                }
                requestUserLifeRecordList(body).then((response) => {
                    resolve(response);
                })
            }, Math.random() * 100 + 100)
        });
    }

    /**
     * 다이얼로그 열기 이벤트
     */
    const handleClickedOpen = (row) => {
        if (row.id) {
            setOpenLifeRecordDialog(true);

            setLifeRecordData({
                ...lifeRecordData,
                id: row.id,
                email: row.email,
                name: row.nickname
            });

            requestUserLifeRecordInfo(row.id).then((response) => {
                setSubjectLearningRows(response.schoolrecordSubjectLearningList);
            })
        } else {
            alert('해당 row가 존재하지않습니다!');
        }
    };

    /**
     * 다이얼로그 닫기 이벤트
     */
    const handleClosed = () => {
        setOpenLifeRecordDialog(false);
        clearLifeRecordData();
    };

    /**
     * 다이얼로그 값 초기화
     */
    const clearLifeRecordData = async () => {
        setLifeRecordData(initialFormData);
    };

    /**
     * 그리드 헤더 정렬 이벤트
     * @type {(function(*): void)|*}
     */
    const handleSortModelChange = async (sortModel) => {
        // Here you save the data you need from the sort model
        setSearchSort({
            sortModel: [
                {
                    field: camelCaseToSnakeCase(sortModel[0].field),
                    sort: sortModel[0].sort
                }
            ]
        })
    };

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            const newRows = await searchList();

            if (!active) {
                return;
            }

            setRows(newRows.list);
            setTotalRows(newRows.totalCount);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchSort]);

    return (
        <Grid container spacing={1}>
            <UserLifeRecordUI
                gridOption={userLifeRecordGridOption}//메인 그리드
                gridOptionDetail_2={subjectLearningGridOption}//학생의 일반교과목
                rows={rows}
                subjectLearningRows={subjectLearningRows}
                totalRows={totalRows}
                setPage={setPage}
                handleSortModelChange={handleSortModelChange}
                loading={loading}
                setGridOption={setUserLifeRecordGridOption}
                setSubjectLearningGridOption={setSubjectLearningGridOption}
                onChangeSearchKey={onChangeSearchKey}
                onChangeSearchWord={onChangeSearchWord}
                onKeyDownEnter={onKeyDownEnter}
                onClickSearch={onClickSearch}
                lifeRecordData={lifeRecordData}
                handleClickedOpen={handleClickedOpen}
                handleClosed={handleClosed}
                openLifeRecordDialog={openLifeRecordDialog}
                selectList={selectList}
            />
        </Grid>
    );
}