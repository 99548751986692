import React from 'react';
import MemberIncreaseChart from "./MemberIncreaseChart.presenter";
import LoginIncreaseChart from "./LoginIncreaseChart.presenter";

/**
 * 통합 대시보드
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function IntegratedDashBoard(props) {



    return (

<>
    <MemberIncreaseChart/>
    <LoginIncreaseChart/>
</>

    );
}