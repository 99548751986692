import * as React from 'react';
import {useState} from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Face5TwoToneIcon from '@mui/icons-material/Face5TwoTone';
import FaceTwoToneIcon from '@mui/icons-material/FaceTwoTone';
import EditNoteTwoToneIcon from '@mui/icons-material/EditNoteTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import PaymentTwoToneIcon from '@mui/icons-material/PaymentTwoTone';
import IntegrationInstructionsTwoToneIcon from '@mui/icons-material/IntegrationInstructionsTwoTone';
import FilterFramesTwoToneIcon from '@mui/icons-material/FilterFramesTwoTone';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListTwoToneIcon from '@mui/icons-material/ListTwoTone';
import TimelineTwoToneIcon from '@mui/icons-material/TimelineTwoTone';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';

export default function MainListItems() {
    const [openUser, setUserOpen] = useState(true);//사용자 정보
    const [openSusi, setSusiOpen] = useState(true);//수시 서비스
    const [openMock, setMockOpen] = useState(true);//모의고사 서비스
    const [openBoard, setBoardOpen] = useState(true);//게시판 서비스
    const [openPay, setPayOpen] = useState(true);//상품*결제 서비스
    const [openCommon, setCommonOpen] = useState(true);//공통 설정

    const handleUserClick = (e) => {
        setUserOpen(!openUser);
    };

    const handleSusiClick = (e) => {
        setSusiOpen(!openSusi);
    };

    const handleMockClick = (e) => {
        setMockOpen(!openMock);
    };

    const handleBoardClick = (e) => {
        setBoardOpen(!openBoard);
    };

    const handlePayClick = (e) => {
        setPayOpen(!openPay);
    };

    const handleCommonClick = (e) => {
        setCommonOpen(!openCommon);
    };

    return (
        <React.Fragment>
            <List
                sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        거북스쿨 통합관리 시스템
                    </ListSubheader>
                }
            >
                <ListItemButton component={Link} to="/">
                    <ListItemIcon>
                        <TimelineTwoToneIcon/>
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="h7">대시보드</Typography>}/>
                </ListItemButton>
            </List>

            <List
                sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton onClick={handleUserClick}>
                    <ListItemIcon>
                        <ListTwoToneIcon/>
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="h67">사용자 정보</Typography>}/>
                    {openUser ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={openUser} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{pl: 4}} component={Link} to="/user">
                            <ListItemIcon>
                                <Face5TwoToneIcon/>
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="h7">유저 관리</Typography>}/>
                        </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{pl: 4}} component={Link} to="/officer">
                            <ListItemIcon>
                                <FaceTwoToneIcon/>
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="h7">사정단 관리</Typography>}/>
                        </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{pl: 4}} component={Link} to="/lifeRecord">
                            <ListItemIcon>
                                <AssignmentTwoToneIcon/>
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="h7">생기부 관리</Typography>}/>
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>

            <List
                sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton onClick={handleSusiClick}>
                    <ListItemIcon>
                        <ListTwoToneIcon/>
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="h67">수시 서비스</Typography>}/>
                    {openSusi ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={openSusi} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{pl: 4}} component={Link} to="/essay">
                            <ListItemIcon>
                                <EditNoteTwoToneIcon/>
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="h7">논술 관리</Typography>}/>
                        </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{pl: 4}} component={Link} to="/studentRecord">
                            <ListItemIcon>
                                <MenuBookTwoToneIcon/>
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="h7">학종 관리</Typography>}/>
                        </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{pl: 4}} component={Link} to="/subject">
                            <ListItemIcon>
                                <MenuBookTwoToneIcon/>
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="h7">교과 관리</Typography>}/>
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>

            <List
                sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton onClick={handleMockClick}>
                    <ListItemIcon>
                        <ListTwoToneIcon/>
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="h67">모의고사 서비스</Typography>}/>
                    {openMock ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={openMock} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{pl: 4}} component={Link} to="/mockexam">
                            <ListItemIcon>
                                <EditNoteTwoToneIcon/>
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="h7">모의고사 관리</Typography>}/>
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>

            <List
                sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton onClick={handlePayClick}>
                    <ListItemIcon>
                        <ListTwoToneIcon/>
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="h67">상품 및 결제 서비스</Typography>}/>
                    {openPay ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={openPay} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{pl: 4}} component={Link} to="/pay">
                            <ListItemIcon>
                                <PaymentTwoToneIcon/>
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="h7">상품 및 결제 관리</Typography>}/>
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>

            <List
                sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton onClick={handleBoardClick}>
                    <ListItemIcon>
                        <ListTwoToneIcon/>
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="h67">게시판 서비스</Typography>}/>
                    {openBoard ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={openBoard} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{pl: 4}} component={Link} to="/board">
                            <ListItemIcon>
                                <FilterFramesTwoToneIcon/>
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="h7">게시판 관리</Typography>}/>
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>

            <List
                sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton onClick={handleCommonClick}>
                    <ListItemIcon>
                        <ListTwoToneIcon/>
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="h67">공통 설정</Typography>}/>
                    {openCommon ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={openCommon} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{pl: 4}} component={Link} to="/commonCode">
                            <ListItemIcon>
                                <IntegrationInstructionsTwoToneIcon/>
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="h7">공통 코드 관리</Typography>}/>
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>
        </React.Fragment>
    );
}