import React, {Component} from 'react';
import Grid from "@mui/material/Grid";
import {GridColDef} from "@mui/x-data-grid";

import BasicSearchListComponent from "../list/BasicSearchListComponent";
import {
    requestEssayPercentileExcelUpload,
    requestRemoveEssayPercentile,
    requestEssayPercentile
} from "../../api/essay/EssayAPI";
import {requestMockScheduleList} from "../../api/mock/MockAPI";

/**
 * 논술관리 > 논술 대학 백분위 최고,최저 관리 탭
 */
class EssayPercentileManage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            dense: false,
            secondary: false,
        }
    }

    componentDidMount() {

    }

    render() {
        const essayPercentileManageColumns: GridColDef[] = [
            {field: 'id', headerName: 'ID', width: 50, hide: true},
            {field: 'scheduleName', headerName: '모의고사 스케줄', headerAlign: "center", width: 180},
            {field: 'collegeCode', headerName: '대학교코드', headerAlign: "center", width: 80},
            {field: 'collegeName', headerName: '대학교이름', headerAlign: "center", width: 130},
            {field: 'admissionSeriesCode', headerName: '전형계열코드', headerAlign: "center", width: 100},
            {field: 'percentileTop', headerName: '백분위최고', headerAlign: "center", width: 100},
            {field: 'percentileBottom', headerName: '백분위최저', headerAlign: "center", width: 100}
        ];

        return (
            <Grid container spacing={1}>
                <BasicSearchListComponent
                    init={requestEssayPercentile}
                    removeEvent={requestRemoveEssayPercentile}
                    excelupload={requestEssayPercentileExcelUpload}
                    exceluploadRequireSearchKey1={true}//엑셀 업로드 시 검색조건1 필수여부
                    columns={essayPercentileManageColumns}
                    addButtonUse={false}//추가버튼 사용여부
                    searchKey1Api={requestMockScheduleList}//모의고사 스케줄 목록조회
                    searchKey1Use={true}//검색조건1 사용여부
                    searchKey1Label={"모의고사 스케줄"}//검색조건1 라벨명
                    title={"> 논술 대학 백분위 최고,최저 목록 (조회조건 : 모의고사 스케줄, 대학교코드)"}
                />
            </Grid>
        );
    }
}

export default EssayPercentileManage;