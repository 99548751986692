import React, {useEffect, useRef, useState} from 'react';
import Grid from "@mui/material/Grid";
import EarlydStudentComprehensiveUI from "./EarlydStudentComprehensive.presenter";
import {
    requestEarlydStudentRecordExcelUpload,
    requestEarlydStudentRecordList,
    requestRemoveEarlydStudentRecord
} from "../../../api/studentRecord/StudentRecordCodeAPI";
import LoadingComponent from "../../common/loadding/LoadingComponent";

/**
 * 수시 학종 통합 관리
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function EarlydStudentComprehensive(props) {
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const fileInput = useRef();
    const [searchWord, setSearchWord] = useState("");
    const [earlydStudentComprehensiveGridOption, setEarlydStudentComprehensiveGridOption] = useState(
        {
            columns: [
                {field: 'id', headerName: 'ID', headerAlign: "center", width: 70, hide: true},
                {field: 'collegeCode', headerName: '대학교 코드', headerAlign: "center", width: 130, align: 'center'},
                {field: 'collegeName', headerName: '대학교 이름', headerAlign: "center", width: 130, align: 'center'},
                {field: 'classification', headerName: '정원구분', headerAlign: "center", width: 130, align: 'center'},
                {field: 'grandSeries', headerName: '대계열', headerAlign: "center", width: 180},
                {field: 'middleSeries', headerName: '중계열', headerAlign: "center", width: 180},
                {field: 'rowSeries', headerName: '소계열', headerAlign: "center", width: 180},
                {field: 'basicTypeAdmission', headerName: '전형기본유형', headerAlign: "center", width: 130},
                {field: 'applicationDetailedType', headerName: '전형세부유형', headerAlign: "center", width: 130},
                {field: 'typeName', headerName: '전형명', headerAlign: "center", width: 180},
                {field: 'regionRange', headerName: '지역인재적용범위', headerAlign: "center", width: 130},
                {field: 'centerType', headerName: '중심전형분류', headerAlign: "center", width: 130, align: 'center'},
                {field: 'line', headerName: '계열', headerAlign: "center", width: 130, align: 'center'},
                {field: 'recruitmentUnit', headerName: '모집단위명', headerAlign: "center", width: 180},
                {field: 'recruitmentNum', headerName: '모집인원', headerAlign: "center", width: 130, align: 'right'},
                {field: 'year', headerName: '학년도', headerAlign: "center", width: 130, align: 'right'},
                {field: 'cut50', headerName: '50%컷', headerAlign: "center", width: 130, align: 'right'},
                {field: 'cut70', headerName: '70%컷', headerAlign: "center", width: 130, align: 'right'},
                {field: 'lowestUse', headerName: '최저학력기준 반영여부', headerAlign: "center", width: 130, align: 'right'},
                {field: 'lowestText', headerName: '수능 최저학력기준 TEXT', headerAlign: "center", width: 180},
                {field: 'lowestKorean', headerName: '최저국어', headerAlign: "center", width: 130, align: 'right'},
                {field: 'lowestMath', headerName: '최저수학', headerAlign: "center", width: 130, align: 'right'},
                {field: 'lowestMigi', headerName: '최저수학(미/기)', headerAlign: "center", width: 130, align: 'right'},
                {field: 'lowestEnglish', headerName: '최저영어', headerAlign: "center", width: 130, align: 'right'},
                {field: 'lowestSociety', headerName: '최저사탐', headerAlign: "center", width: 130, align: 'right'},
                {field: 'lowestScience', headerName: '최저과탐', headerAlign: "center", width: 130, align: 'right'},
                {field: 'lowestCal', headerName: '탐계산', headerAlign: "center", width: 130, align: 'right'},
                {field: 'lowestSum', headerName: '최저합 (~이내)', headerAlign: "center", width: 130, align: 'right'},
                {field: 'lowestHistory', headerName: '한국사 (~이내)', headerAlign: "center", width: 130, align: 'right'},
                {field: 'interviewType', headerName: '면접유형', headerAlign: "center", width: 130, align: 'right'},
                {field: 'recommendUse', headerName: '학교장추천유무', headerAlign: "center", width: 130, align: 'right'},
                {field: 'interviewText', headerName: '면접안내TEXT', headerAlign: "center", width: 300},
                {field: 'interviewDate', headerName: '면접날짜', headerAlign: "center", width: 130, align: 'center'},
                {field: 'interviewTime', headerName: '면접시간', headerAlign: "center", width: 130, align: 'center'},
                {field: 'interviewPaper', headerName: '면접시 서류종류', headerAlign: "center", width: 300}
            ],
            rows: [],
            title: "> 수시 학종 통합 목록 (조회조건 : 대학교 이름)",
            rowPerPageOptions: [15],
            pageSize: 15,
            Ids: []
        }
    )

    /** 검색어 입력 이벤트 **/
    const onChangeSearchWord = (e) => {
        setSearchWord(e.target.value);
    }

    /** 검색 버튼 이벤트 **/
    const onClickSearch = async () => {
        const newRows = await searchList();
        setRows(newRows.list);
        setTotalRows(newRows.totalCount);
    }

    /** 검색목록 조회 **/
    function searchList() {
        return new Promise((resolve) => {
            setTimeout(() => {
                const body = {
                    searchWord: searchWord,
                    page: page * earlydStudentComprehensiveGridOption.pageSize,
                    pageSize: earlydStudentComprehensiveGridOption.pageSize
                }
                requestEarlydStudentRecordList(body).then((response) => {
                    resolve(response);
                })
            }, Math.random() * 100 + 100)
        });
    }

    /**
     * 삭제 버튼 클릭 이벤트
     */
    const onClickDeleteButton = e => {
        if (earlydStudentComprehensiveGridOption.Ids.length) {
            requestRemoveEarlydStudentRecord({ids: earlydStudentComprehensiveGridOption.Ids}).then(response => {
                if (response.status < 400) {
                    alert('삭제가 완료되었습니다.');
                } else {
                    alert('삭제가 실패하였습니다.\n실패 사유 : ' + response.message);
                }
            }).finally(async () => {
                const newRows = await searchList();
                setRows(newRows.list);
                setTotalRows(newRows.totalCount);
            });
        } else {
            alert('1건 이상 선택해 주세요!');
        }
    }

    /**
     * 엑셀 업로드 이벤트
     */
    const excelUpload = e => {
        debugger;
        console.log(e.target.files[0]);
        const formData = new FormData();
        setLoadingOpen(true);
        formData.append("file", e.target.files[0].size && e.target);
        requestEarlydStudentRecordExcelUpload(e.target.files[0]).then(response => {
            if (response.data.status) {
                alert("엑셀 업로드가 완료되었습니다.")
            } else {
                alert('엑셀 업로드가 실패하였습니다.\n실패 사유 : ' + response.data.message)
            }
            setLoadingOpen(false);
        }).finally(async () => {
            fileInput.current.value = "";
            const newRows = await searchList();
            setRows(newRows.list);
            setTotalRows(newRows.totalCount);
        });
    }

    /**
     * 엑셀 업로드 파일 클릭 이벤트
     */
    const onClickExcelUploadFile = e => {
        fileInput.current.click();
    };

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            const newRows = await searchList();

            if (!active) {
                return;
            }

            setRows(newRows.list);
            setTotalRows(newRows.totalCount);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <Grid container spacing={1}>
            <LoadingComponent open={loadingOpen}/>
            <EarlydStudentComprehensiveUI
                gridOption={earlydStudentComprehensiveGridOption}
                rows={rows}
                totalRows={totalRows}
                setPage={setPage}
                loading={loading}
                setGridOption={setEarlydStudentComprehensiveGridOption}
                onChangeSearchWord={onChangeSearchWord}
                onClickSearch={onClickSearch}
                onClickDeleteButton={onClickDeleteButton}
                excelUpload={excelUpload}
                excelUploadFile={fileInput}
                onClickExcelUploadFile={onClickExcelUploadFile}
            />
        </Grid>
    );
}