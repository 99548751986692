import React from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import {ButtonGroup} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

/**
 * 모의고사 스케쥴 관리 UI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function MockExamScheduleUI(props) {

    return (
        <>
            <Box sx={{display: "flex", height: 40, width: '100%', justifyContent: "flex-end", marginBottom: "10px"}}>
                <Box sx={{
                    justifyContent: 'flex-end',
                    flexDirection: "coulmn",
                    alignItems: "center",
                    marginRight: "10px"
                }}>
                    <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        variant="filled"
                        size="small"
                        onChange={props.onChangeSearchWord}
                        sx={{marginLeft: "10px", marginRight: "10px"}}
                    />
                    <Button variant="contained" onClick={props.onClickSearch} endIcon={<SearchIcon/>}>
                        검색
                    </Button>
                </Box>

                <ButtonGroup variant="outlined" aria-label="outlined button group">
                    <Button onClick={props.handleClickedOpen} type="submit">
                        추가
                    </Button>
                    <>
                        <Dialog
                            // disableBackdropClick
                            open={props.openMockExamScheduleDialog}
                            onClose={props.handleClosed}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogTitle id="form-dialog-title">
                                {props.mockExamScheduleData.id ? "모의고사 스케쥴 수정" : "모의고사 스케쥴 등록"}
                            </DialogTitle>
                            <DialogContent item xs={2}>
                                <DialogContentText>
                                    {props.mockExamScheduleData.id ? "모의고사 스케쥴 수정." : "모의고사 스케쥴 등록."}
                                </DialogContentText>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        views={["year", "month"]}
                                        inputFormat="MM-YYYY"
                                        label="월-년"
                                        openTo="year"
                                        defaultValue={dayjs(`${props.mockExamScheduleData.mockexamYear}${props.mockExamScheduleData.mockexamMonth}`)}
                                        value={dayjs(`${props.mockExamScheduleData.mockexamYear}${props.mockExamScheduleData.mockexamMonth}`)}
                                        onChange={(newValue) => {
                                            props.handleDateChange(newValue);
                                        }}
                                        fullWidth
                                    />
                                </LocalizationProvider>
                            </DialogContent>
                            <DialogActions>
                                {props.mockExamScheduleData.id ?
                                    <Button onClick={props.saveMockExamSchedule} variant="contained" color="warning">
                                        수정
                                    </Button>
                                    :
                                    <Button onClick={props.saveMockExamSchedule} variant="contained" color="primary">
                                        등록
                                    </Button>
                                }
                                <Button onClick={props.handleClosed} color="primary">
                                    닫기
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>

                    <Button color="error" onClick={props.onClickDeleteButton}>삭제</Button>
                    <Button color="success" onClick={props.onClickExcelUploadFile}><i className="file excel icon"></i>엑셀
                        밀어넣기<input
                            type="file"
                            ref={props.excelUploadFile}
                            onChange={props.excelUpload}
                            hidden
                        /></Button>
                </ButtonGroup>
            </Box>
            <Box sx={{height: 400, width: '100%'}}>
                <Typography>
                    {props.gridOption.title}
                </Typography>
                <DataGrid
                    sx={{
                        boxShadow: 2,
                        border: 2,
                        borderColor: 'primary.light', '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        }
                    }}
                    autoHeight
                    density={"compact"}
                    components={{Toolbar: GridToolbar}}
                    rows={props.rows}
                    rowCount={props.totalRows}
                    columns={props.gridOption.columns}
                    pageSize={props.gridOption.pageSize}
                    rowsPerPageOptions={props.gridOption.rowPerPageOptions}
                    paginationMode="server"
                    checkboxSelection
                    onPageChange={(newPage) => props.setPage(newPage)}
                    loading={props.loading}
                    onSelectionModelChange={(newSelectionModel) => {
                        props.setGridOption({
                            ...props.gridOption,
                            Ids: newSelectionModel
                        })
                    }}
                    onPageSizeChange={(data) => {
                        console.log(data)
                    }}
                />
            </Box>
        </>
    )
}
