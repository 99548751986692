import React, {Component} from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CollegeCode from "./collegeCode/CollegeCode.container";
import EarlydSubjectCode from "./earlydSubjectCode/EarlydSubjectCode.container";

/**
 * 공통 코드 관리 Main
 */
class CommonCodeMain extends Component {
    constructor(props, context, _) {
        super(props, context);
        //state의 초깃값 설정하기
        this.state = {
            value: '1',
        };
    }

    render() {
        const handleChange = (event, newValue) => {
            this.setState({value: newValue});
        };

        return (
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={this.state.value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="대학 코드 관리" value="1"/>
                            <Tab label="교과 과목코드 관리" value="2"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1"><CollegeCode/></TabPanel>
                    <TabPanel value="2"><EarlydSubjectCode/></TabPanel>
                </TabContext>
            </Box>
        );
    }
}

CommonCodeMain.propTypes = {};

export default CommonCodeMain;