import {API_BASE_URL} from "../../constants";
import {request, requestFile} from "../../util/APIUtils";

/**
 * [관리자] 대학교 목록 조회
 *
 * @param collegeRequest
 * @returns {Promise<* | void>}
 */
export function requestCollegeList(collegeRequest) {
    return request({
        url: API_BASE_URL + "/admin/college/list",
        method: 'POST',
        body: JSON.stringify(collegeRequest)
    });
}

/**
 * [관리자] 대학교 row 삭제
 *
 * @param collegeRequest
 * @returns {Promise<* | void>}
 */
export function requestRemoveCollege(collegeRequest) {
    return request({
        url: API_BASE_URL + "/admin/college/delete",
        method: 'POST',
        body: JSON.stringify(collegeRequest)
    });
}

/**
 * [관리자] 대학교 리스트 엑셀 업로드
 *
 * @param file
 * @returns {*}
 */
export function requestCollegeExcelUpload(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("columnCount", 5);

    return requestFile(
        {
            url: API_BASE_URL + "/admin/college/excelupload",
            method: 'POST',
        },
        formData
    )
}