import React, {useEffect, useRef, useState} from 'react';
import Grid from "@mui/material/Grid";
import EarlydSeriesRequiredSubjectUI from "./EarlydSeriesRequiredSubject.presenter";
import {
    requestEarlydSeriesRequiredSubjectExcelUpload,
    requestEarlydSeriesRequiredSubjectList,
    requestRemoveEarlydSeriesRequiredSubject
} from "../../../api/studentRecord/StudentRecordCodeAPI";
import LoadingComponent from "../../common/loadding/LoadingComponent";

/**
 * 수시 학종 계열 필수과목 관리
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function EarlydSeriesRequiredSubject(props) {
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const fileInput = useRef();
    const [searchWord, setSearchWord] = useState("");
    const [earlydSeriesRequiredSubjectGridOption, setEarlydSeriesRequiredSubjectGridOption] = useState(
        {
            columns: [
                {field: 'id', headerName: 'ID', width: 70, hide: true},
                {field: 'rowSeries', headerName: '소계열ID', headerAlign: "center", width: 130},
                {field: 'subjectCode', headerName: '과목코드', headerAlign: "center", width: 130, align: 'center'},
                {field: 'essentialFlag', headerName: '계열별필수과목 플래그', headerAlign: "center", width: 200, align: 'center'},
            ],
            rows: [],
            title: "> 수시 학종 계열 필수과목 목록 (조회조건 : 소계열)",
            rowPerPageOptions: [15],
            pageSize: 15,
            Ids: []
        }
    )

    /** 검색어 입력 이벤트 **/
    const onChangeSearchWord = (e) => {
        setSearchWord(e.target.value);
    }

    /** 검색 버튼 이벤트 **/
    const onClickSearch = async () => {
        const newRows = await searchList();
        setRows(newRows.list);
        setTotalRows(newRows.totalCount);
    }

    /** 검색목록 조회 **/
    function searchList() {
        return new Promise((resolve) => {
            setTimeout(() => {
                const body = {
                    searchWord: searchWord,
                    page: page * earlydSeriesRequiredSubjectGridOption.pageSize,
                    pageSize: earlydSeriesRequiredSubjectGridOption.pageSize
                }
                requestEarlydSeriesRequiredSubjectList(body).then((response) => {
                    resolve(response);
                })
            }, Math.random() * 100 + 100)
        });
    }

    /**
     * 삭제 버튼 클릭 이벤트
     */
    const onClickDeleteButton = e => {
        if (earlydSeriesRequiredSubjectGridOption.Ids.length) {
            requestRemoveEarlydSeriesRequiredSubject({ids: earlydSeriesRequiredSubjectGridOption.Ids}).then(response => {
                if (response.status < 400) {
                    alert('삭제가 완료되었습니다.');
                } else {
                    alert('삭제가 실패하였습니다.\n실패 사유 : ' + response.message);
                }
            }).finally(async () => {
                const newRows = await searchList();
                setRows(newRows.list);
                setTotalRows(newRows.totalCount);
            });
        } else {
            alert('1건 이상 선택해 주세요!');
        }
    }

    /**
     * 엑셀 업로드 이벤트
     */
    const excelUpload = e => {
        console.log(e.target.files[0]);
        const formData = new FormData();
        formData.append("file", fileInput.length && fileInput[0].uploadedFile);
        setLoadingOpen(true);
        requestEarlydSeriesRequiredSubjectExcelUpload(e.target.files[0]).then(response => {
            if (response.data.status) {
                alert("엑셀 업로드가 완료되었습니다.")
            } else {
                alert('엑셀 업로드가 실패하였습니다.\n실패 사유 : ' + response.data.message)
            }
            setLoadingOpen(false);
        }).finally(async () => {
            fileInput.current.value = "";
            const newRows = await searchList();
            setRows(newRows.list);
            setTotalRows(newRows.totalCount);
        });
    }

    /**
     * 엑셀 업로드 파일 클릭 이벤트
     */
    const onClickExcelUploadFile = e => {
        fileInput.current.click();
    };

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            const newRows = await searchList();

            if (!active) {
                return;
            }

            setRows(newRows.list);
            setTotalRows(newRows.totalCount);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <Grid container spacing={1}>
            <LoadingComponent open={loadingOpen}/>
            <EarlydSeriesRequiredSubjectUI
                gridOption={earlydSeriesRequiredSubjectGridOption}
                rows={rows}
                totalRows={totalRows}
                setPage={setPage}
                loading={loading}
                setGridOption={setEarlydSeriesRequiredSubjectGridOption}
                onChangeSearchWord={onChangeSearchWord}
                onClickSearch={onClickSearch}
                onClickDeleteButton={onClickDeleteButton}
                excelUpload={excelUpload}
                excelUploadFile={fileInput}
                onClickExcelUploadFile={onClickExcelUploadFile}
            />
        </Grid>
    );
}