import {API_BASE_URL} from "../../constants";
import {request} from "../../util/APIUtils";

/**
 * [관리자] 유저목록 조회
 * @param userRequest
 * @returns {Promise<* | void>}
 */
export function requestUserList(userRequest) {
    return request({
        url: API_BASE_URL + "/admin/user/list",
        method: 'POST',
        body: JSON.stringify(userRequest)
    });
}

/**
 * [관리자] 유저 정보 수정
 *
 * @param userRequest
 * @returns {Promise<* | void>}
 */
export function requestUpdateUser(userRequest) {
    return request({
        url: API_BASE_URL + "/admin/user/update",
        method: 'PUT',
        body: JSON.stringify(userRequest)
    });
}

/**
 * [관리자] 유저에게 커스텀 SMS 문자전송
 * @param messageRequest
 * @returns {Promise<* | void>}
 */
export function sendCustomSmsMsgToUser(messageRequest) {
    return request({
        url: API_BASE_URL + "/sms/custom-message/send/user",
        method: 'POST',
        body: JSON.stringify(messageRequest)
    });
}