import {API_BASE_URL} from "../../constants";
import {request, requestFile} from "../../util/APIUtils";

/**
 * [관리자] 모의고사 스케쥴 목록조회
 *
 * @param mockExamScheduleRequest
 * @returns {Promise<* | void>}
 */
export function requestMockScheduleList(mockExamScheduleRequest) {
    return request({
        url: API_BASE_URL + "/admin/mock/schedule/list",
        method: 'POST',
        body: JSON.stringify(mockExamScheduleRequest)
    });
}

/**
 * [관리자] 모의고사 스케쥴 등록
 *
 * @param mockExamScheduleRequest
 * @returns {Promise<* | void>}
 */
export function requestAddMockSchedule(mockExamScheduleRequest) {
    return request({
        url: API_BASE_URL + "/admin/mock/schedule/add",
        method: 'POST',
        body: JSON.stringify(mockExamScheduleRequest)
    });
}

/**
 * [관리자] 모의고사 스케쥴 수정
 *
 * @param mockExamScheduleRequest
 * @returns {Promise<* | void>}
 */
export function requestUpdateMockSchedule(mockExamScheduleRequest) {
    return request({
        url: API_BASE_URL + "/admin/mock/schedule/update",
        method: 'PUT',
        body: JSON.stringify(mockExamScheduleRequest)
    });
}

/**
 * [관리자] 모의고사 스케쥴 삭제
 *
 * @param mockExamScheduleRequest
 * @returns {Promise<* | void>}
 */
export function requestRemoveMockSchedule(mockExamScheduleRequest) {
    return request({
        url: API_BASE_URL + "/admin/mock/schedule/delete",
        method: 'POST',
        body: JSON.stringify(mockExamScheduleRequest)
    });
}

/**
 * [관리자] 모의고사 스케쥴 엑셀업로드
 * @param file
 * @returns {*}
 */
export function requestMockScheduleExcelUpload(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("columnCount", 2);

    return requestFile(
        {
            url: API_BASE_URL + "/admin/mock/schedule/excelupload",
            method: 'POST',
        },
        formData
    )
}


export function requestMockExamSubjectList(collegeRequest) {
    return request({
        url: API_BASE_URL + "/admin/mock/subject/list",
        method: 'POST',
        body: JSON.stringify(collegeRequest)
    });
}

export function requestRemoveMockExamSubject(collegeRequest) {
    return request({
        url: API_BASE_URL + "/admin/mock/subject/delete",
        method: 'POST',
        body: JSON.stringify(collegeRequest)
    });
}

export function requestMockExamSubjectExcelUpload(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("columnCount", 4);

    return requestFile(
        {
            url: API_BASE_URL + "/admin/mock/subject/excelupload",
            method: 'POST',
        },
        formData
    )
}

