import {API_BASE_URL} from "../../constants";
import {request, requestFile} from "../../util/APIUtils";

/**
 * [관리자] 게시판 관리 > 공지사항 목록조회
 *
 * @param NoticeBoardRequest
 * @returns {Promise<* | void>}
 */
export function requestNoticeBoardList(NoticeBoardRequest) {
    return request({
        url: API_BASE_URL + "/admin/board/notice/list",
        method: 'POST',
        body: JSON.stringify(NoticeBoardRequest)
    });
}

/**
 * [관리자] 게시판 관리 > 공지사항 추가
 * @param NoticeBoardRequest
 * @param file
 * @returns {Promise<* | void>}
 */
export function requestAddNoticeBoard(NoticeBoardRequest, file) {
    const formData = new FormData()

    formData.append("adminNoticeBoardDTO", new Blob([JSON.stringify(NoticeBoardRequest)], {
        type: "application/json"
    }));
    formData.append('file', file);

    return requestFile({
            url: API_BASE_URL + "/admin/board/notice/add",
            method: 'POST'
        },
        formData
    );
}

/**
 * [관리자] 게시판 관리 > 공지사항 수정
 * @param NoticeBoardRequest
 * @param file
 * @returns {*}
 */
export function requestUpdateNoticeBoard(NoticeBoardRequest, file) {
    const formData = new FormData()

    formData.append("adminNoticeBoardDTO", new Blob([JSON.stringify(NoticeBoardRequest)], {
        type: "application/json"
    }));
    formData.append('file', file);

    return requestFile({
            url: API_BASE_URL + "/admin/board/notice/update",
            method: 'POST'
        },
        formData
    );
}

/**
 * [관리자] 게시판 관리 > 공지사항 삭제
 *
 * @param NoticeBoardRequest
 * @returns {Promise<* | void>}
 */
export function requestRemoveNoticeBoard(NoticeBoardRequest) {
    return request({
        url: API_BASE_URL + "/admin/board/notice/delete",
        method: 'POST',
        body: JSON.stringify(NoticeBoardRequest)
    });
}


