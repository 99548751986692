import React, {Component} from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import EssayManage from "./EssayManage";
import EssayAvailableManage from "./EssayAvailableManage";
import EssayPercentileManage from "./EssayPercentileManage";

/**
 * 논술 관리 메뉴
 */
class EssayMain extends Component {
    constructor(props, context, _) {
        super(props, context);
        //state의 초깃값 설정하기
        this.state = {
            value : '1',
        };
    }
    render() {
        const handleChange = ( event,newValue) => {
            this.setState({value:newValue});
        };

        return (
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={this.state.value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="논술 통합정보 및 최저등급 관리" value="1" />
                            <Tab label="논술 수학가능과목 관리" value="2" />
                            <Tab label="논술 대학 백분위 최고,최저 관리" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1"><EssayManage/></TabPanel>
                    <TabPanel value="2"><EssayAvailableManage/></TabPanel>
                    <TabPanel value="3"><EssayPercentileManage/></TabPanel>
                </TabContext>
            </Box>
        );
    }
}

EssayMain.propTypes = {};

export default EssayMain;