import {API_BASE_URL} from "../../constants";
import {request, requestFile} from "../../util/APIUtils";

/**
 * 논술 통합정보 및 최저등급 목록조회
 * @param collegeRequest
 * @returns {Promise<* | void>}
 */
export function requestEssayList(collegeRequest) {
    console.log(collegeRequest)
    return request({
        url: API_BASE_URL + "/admin/essay/list",
        method: 'POST',
        body: JSON.stringify(collegeRequest)
    });
}

/**
 * 선택한 논술 통합정보 및 최저등급 row삭제
 * @param collegeRequest
 * @returns {Promise<* | void>}
 */
export function requestRemoveEssayList(collegeRequest) {
    return request({
        url: API_BASE_URL + "/admin/essay/delete",
        method: 'POST',
        body: JSON.stringify(collegeRequest)
    });
}

/**
 * 논술 통합DB 엑셀업로드
 * "essay_list_tb", "essay_lowest_grade_list_tb"
 *
 * @param file
 * @returns {*}
 */
export function requestEssayExcelUpload(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("columnCount", 51);

    return requestFile(
        {
            url: API_BASE_URL + "/admin/essay/excelupload",
            method: 'POST',
        },
        formData
    )
}

/**
 * 논술_수학가능과목 목록조회
 * @param collegeRequest
 * @returns {Promise<* | void>}
 */
export function requestEssayMath(collegeRequest) {
    console.log(collegeRequest)
    return request({
        url: API_BASE_URL + "/admin/essay/math/list",
        method: 'POST',
        body: JSON.stringify(collegeRequest)
    });
}

/**
 * 선택한 논술_수학가능과목 row삭제
 * @param collegeRequest
 * @returns {Promise<* | void>}
 */
export function requestRemoveEssayMath(collegeRequest) {
    return request({
        url: API_BASE_URL + "/admin/essay/math/delete",
        method: 'POST',
        body: JSON.stringify(collegeRequest)
    });
}

/**
 * 논술_수학가능과목 엑셀업로드
 * "essay_available_math_list_tb"
 *
 * @param file
 * @returns {*}
 */
export function requestEssayMathExcelUpload(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("columnCount", 7);

    return requestFile(
        {
            url: API_BASE_URL + "/admin/essay/math/excelupload",
            method: 'POST',
        },
        formData
    )
}

/**
 * 논술 대학 백분위 최고,최저 목록조회
 * @param collegeRequest
 * @returns {Promise<* | void>}
 */
export function requestEssayPercentile(collegeRequest) {
    console.log(collegeRequest)
    return request({
        url: API_BASE_URL + "/admin/essay/percentile/list",
        method: 'POST',
        body: JSON.stringify(collegeRequest)
    });
}

/**
 * 선택한 논술 대학 백분위 최고,최저 row삭제
 * @param collegeRequest
 * @returns {Promise<* | void>}
 */
export function requestRemoveEssayPercentile(collegeRequest) {
    return request({
        url: API_BASE_URL + "/admin/essay/percentile/delete",
        method: 'POST',
        body: JSON.stringify(collegeRequest)
    });
}

/**
 * 논술 대학 백분위 최고,최저 엑셀업로드(내신 유불리)
 * "essay_available_math_list_tb"
 *
 * @param file
 * @returns {*}
 */
export function requestEssayPercentileExcelUpload(file, searchKey) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("searchKey", searchKey);
    formData.append("columnCount", 5);

    return requestFile(
        {
            url: API_BASE_URL + "/admin/essay/percentile/excelupload",
            method: 'POST',
        },
        formData
    )
}