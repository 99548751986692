import React, {Component} from 'react';
import Grid from "@mui/material/Grid";
import { GridColDef } from "@mui/x-data-grid";

import BasicSearchListComponent from "../list/BasicSearchListComponent";
import {
    requestEssayMath,
    requestRemoveEssayMath,
    requestEssayMathExcelUpload
} from "../../api/essay/EssayAPI";

/**
 * 논술관리 > 논술_수학가능과목 탭
 */
class EssayAvailableManage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            dense:false,
            secondary:false,
        }
    }

    componentDidMount() {

    }

    render() {
        const essayAvailableManageColumns: GridColDef[] = [
            { field: 'id', headerName: 'ID', width: 50 , hide: true},
            { field: 'collegeCode', headerName: '대학교코드', headerAlign: "center", width: 80 },
            { field: 'admissionSeriesCode', headerName: '전형계열코드', headerAlign: "center", width: 100 },
            { field: 'commonMath', headerName: '공통수학', headerAlign: "center", width: 80 },
            { field: 'suoneSutwo', headerName: '수1+수2', headerAlign: "center", width: 80 },
            { field: 'pbSt', headerName: '확통', headerAlign: "center", width: 80 },
            { field: 'dfIn', headerName: '미적', headerAlign: "center", width: 80 },
            { field: 'geometry', headerName: '기하', headerAlign: "center", width: 80 }
        ];

        return (
            <Grid container spacing={1}>
                <BasicSearchListComponent
                    init={requestEssayMath}
                    removeEvent={requestRemoveEssayMath}
                    excelupload={requestEssayMathExcelUpload}
                    columns={essayAvailableManageColumns}
                    addButtonUse={false}
                    title={"> 논술 수학가능과목 목록 (조회조건 : 대학교코드)"}
                />
            </Grid>
        );
    }
}

export default EssayAvailableManage;