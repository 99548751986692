import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
export default function LoadingComponent({open}) {


    return (
        <div>
            <Dialog open={open} >
                <DialogContent>
                    <DialogContentText>
                        <CircularProgress />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}