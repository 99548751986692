import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {requestCancel, requestPaymentsList} from "../../../api/pay/PayAPI";
import PaymentOrderUI from "./PaymentOrder.presenter";
import {camelCaseToSnakeCase} from "../../../util/CommonFunction";

export default function PaymentOrder(props) {

    const [loading, setLoading] = useState(false);

    const [searchKey, setSearchKey] = useState("all");
    const [searchWord, setSearchWord] = useState("");
    const [searchSort, setSearchSort] = useState({
        sortModel: [
            {
                field: camelCaseToSnakeCase('createDt'),
                sort: 'desc'
            }
        ]
    });

    const [paymentOrderGridOption, setPaymentOrderGridOption] = useState(
        {
            columns: [
                {field: 'id', headerName: 'ID', headerAlign: "center", width: 70, hide: true},
                {field: 'nickname', headerName: '닉네임', headerAlign: "center", width: 130, align: 'center'},
                {field: 'email', headerName: '이메일', headerAlign: "center", width: 200},
                {field: 'phone', headerName: '휴대폰번호', width: 130, headerAlign: "center", align: 'center'},
                {
                    field: 'isThreeGrade', headerName: '생활기록부 등록여부', width: 130, headerAlign: "center", align: 'center',
                    renderCell: (params) => (<>{params.row.isThreeGrade === false ? "미등록" : "등록"}</>)
                },
                {field: 'merchantUid', headerName: '주문번호', headerAlign: "center", width: 200},
                {field: 'orderState', headerName: '주문상태', headerAlign: "center", width: 150},
                {field: 'impUid', headerName: '결제주문번호', headerAlign: "center", width: 150, align: 'center'},
                {field: 'cancelAmount', headerName: '부분 환불 금액', headerAlign: "center", width: 130, align: 'right'},
                {field: 'paidAmount', headerName: '결제 금액', headerAlign: "center", width: 130, align: 'right'},
                {field: 'payServiceId', headerName: '주문서비스 타입 아이디', headerAlign: "center", width: 130},
                {field: 'cardName', headerName: '카드명', headerAlign: "center", width: 130},
                {field: 'cardNumber', headerName: '카드번호', headerAlign: "center", width: 200},
                {field: 'vbankCode', headerName: '가상계좌코드', headerAlign: "center", width: 130},
                {field: 'vbankName', headerName: '가상계좌이름', headerAlign: "center", width: 130},
                {field: 'contractId', headerName: '계약아이디', headerAlign: "center", width: 130},
                {
                    field: 'createDt',
                    headerName: '생성날짜',
                    headerAlign: "center",
                    width: 150,
                    align: 'center',
                    sortingOrder: ['desc', 'asc', null]
                },
                {field: 'updateDt', headerName: '수정날짜', headerAlign: "center", width: 150, align: 'center'},
                {field: 'productNm', headerName: '상품이름', headerAlign: "center", width: 200},
                {field: 'productCode', headerName: '상품코드', headerAlign: "center", width: 130},
                {field: 'productPrice', headerName: '상품가격', headerAlign: "center", width: 130, align: 'right'},
                {field: 'promotionDiscount', headerName: '프로모션 할인율', headerAlign: "center", width: 150, align: 'right'},
                {field: 'productImage', headerName: '상품 이미지', headerAlign: "center", width: 130}
            ],
            rows: [],
            title: "> 결제 주문내역",
            rowPerPageOptions: [15],
            pageSize: 15,
            Ids: [],
        }
    )
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    // select box
    const selectList = [
        {value: "all", name: "전체"},
        {value: "C.nickname", name: "닉네임"},
        {value: "C.email", name: "이메일"},
        {value: "C.phone", name: "휴대폰번호"},
        {value: "A.merchant_uid", name: "주문번호"},
        {value: "A.order_state", name: "주문상태"},
        {value: "A.imp_uid", name: "결제주문번호"},
        {value: "A.card_name", name: "카드명"},
        {value: "A.card_number", name: "카드번호"},
        {value: "B.product_nm", name: "상품이름"}
    ];

    /** 검색조건 변경 이벤트 **/
    const onChangeSearchKey = (e) => {
        setSearchKey(e.target.value);
    }

    /** 검색어 입력 이벤트 **/
    const onChangeSearchWord = (e) => {
        setSearchWord(e.target.value);
    }

    /** 검색 버튼 이벤트 **/
    const onClickSearch = async () => {
        const newRows = await searchList();
        setRows(newRows.list);
        setTotalRows(newRows.totalCount);
    }

    const onKeyDownEnter = e => {
        if (e.key === 'Enter') {
            onClickSearch(); // Enter 입력이 되면 클릭 이벤트 실행
        }
    };

    /** 검색목록 조회 **/
    const searchList = function () {
        return new Promise((resolve) => {
            setTimeout(() => {
                const body = {
                    searchKey: searchKey === 'all' ? selectList.filter(item => item.value !== 'all').map(item => {
                        return item.value
                    }).join('`') : searchKey,
                    searchWord: searchWord,
                    searchSort: searchSort.sortModel ? searchSort.sortModel[0] : null,
                    page: page * paymentOrderGridOption.pageSize,
                    pageSize: paymentOrderGridOption.pageSize
                }
                requestPaymentsList(body).then((response) => {
                    resolve(response);
                })
            }, Math.random() * 100 + 100)
        });
    }

    /**
     * 그리드 헤더 정렬 이벤트
     * @type {(function(*): void)|*}
     */
    const handleSortModelChange = async (sortModel) => {
        // Here you save the data you need from the sort model
        setSearchSort({
            sortModel: [
                {
                    field: camelCaseToSnakeCase(sortModel[0].field),
                    sort: sortModel[0].sort
                }
            ]
        })
    };

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            const newRows = await searchList();

            if (!active) {
                return;
            }

            setRows(newRows.list);
            setTotalRows(newRows.totalCount);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchSort]);

    const onClickCancel = () => {
        console.log(paymentOrderGridOption.Ids);
        if (paymentOrderGridOption.Ids.length < 1) {
            alert("취소할 리스트를 선택해주세요")
            return;
        }
        requestCancel({payOrderId: paymentOrderGridOption.Ids[0], cancelReason: "운영자 취소"}).then((data) => {
            alert(data.message);
            // eslint-disable-next-line no-restricted-globals
            location.reload()
        })
    }

    return (
        <Grid container spacing={1}>
            <PaymentOrderUI
                gridOption={paymentOrderGridOption}
                rows={rows}
                totalRows={totalRows}
                setPage={setPage}
                handleSortModelChange={handleSortModelChange}
                loading={loading}
                setGridOption={setPaymentOrderGridOption}
                onChangeSearchKey={onChangeSearchKey}
                onChangeSearchWord={onChangeSearchWord}
                onKeyDownEnter={onKeyDownEnter}
                onClickSearch={onClickSearch}
                onClickCancel={onClickCancel}
                selectList={selectList}
            />
        </Grid>
    );
}