import {API_BASE_URL} from "../../constants";
import {request} from "../../util/APIUtils";

/**
 * [관리자] 사정단 목록조회
 *
 * @param officerRequest
 * @returns {Promise<* | void>}
 */
export function requestOfficerList(officerRequest) {
    return request({
        url: API_BASE_URL + "/admin/officer/list",
        method: 'POST',
        body: JSON.stringify(officerRequest)
    });
}

/**
 * [관리자] 사정단 정보 등록
 *
 * @param officerRequest
 * @returns {Promise<* | void>}
 */
export function requestAddOfficer(officerRequest) {
    return request({
        url: API_BASE_URL + "/admin/officer/add",
        method: 'POST',
        body: JSON.stringify(officerRequest)
    });
}

/**
 * [관리자] 사정단 정보 수정
 *
 * @param officerRequest
 * @returns {Promise<* | void>}
 */
export function requestUpdateOfficer(officerRequest) {
    return request({
        url: API_BASE_URL + "/admin/officer/update",
        method: 'PUT',
        body: JSON.stringify(officerRequest)
    });
}

/**
 * [관리자] 사정단 정보 삭제(사정단 정보 삭제여부 "Y"로 변경)
 *
 * @param officerRequest
 * @returns {Promise<* | void>}
 */
export function requestRemoveOffcier(officerRequest) {
    return request({
        url: API_BASE_URL + "/admin/officer/delete",
        method: 'POST',
        body: JSON.stringify(officerRequest)
    });
}

/**
 * [관리자] 사정단 승인
 *
 * @param officerRequest
 * @returns {Promise<* | void>}
 */
export function requestApproveOffcier(officerRequest) {
    return request({
        url: API_BASE_URL + "/admin/officer/approve",
        method: 'POST',
        body: JSON.stringify(officerRequest)
    });
}

/**
 * [관리자] 사정단 반려
 * @param officerRequest
 * @returns {Promise<* | void>}
 */
export function requestRejectOffcier(officerRequest) {
    return request({
        url: API_BASE_URL + "/admin/officer/reject",
        method: 'POST',
        body: JSON.stringify(officerRequest)
    });
}


