import { ACCESS_TOKEN, REFRESH_TOKEN } from "../constants";
import axios from "axios";

export const request = (options) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
      RefreshToken: `Bearer ${localStorage.getItem(REFRESH_TOKEN)}`,
    },
  };

  options = Object.assign({}, headers, options);

  return fetch(options.url, options)
    .then((response) => {
      return response.json().then((json) => {
        console.log("result : ", json);
        if (!response.ok) {
          if (response.status === 401) {
            localStorage.clear();
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          }
        }
        return json;
      });
    })
    .catch((error) => {
      console.log("qwfqwfqwf", error);
    });
};

export const requestFile = (options, formData) => {
  const headers = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
      RefreshToken: `Bearer ${localStorage.getItem(REFRESH_TOKEN)}`,
    },
  };

  return axios.post(options.url, formData, headers);
};

export const requestGet = (options, formData) => {
  const headers = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
      RefreshToken: `Bearer ${localStorage.getItem(REFRESH_TOKEN)}`,
    },
  };

  return axios.post(options.url, formData, headers);
};
