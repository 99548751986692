import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {
    requestAddOfficer,
    requestApproveOffcier,
    requestOfficerList,
    requestRejectOffcier,
    requestRemoveOffcier,
    requestUpdateOfficer
} from "../../../api/officer/OfficerAPI";
import OfficerUI from "./Officer.presenter";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import {camelCaseToSnakeCase} from "../../../util/CommonFunction";

/**
 * 모의고사 스케쥴 관리
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Officer(props) {
    const [loading, setLoading] = useState(false);

    const [searchKey, setSearchKey] = useState("all");
    const [searchWord, setSearchWord] = useState("");
    const [searchSort, setSearchSort] = useState({});

    //그리드 옵션
    const [officerGridOption, setOfficerGridOption] = useState(
        {
            columns: [
                {field: 'id', headerName: 'ID', width: 70, hide: true},
                {
                    field: "actions",
                    headerClassName: "super-app-theme--header",
                    headerName: "수정",
                    headerAlign: "center",
                    width: 80,
                    disableColumnMenu: true,
                    editable: false,
                    renderCell: (params) => (
                        <>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <IconButton color="primary" onClick={() => editOfficer(params.row)}>
                                    <EditIcon/>
                                </IconButton>
                            </Box>
                        </>
                    )
                },
                {field: 'email', headerName: '이메일', headerAlign: "center", width: 200, align: 'center'},
                {field: 'officerName', headerName: '평가자이름', headerAlign: "center", width: 180, align: 'center'},
                {field: 'phone', headerName: '휴대폰 번호', headerAlign: "center", width: 180, align: 'center'},
                {field: 'approvalStatus', headerName: '승인상태', headerAlign: "center", width: 100, align: 'center'},
                {field: 'rejectReason', headerName: '거절사유', headerAlign: "center", width: 150},
                {field: 'university', headerName: '출신대학', headerAlign: "center", width: 150, align: 'center'},
                {field: 'education', headerName: '학과/학력', headerAlign: "center", width: 150, align: 'center'},
                {field: 'nickname', headerName: '닉네임', headerAlign: "center", width: 180, align: 'center'},
                {
                    field: 'officerProfileImage',
                    headerName: '프로필 이미지',
                    headerAlign: "center",
                    width: 180,
                    sortable: false
                },
                {field: 'officerApplyFile', headerName: '증빙 내용 파일', headerAlign: "center", width: 130, sortable: false},
                {
                    field: 'providerType',
                    headerName: 'ouath타입',
                    headerAlign: "center",
                    width: 130,
                    align: 'center',
                    sortable: false
                },
                {
                    field: 'delYn',
                    headerName: '탈퇴여부',
                    headerAlign: "center",
                    width: 130,
                    align: 'center',
                    sortable: false
                },
                {field: 'createDt', headerName: '등록날짜', headerAlign: "center", width: 150, align: 'center'},
                {field: 'updateDt', headerName: '수정날짜', headerAlign: "center", width: 150, align: 'center'}
            ],
            rows: [],
            title: "> 사정단 정보 목록",
            rowPerPageOptions: [15],
            pageSize: 15,
            Ids: []
        }
    )
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    // select box
    const selectList = [
        {value: "all", name: "전체"},
        {value: "A.email", name: "이메일"},
        {value: "B.officer_name", name: "평가자이름"},
        {value: "A.phone", name: "휴대폰번호"}
    ];

    /**
     * 초기화 데이터
     */
    let initialFormData = {
        id: null,
        email: "@turtleschool.kr",
        nickname: "",
        password: "",
        phone: "",
        rejectReason: "",
        university: "",
        education: "",
        officerName: "",
        delYn: "N",
        initEmail: "",
        initPhone: ""
    };
    // 다이얼로그 데이터 초기화
    const [officerData, setOfficerData] = useState(initialFormData);
    // 다이얼로그 Open 여부 초기화
    const [openOfficerDialog, setOpenOfficerDialog] = useState(false);

    /** 검색조건 변경 이벤트 **/
    const onChangeSearchKey = (e) => {
        setSearchKey(e.target.value);
    }

    /**
     * 검색어 입력 변경 이벤트
     */
    const onChangeSearchWord = (e) => {
        setSearchWord(e.target.value);
    }

    /**
     * 검색 버튼 클릭 이벤트
     */
    const onClickSearch = async () => {
        const newRows = await searchList();
        setRows(newRows.list);
        setTotalRows(newRows.totalCount);
    }

    const onKeyDownEnter = e => {
        if (e.key === 'Enter') {
            onClickSearch(); // Enter 입력이 되면 클릭 이벤트 실행
        }
    };

    /**
     * 검색목록 조회
     */
    function searchList() {
        return new Promise((resolve) => {
            setTimeout(() => {
                const body = {
                    searchKey: searchKey === 'all' ? selectList.filter(item => item.value !== 'all').map(item => {
                        return item.value
                    }).join('`') : searchKey,
                    searchWord: searchWord,
                    searchSort: searchSort.sortModel ? searchSort.sortModel[0] : null,
                    page: page * officerGridOption.pageSize,
                    pageSize: officerGridOption.pageSize
                }
                requestOfficerList(body).then((response) => {
                    resolve(response);
                })
            }, Math.random() * 100 + 100)
        });
    }

    /**
     * 다이얼로그 추가/수정 이벤트
     */
    const saveOfficer = async (e) => {
        try {
            e.preventDefault();

            if (officerData.id) {
                requestUpdateOfficer(officerData).then(response => {
                    if (response.status < 400) {
                        alert('수정이 완료되었습니다.');
                    } else {
                        alert('수정에 실패하였습니다.\n실패 사유 : ' + response.message);
                    }
                }).finally(async () => {
                    setOpenOfficerDialog(false);
                    await clearOfficerData();
                    const newRows = await searchList();
                    setRows(newRows.list);
                    setTotalRows(newRows.totalCount);
                });
            } else {
                requestAddOfficer(officerData).then(response => {
                    if (response.status < 400) {
                        alert('등록이 완료되었습니다.');
                    } else {
                        alert('등록에 실패하였습니다.\n실패 사유 : ' + response.message);
                    }
                }).finally(async () => {
                    setOpenOfficerDialog(false);
                    await clearOfficerData();
                    const newRows = await searchList();
                    setRows(newRows.list);
                    setTotalRows(newRows.totalCount);
                });
            }
        } catch (err) {
            alert(err.message);
        }
    };

    /**
     * 다이얼로그 수정창 띄우기 이벤트
     */
    const editOfficer = async (officerData) => {
        setOpenOfficerDialog(true);

        officerData.initEmail = officerData.email;
        officerData.initPhone = officerData.phone;

        setOfficerData(officerData);
    };

    /**
     * 승인 버튼 클릭 이벤트
     */
    const onClickApproveButton = e => {
        if (officerGridOption.Ids.length) {
            if (officerGridOption.Ids.length > 1) {
                alert("유저를 한명만 선택해주세요.")
            } else {
                requestApproveOffcier({id: officerGridOption.Ids[0]}).then(response => {
                    if (response.status < 400) {
                        alert('승인이 완료되었습니다.');
                    } else {
                        alert('승인이 실패하였습니다.\n실패 사유 : ' + response.message);
                    }
                }).finally(async () => {
                    const newRows = await searchList();
                    setRows(newRows.list);
                    setTotalRows(newRows.totalCount);
                });
            }
        } else {
            alert('1건 이상 선택해 주세요!');
        }
    }

    /**
     * 반려 버튼 클릭 이벤트
     */
    const onClickRejectButton = e => {
        if (officerGridOption.Ids.length) {
            if (officerGridOption.Ids.length > 1) {
                alert("유저를 한명만 선택해주세요.")
            } else {
                requestRejectOffcier({id: officerGridOption.Ids[0]}).then(response => {
                    if (response.status < 400) {
                        alert('반려가 완료되었습니다.');
                    } else {
                        alert('반려가 실패하였습니다.\n실패 사유 : ' + response.message);
                    }
                }).finally(async () => {
                    const newRows = await searchList();
                    setRows(newRows.list);
                    setTotalRows(newRows.totalCount);
                });
            }
        } else {
            alert('1건 이상 선택해 주세요!');
        }
    }

    /**
     * 삭제 버튼 클릭 이벤트
     */
    const onClickDeleteButton = e => {
        if (officerGridOption.Ids.length) {
            requestRemoveOffcier({ids: officerGridOption.Ids}).then(response => {
                if (response.status < 400) {
                    alert('삭제가 완료되었습니다.');
                } else {
                    alert('삭제가 실패하였습니다.\n실패 사유 : ' + response.message);
                }
            }).finally(async () => {
                const newRows = await searchList();
                setRows(newRows.list);
                setTotalRows(newRows.totalCount);
            });
        } else {
            alert('1건 이상 선택해 주세요!');
        }
    }

    /**
     * 다이얼로그 값 초기화
     */
    const clearOfficerData = async () => {
        setOfficerData(initialFormData);
    };

    /**
     * 다이얼로그 값 변경 이벤트
     */
    const handleChanged = (event, value) => {
        setOfficerData({
            ...officerData,
            [event.target.name]: event.target.value
        });
    };

    /**
     * 다이얼로그 열기 이벤트
     */
    const handleClickedOpen = () => {
        setOpenOfficerDialog(true);
    };

    /**
     * 다이얼로그 닫기 이벤트
     */
    const handleClosed = () => {
        setOpenOfficerDialog(false);
        clearOfficerData();
    };

    /**
     * 그리드 헤더 정렬 이벤트
     * @type {(function(*): void)|*}
     */
    const handleSortModelChange = async (sortModel) => {
        // Here you save the data you need from the sort model
        setSearchSort({
            sortModel: [
                {
                    field: camelCaseToSnakeCase(sortModel[0].field),
                    sort: sortModel[0].sort
                }
            ]
        })
    };

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            const newRows = await searchList();

            if (!active) {
                return;
            }

            setRows(newRows.list);
            setTotalRows(newRows.totalCount);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchSort]);

    return (
        <Grid container spacing={1}>
            <OfficerUI
                gridOption={officerGridOption}
                rows={rows}
                totalRows={totalRows}
                setPage={setPage}
                handleSortModelChange={handleSortModelChange}
                loading={loading}
                setGridOption={setOfficerGridOption}
                onChangeSearchKey={onChangeSearchKey}
                onChangeSearchWord={onChangeSearchWord}
                onKeyDownEnter={onKeyDownEnter}
                onClickSearch={onClickSearch}
                officerData={officerData}
                handleClickedOpen={handleClickedOpen}
                handleClosed={handleClosed}
                handleChanged={handleChanged}
                openOfficerDialog={openOfficerDialog}
                saveOfficer={saveOfficer}
                onClickApproveButton={onClickApproveButton}
                onClickRejectButton={onClickRejectButton}
                onClickDeleteButton={onClickDeleteButton}
                selectList={selectList}
            />
        </Grid>
    );
}