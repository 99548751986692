import React, {Component} from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel'
import NoticeBoard from "./noticeBoard/NoticeBoard.container";

class BoardMain extends Component {


    constructor(props, context, _) {
        super(props, context);
        //state의 초깃값 설정하기
        this.state = {
            value: '1',
        };
    }


    render() {
        const handleChange = (event, newValue) => {
            this.setState({value: newValue});
        };

        return (
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={this.state.value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="공지사항 관리" value="1"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1"><NoticeBoard/></TabPanel>
                </TabContext>
            </Box>
        );
    }
}


export default BoardMain;