import {API_BASE_URL} from "../../constants";
import {request, requestFile} from "../../util/APIUtils";

/**
 * [관리자] 학종_통합DB 목록조회
 *
 * @param earlydStudentRequest
 * @returns {Promise<* | void>}
 */
export function requestEarlydStudentRecordList(earlydStudentRequest) {
    return request({
        url: API_BASE_URL + "/admin/earlyd/studentrecord/list",
        method: 'POST',
        body: JSON.stringify(earlydStudentRequest)
    });
}


/**
 * [관리자] 학종_통합DB row 삭제
 *
 * @param earlydStudentRequest
 * @returns {Promise<* | void>}
 */
export function requestRemoveEarlydStudentRecord(earlydStudentRequest) {
    return request({
        url: API_BASE_URL + "/admin/earlyd/studentrecord/delete",
        method: 'POST',
        body: JSON.stringify(earlydStudentRequest)
    });
}

/**
 * [관리자] 학종_통합DB 엑셀업로드
 *
 * @param file
 * @returns {*}
 */
export function requestEarlydStudentRecordExcelUpload(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("columnCount", 142);

    return requestFile(
        {
            url: API_BASE_URL + "/admin/earlyd/studentrecord/excelupload",
            method: 'POST',
        },
        formData
    )
}

/**
 * [관리자]수시_학종_계열 목록조회
 *
 * @param earlydSeriesRequest
 * @returns {Promise<* | void>}
 */
export function requestEarlydSeriesList(earlydSeriesRequest) {
    return request({
        url: API_BASE_URL + "/admin/earlyd/series/list",
        method: 'POST',
        body: JSON.stringify(earlydSeriesRequest)
    });
}


/**
 * [관리자]수시_학종_계열 삭제
 *
 * @param earlydSeriesRequest
 * @returns {Promise<* | void>}
 */
export function requestRemoveEarlydSeries(earlydSeriesRequest) {
    return request({
        url: API_BASE_URL + "/admin/earlyd/series/delete",
        method: 'POST',
        body: JSON.stringify(earlydSeriesRequest)
    });
}

/**
 * [관리자] 수시_학종_계열 엑셀업로드
 *
 * @param file
 * @returns {*}
 */
export function requestEarlydSeriesExcelUpload(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("columnCount", 4);

    return requestFile(
        {
            url: API_BASE_URL + "/admin/earlyd/series/excelupload",
            method: 'POST',
        },
        formData
    )
}


/**
 * [관리자]수시_학종_계열_필수과목 목록조회
 *
 * @param earlydSeriesRequiredSubjectRequest
 * @returns {Promise<* | void>}
 */
export function requestEarlydSeriesRequiredSubjectList(earlydSeriesRequiredSubjectRequest) {
    return request({
        url: API_BASE_URL + "/admin/earlyd/series/required/subject/list",
        method: 'POST',
        body: JSON.stringify(earlydSeriesRequiredSubjectRequest)
    });
}


/**
 * [관리자]수시_학종_계열_필수과목 삭제
 *
 * @param earlydSeriesRequiredSubjectRequest
 * @returns {Promise<* | void>}
 */
export function requestRemoveEarlydSeriesRequiredSubject(earlydSeriesRequiredSubjectRequest) {
    return request({
        url: API_BASE_URL + "/admin/earlyd/series/required/subject/delete",
        method: 'POST',
        body: JSON.stringify(earlydSeriesRequiredSubjectRequest)
    });
}

/**
 * [관리자] 수시_학종_계열_필수과목 엑셀업로드
 *
 * @param file
 * @returns {*}
 */
export function requestEarlydSeriesRequiredSubjectExcelUpload(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("columnCount", 5);

    return requestFile(
        {
            url: API_BASE_URL + "/admin/earlyd/series/required/subject/excelupload",
            method: 'POST',
        },
        formData
    )
}