import './App.css';
import Dashboard from './component/Dashboard';
import {Context} from './util/AppContext';

import * as React from "react";
import {useEffect, useState} from "react";
import Login from "./component/login/Login";
function App() {

  const [isLogin,setIsLogin] = useState(false);

  useEffect(()=>{
      if(localStorage.getItem("accessToken")){
          setIsLogin(true);
      }

  },[])


  return (

    <div className="App" style={{width:"100%",height:"100%"}}>
        <Context.Provider value={{isLogin,setIsLogin}}>
            {isLogin ? <Dashboard setIsLogin={setIsLogin}/> : <Login setIsLogin={setIsLogin}/>}
        </Context.Provider>
    </div>

  );
}

export default App;
