import React, {Component} from 'react';
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import IntegratedDashBoard from "./integratedDashBoard/IntegratedDashBoard.container";


/**
 * 대시보드 관리 Main
 */
class DashBoardMain extends Component {
    constructor(props, context, _) {
        super(props, context);
        //state의 초깃값 설정하기
        this.state = {
            value: '1',
        };
    }

    render() {
        const handleChange = (event, newValue) => {
            this.setState({value: newValue});
        };

        return (
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={this.state.value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="DashBoard" value="1"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1"><IntegratedDashBoard/></TabPanel>
                </TabContext>
            </Box>
        );
    }
}

DashBoardMain.propTypes = {};

export default DashBoardMain;