import React, {useEffect, useRef, useState} from 'react';
import Grid from "@mui/material/Grid";
import EarlydSubjectCodeUI from "./EarlydSubjectCode.presenter";
import {
    requestEarlydSubjectCodeExcelUpload,
    requestEarlydSubjectCodeList,
    requestRemoveEarlydSubjectCode
} from "../../../api/commonCode/earlySubjectCodeAPI";
import LoadingComponent from "../../common/loadding/LoadingComponent";
import {camelCaseToSnakeCase} from "../../../util/CommonFunction";

/**
 * 교과 과목코드 관리
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function EarlydSubjectCode(props) {
    const [loading, setLoading] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);

    const fileInput = useRef();
    const [searchKey, setSearchKey] = useState("all");
    const [searchWord, setSearchWord] = useState("");
    const [searchSort, setSearchSort] = useState({});

    const [earlydSubjectCodeOption, setEarlydSubjectCodeGridOption] = useState(
        {
            columns: [
                {field: 'id', headerName: 'ID', headerAlign: "center", width: 70, hide: true},
                {field: 'mainSubjectCode', headerName: '교과코드', headerAlign: "center", width: 130, align: 'center'},
                {field: 'mainSubjectName', headerName: '교과이름', headerAlign: "center", width: 130},
                {field: 'subjectCode', headerName: '과목코드', headerAlign: "center", width: 130, align: 'center'},
                {field: 'subjectName', headerName: '과목이름', headerAlign: "center", width: 130},
                {field: 'type', headerName: '유형', headerAlign: "center", width: 130}
            ],
            rows: [],
            title: "> 교과 과목코드 목록",
            rowPerPageOptions: [15],
            pageSize: 15,
            Ids: []
        }
    )
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    // select box
    const selectList = [
        {value: "all", name: "전체"},
        {value: "A.main_subject_code", name: "교과코드"},
        {value: "A.main_subject_name", name: "교과이름"},
        {value: "A.subject_code", name: "과목코드"},
        {value: "A.subject_name", name: "과목이름"},
        {value: "A.type", name: "유형"}
    ];

    /** 검색조건 변경 이벤트 **/
    const onChangeSearchKey = (e) => {
        setSearchKey(e.target.value);
    }

    /** 검색어 입력 이벤트 **/
    const onChangeSearchWord = (e) => {
        setSearchWord(e.target.value);
    }

    /** 검색 버튼 이벤트 **/
    const onClickSearch = async () => {
        const newRows = await searchList();
        setRows(newRows.list);
        setTotalRows(newRows.totalCount);
    }

    const onKeyDownEnter = e => {
        if (e.key === 'Enter') {
            onClickSearch(); // Enter 입력이 되면 클릭 이벤트 실행
        }
    };

    /** 검색목록 조회 **/
    function searchList() {
        return new Promise((resolve) => {
            setTimeout(() => {
                const body = {
                    searchKey: searchKey === 'all' ? selectList.filter(item => item.value !== 'all').map(item => {
                        return item.value
                    }).join('`') : searchKey,
                    searchWord: searchWord,
                    searchSort: searchSort.sortModel ? searchSort.sortModel[0] : null,
                    page: page * earlydSubjectCodeOption.pageSize,
                    pageSize: earlydSubjectCodeOption.pageSize
                }
                requestEarlydSubjectCodeList(body).then((response) => {
                    resolve(response);
                })
            }, Math.random() * 100 + 100)
        });
    }

    /**
     * 삭제 버튼 클릭 이벤트
     */
    const onClickDeleteButton = e => {
        if (earlydSubjectCodeOption.Ids.length) {
            requestRemoveEarlydSubjectCode({ids: earlydSubjectCodeOption.Ids}).then(response => {
                if (response.status < 400) {
                    alert('삭제가 완료되었습니다.');
                } else {
                    alert('삭제가 실패하였습니다.\n실패 사유 : ' + response.message);
                }
            }).finally(async () => {
                const newRows = await searchList();
                setRows(newRows.list);
                setTotalRows(newRows.totalCount);
            });
        } else {
            alert('1건 이상 선택해 주세요!');
        }
    }

    /**
     * 엑셀 업로드 이벤트
     */
    const excelUpload = e => {
        console.log(e.target.files[0]);
        const formData = new FormData();
        formData.append("file", fileInput.length && fileInput[0].uploadedFile);
        setLoadingOpen(true);
        requestEarlydSubjectCodeExcelUpload(e.target.files[0]).then(response => {
            if (response.data.status) {
                alert("엑셀 업로드가 완료되었습니다.")
            } else {
                alert('엑셀 업로드가 실패하였습니다.\n실패 사유 : ' + response.data.message)
            }
            setLoadingOpen(false);
        }).finally(async () => {
            fileInput.current.value = "";
            const newRows = await searchList();
            setRows(newRows.list);
            setTotalRows(newRows.totalCount);
        });
    }

    /**
     * 엑셀 업로드 파일 클릭 이벤트
     */
    const onClickExcelUploadFile = e => {
        fileInput.current.click();
    };

    /**
     * 그리드 헤더 정렬 이벤트
     * @type {(function(*): void)|*}
     */
    const handleSortModelChange = async (sortModel) => {
        // Here you save the data you need from the sort model
        setSearchSort({
            sortModel: [
                {
                    field: camelCaseToSnakeCase(sortModel[0].field),
                    sort: sortModel[0].sort
                }
            ]
        })
    };

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            const newRows = await searchList();

            if (!active) {
                return;
            }

            setRows(newRows.list);
            setTotalRows(newRows.totalCount);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchSort]);

    return (
        <Grid container spacing={1}>
            <LoadingComponent open={loadingOpen}/>
            <EarlydSubjectCodeUI
                gridOption={earlydSubjectCodeOption}
                rows={rows}
                totalRows={totalRows}
                setPage={setPage}
                handleSortModelChange={handleSortModelChange}
                loading={loading}
                setGridOption={setEarlydSubjectCodeGridOption}
                onChangeSearchKey={onChangeSearchKey}
                onChangeSearchWord={onChangeSearchWord}
                onKeyDownEnter={onKeyDownEnter}
                onClickSearch={onClickSearch}
                onClickDeleteButton={onClickDeleteButton}
                excelUpload={excelUpload}
                excelUploadFile={fileInput}
                onClickExcelUploadFile={onClickExcelUploadFile}
                selectList={selectList}
            />
        </Grid>
    );
}