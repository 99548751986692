import React from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import {ButtonGroup, Radio, RadioGroup} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {NoticeBoardeImage} from "./NoticeBoard.styles";

/**
 * 사정단 정보 관리 UI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function NoticeBoardUI(props) {

    return (
        <>
            <Box sx={{display: "flex", height: 40, width: '100%', justifyContent: "flex-end", marginBottom: "10px"}}>
                <Box sx={{
                    justifyContent: 'flex-end',
                    flexDirection: "coulmn",
                    alignItems: "center",
                    marginRight: "10px"
                }}>
                    <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        variant="filled"
                        size="small"
                        onChange={props.onChangeSearchWord}
                        sx={{marginLeft: "10px", marginRight: "10px"}}
                    />
                    <Button variant="contained" onClick={props.onClickSearch} endIcon={<SearchIcon/>}>
                        검색
                    </Button>
                </Box>

                <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{paddingLeft: 3}}>
                    <Button onClick={props.handleClickedOpen} type="submit">
                        추가
                    </Button>
                    <>
                        <Dialog
                            // disableBackdropClick
                            open={props.openNoticeBoardDialog}
                            onClose={props.handleClosed}
                            aria-labelledby="form-dialog-title"
                            fullWidth={true}
                        >
                            <DialogTitle id="form-dialog-title">
                                {props.noticeBoardData.id ? "공지사항 정보 수정" : "공지사항 정보 등록"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {props.noticeBoardData.id ? "공지사항 정보 수정" : "공지사항 정보 등록"}
                                </DialogContentText>
                                <div>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="title"
                                        name="title"
                                        label="게시판 제목"
                                        type="text"
                                        defaultValue={props.noticeBoardData.title}
                                        value={props.noticeBoardData.title}
                                        onChange={props.handleChanged}
                                        fullWidth
                                    />
                                </div>
                                <div>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="content"
                                        name="content"
                                        label="게시판 내용"
                                        type="text"
                                        variant="outlined"
                                        defaultValue={props.noticeBoardData.content}
                                        value={props.noticeBoardData.content}
                                        onChange={props.handleChanged}
                                        rows={2}
                                        multiline
                                        maxRows={4}
                                        fullWidth
                                    />
                                </div>
                                <div>
                                    <FormControl>
                                        <FormLabel
                                            sx={{marginTop: "10px"}}
                                            id="demo-controlled-radio-buttons-group"
                                            className="text-align-center">
                                            공지 유형
                                        </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            id="type"
                                            name="type"
                                            style={{display: "flex", flexDirection: "row"}}
                                            defaultValue={props.noticeBoardData.type}
                                            value={props.noticeBoardData.type}
                                            onChange={props.handleChanged}
                                        >
                                            <FormControlLabel value="none" control={<Radio/>} label="적용안함"/>
                                            <FormControlLabel value="popup" control={<Radio/>} label="팝업 적용"/>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                <div>
                                    <TextField
                                        sx={{marginTop: "10px"}}
                                        id="visibleWidthSize"
                                        name="visibleWidthSize"
                                        label="이미지 너비(px)"
                                        type="number"
                                        InputProps={{inputProps: {min: 200, max: 1000}}}
                                        variant="outlined"
                                        defaultValue={props.noticeBoardData.visibleWidthSize}
                                        value={props.noticeBoardData.visibleWidthSize}
                                        onChange={props.handleChanged}
                                    />
                                    <TextField
                                        sx={{marginTop: "10px", marginLeft: "10px"}}
                                        id="visibleHeightSize"
                                        name="visibleHeightSize"
                                        label="이미지 높이(px)"
                                        type="number"
                                        InputProps={{inputProps: {min: 200, max: 1000}}}
                                        variant="outlined"
                                        defaultValue={props.noticeBoardData.visibleHeightSize}
                                        value={props.noticeBoardData.visibleHeightSize}
                                        onChange={props.handleChanged}
                                    />
                                </div>
                                <div>
                                    <FormControl>
                                        <FormLabel id="demo-controlled-radio-buttons-group" sx={{marginTop: "10px"}}>게시판
                                            상태</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            id="status"
                                            name="status"
                                            style={{display: "flex", flexDirection: "row"}}
                                            defaultValue={props.noticeBoardData.status}
                                            value={props.noticeBoardData.status}
                                            onChange={props.handleChanged}
                                        >
                                            <FormControlLabel value="0" control={<Radio/>} label="비활성화"/>
                                            <FormControlLabel value="1" control={<Radio/>} label="활성화"/>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                <div>
                                    <FormControl>
                                        <FormLabel id="demo-controlled-radio-buttons-group" sx={{marginTop: "10px"}}>
                                            공지사항 이미지(수정 시 이미지 다시 업로드)
                                        </FormLabel>
                                        <NoticeBoardeImage src={props.image} onClick={props.handleImage}/>
                                        <input id="file"
                                               type="file"
                                               ref={props.imageInput}
                                               onChange={(event) => props.handleImageData(event)}
                                        />
                                    </FormControl>
                                </div>
                                <div>
                                    <FormControl fullWidth>
                                        <TextField
                                            sx={{marginTop: "10px"}}
                                            autoFocus
                                            margin="dense"
                                            id="connectionUrl"
                                            name="connectionUrl"
                                            label="연결URL(이미지 클릭 시 URL경로)"
                                            type="text"
                                            defaultValue={props.noticeBoardData.connectionUrl}
                                            value={props.noticeBoardData.connectionUrl}
                                            onChange={props.handleChanged}
                                        />
                                    </FormControl>
                                </div>
                                <div>
                                    <Box>
                                        <InputLabel id="demo-simple-select-label" sx={{marginTop: "10px"}}>게시
                                            시작시간</InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                id="startDt"
                                                name="startDt"
                                                onChange={(newValue) => props.handleStartDtChanged(newValue)}
                                                value={props.noticeBoardData.startDt}
                                            />
                                        </LocalizationProvider>
                                        <InputLabel id="demo-simple-select-label" sx={{marginTop: "10px"}}>게시
                                            종료시간</InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                id="endDt"
                                                name="endDt"
                                                onChange={(newValue) => props.handleEndDtChanged(newValue)}
                                                value={props.noticeBoardData.endDt}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                {props.noticeBoardData.id ?
                                    <Button onClick={props.saveNoticeBoard} variant="contained" color="warning">
                                        수정
                                    </Button>
                                    :
                                    <Button onClick={props.saveNoticeBoard} variant="contained" color="primary">
                                        등록
                                    </Button>
                                }
                                <Button onClick={props.handleClosed} color="primary">
                                    닫기
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>

                    <Button color="error" onClick={props.onClickDeleteButton}>삭제</Button>
                </ButtonGroup>
            </Box>
            <Box sx={{height: 400, width: '100%'}}>
                <Typography>
                    {props.gridOption.title}
                </Typography>
                <DataGrid
                    sx={{
                        boxShadow: 2,
                        border: 2,
                        borderColor: 'primary.light', '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        }
                    }}
                    autoHeight
                    density={"compact"}
                    components={{Toolbar: GridToolbar}}
                    rows={props.rows}
                    rowCount={props.totalRows}
                    columns={props.gridOption.columns}
                    pageSize={props.gridOption.pageSize}
                    rowsPerPageOptions={props.gridOption.rowPerPageOptions}
                    paginationMode="server"
                    checkboxSelection
                    onPageChange={(newPage) => props.setPage(newPage)}
                    loading={props.loading}
                    onSelectionModelChange={(newSelectionModel) => {
                        props.setGridOption({
                            ...props.gridOption,
                            Ids: newSelectionModel
                        })
                    }}
                    onPageSizeChange={(data) => {
                        console.log(data)
                    }}
                />
            </Box>
        </>
    )
}
