import React from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import {DataGrid} from "@mui/x-data-grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

/**
 * 생기부 등록현황 UI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function UserLifeRecordUI(props) {

    return (
        <>
            <Box sx={{display: "flex", height: 40, width: '100%', justifyContent: "flex-end", marginBottom: "10px"}}>
                <Box sx={{display: "flex", flexDirection: "coulmn", alignItems: "center", minWidth: 200}}>
                    <FormControl fullWidth>
                        <InputLabel id="SearchKey-label">검색 키워드</InputLabel>
                        <Select
                            labelId="SearchKey"
                            id="SearchKey"
                            name="SearchKey"
                            label="ALL"
                            defaultValue="all"
                            onChange={props.onChangeSearchKey}
                        >
                            {props.selectList.map((item) => {
                                return (<MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{
                    justifyContent: 'flex-end',
                    flexDirection: "coulmn",
                    alignItems: "center",
                    marginRight: "10px"
                }}>
                    <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        variant="filled"
                        size="small"
                        onChange={props.onChangeSearchWord}
                        onKeyDown={(e) => props.onKeyDownEnter(e)}
                        sx={{marginLeft: "10px", marginRight: "10px"}}
                    />
                    <Button variant="contained" onClick={props.onClickSearch} endIcon={<SearchIcon/>}>
                        검색
                    </Button>
                </Box>

                <Dialog
                    // disableBackdropClick
                    open={props.openLifeRecordDialog}
                    onClose={props.handleClosed}
                    aria-labelledby="form-dialog-title"
                    maxWidth={"lg"}
                    fullWidth={true}
                >
                    <DialogTitle id="form-dialog-title">
                        [{props.lifeRecordData.name}, {props.lifeRecordData.email}] 생활기록부 정보
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {props.gridOptionDetail_2.title}
                        </DialogContentText>
                        <div>
                            <DataGrid
                                sx={{
                                    boxShadow: 2,
                                    border: 2,
                                    borderColor: 'primary.light', '& .MuiDataGrid-cell:hover': {
                                        color: 'primary.main',
                                    }
                                }}
                                autoHeight
                                disableColumnFilter
                                density={"compact"}
                                rows={props.subjectLearningRows}
                                // rowCount={props.totalRows}
                                columns={props.gridOptionDetail_2.columns}
                                // rowsPerPageOptions={props.gridOptionDetail_2.rowPerPageOptions}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.handleClosed} color="primary">
                            닫기
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            <Box sx={{height: 400, width: '100%'}}>
                <Typography>
                    {props.gridOption.title}
                </Typography>
                <DataGrid
                    sx={{
                        boxShadow: 2,
                        border: 2,
                        borderColor: 'primary.light', '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        }
                    }}
                    autoHeight
                    disableColumnFilter
                    density={"compact"}
                    rows={props.rows}
                    rowCount={props.totalRows}
                    columns={props.gridOption.columns}
                    pageSize={props.gridOption.pageSize}
                    rowsPerPageOptions={props.gridOption.rowPerPageOptions}
                    paginationMode="server"
                    onPageChange={(newPage) => props.setPage(newPage)}
                    sortingMode="server"
                    onSortModelChange={props.handleSortModelChange}
                    loading={props.loading}
                    onRowDoubleClick={(params) =>
                        props.handleClickedOpen(params.row)
                    }
                    onPageSizeChange={(data) => {
                        console.log(data)
                    }}
                />
            </Box>
        </>
    )
}
