import {API_BASE_URL} from "../../constants";
import {request, requestFile} from "../../util/APIUtils";

/**
 * [관리자] 교과 과목코드 목록조회
 *
 * @param earlydSubjectCodeRequest
 * @returns {Promise<* | void>}
 */
export function requestEarlydSubjectCodeList(earlydSubjectCodeRequest) {
    return request({
        url: API_BASE_URL + "/admin/earlyd/subject/code/list",
        method: 'POST',
        body: JSON.stringify(earlydSubjectCodeRequest)
    });
}

/**
 * [관리자] 교과 과목코드 row 삭제
 *
 * @param earlydSubjectCodeRequest
 * @returns {Promise<* | void>}
 */
export function requestRemoveEarlydSubjectCode(earlydSubjectCodeRequest) {
    return request({
        url: API_BASE_URL + "/admin/earlyd/subject/code/delete",
        method: 'POST',
        body: JSON.stringify(earlydSubjectCodeRequest)
    });
}

/**
 * [관리자] 교과 과목코드 엑셀업로드
 *
 * @param file
 * @returns {*}
 */
export function requestEarlydSubjectCodeExcelUpload(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("columnCount", 5);

    return requestFile(
        {
            url: API_BASE_URL + "/admin/earlyd/subject/code/excelupload",
            method: 'POST',
        },
        formData
    )
}