import {request} from "../../util/APIUtils";
import {API_BASE_URL} from "../../constants";

export function requestUserStatisticsubscriberList(userRequest) {
    return request({
        url: API_BASE_URL + "/admin/statistics/user/subscriber",
        method: 'GET'
    });
}

export function requestLoginStatisticsubscriberList(userRequest) {
    return request({
        url: API_BASE_URL + "/admin/statistics/user/login",
        method: 'GET'
    });
}
