import {ACCESS_TOKEN, API_BASE_URL} from "../constants";
import {request} from "../util/APIUtils";


export function logout(){
    return request({
        url: API_BASE_URL + "/auth/logout",
        method: 'POST'
    });
}

export function checkAdmin(){
    if(!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: API_BASE_URL + "/auth/admin/check",
        method: 'GET'
    });
}

export function login(loginRequest) {
    return request({
        url: API_BASE_URL + "/auth/login",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}


