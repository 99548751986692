import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {ReactComponent as HeaderLogo} from "../../asset/image/headerLogo.svg";
import {login,checkAdmin,logout} from '../../api/LoginAPI';
import {useState} from "react";
import {Alert} from "@mui/material";
import {ACCESS_TOKEN,REFRESH_TOKEN} from "../../constants";
const theme = createTheme();

export default function Login({setIsLogin}) {
    const [inputEmail, setInputEmail] = useState('')
    const [inputPw, setInputPw] = useState('')
    const handleInputEmail = (e) => {
        setInputEmail(e.target.value)
    }

    const handleInputPw = (e) => {
        setInputPw(e.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const loginRequest = Object.assign({}, {
            email:inputEmail,
            password:inputPw
        });
        console.log(loginRequest);
        login(loginRequest)
            .then(response => {
                if(response.accessToken==null){
                    alert("계정 및 비밀번호가 틀렸습니다.")
                    return;

                }
                localStorage.setItem(ACCESS_TOKEN, response.accessToken.accessToken);
                localStorage.setItem(REFRESH_TOKEN, response.accessToken.refreshToken);

                checkAdmin().then(response =>{
                    if(response.status){
                        alert('You\'re successfully logged in!');
                        setIsLogin(true);
                    }else{
                        alert(response.message);
                        logout();
                        localStorage.clear();
                        setIsLogin(false);
                    }
                })

                this.props.history.push("/");
                Alert.success("You're successfully logged in!");
            }).catch(error => {
            //alert('Oops! Something went wrong. Please try again!');
        });




    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" style={{height:"100%",display:"flex"}}>
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent:"center",
                        justifyItems:"Center"
                    }}
                >
                        <HeaderLogo/>

                    <Typography component="h1" variant="h5">
                        관리자 로그인
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleInputEmail}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleInputPw}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        {/*
                        <Grid container>
                            <Grid item xs>
                                <Link href="/forgot" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/signup" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                        */}
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}