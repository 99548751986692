import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import UserInfoUI from "./UserInfo.presenter";
import {requestUpdateUser, requestUserList, sendCustomSmsMsgToUser} from "../../../api/user/UserAPI";
import {camelCaseToSnakeCase} from "../../../util/CommonFunction";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

/**
 * 대학교 코드 관리
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function UserInfo(props) {
    const [loading, setLoading] = useState(false);

    const [searchKey, setSearchKey] = useState("all");
    const [searchWord, setSearchWord] = useState("");
    const [searchSort, setSearchSort] = useState({});

    const [userInfoGridOption, setUserInfoGridOption] = useState(
        {
            columns: [
                {field: 'id', headerName: '유저 ID', width: 70, headerAlign: "center", hide: true},
                {
                    field: "actions",
                    headerClassName: "super-app-theme--header",
                    headerName: "수정",
                    headerAlign: "center",
                    width: 80,
                    disableColumnMenu: true,
                    editable: false,
                    renderCell: (params) => (
                        <>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <IconButton color="primary" onClick={() => editUser(params.row)}>
                                    <EditIcon/>
                                </IconButton>
                            </Box>
                        </>
                    )
                },
                {field: 'email', headerName: '이메일', width: 200, headerAlign: "center"},
                {field: 'nickname', headerName: '닉네임', width: 130, headerAlign: "center", align: 'center'},
                {
                    field: 'major', headerName: '문과/이과', width: 100, headerAlign: "center", align: 'center',
                    renderCell: (params, cellValues) => {
                        if (params.row.major === 'LiberalArts') {
                            return '문과'
                        } else if (params.row.major === 'NaturalSciences') {
                            return '이과'
                        }
                    }
                },
                {field: 'graduateYear', headerName: '졸업예정년도', width: 130, headerAlign: "center", align: 'center'},
                {field: 'phone', headerName: '휴대폰번호', width: 130, headerAlign: "center", align: 'center'},
                {field: 'address', headerName: '주소', width: 130, headerAlign: "center"},
                {
                    field: 'ckSms', headerName: 'sms인증', width: 100, headerAlign: "center", align: 'center',
                    renderCell: (params) => (<>{params.row.ckSms === true ? "동의" : "미동의"}</>)
                },
                {
                    field: 'ckSmsAgree', headerName: 'sms수신동의', width: 100, headerAlign: "center", align: 'center',
                    renderCell: (params) => (<>{params.row.ckSmsAgree === true ? "동의" : "미동의"}</>)
                },
                {field: 'roleType', headerName: '권한타입', width: 130, headerAlign: "center", align: 'center'},
                {
                    field: 'isOfficer', headerName: '사정관 유무', width: 100, headerAlign: "center", align: 'center',
                    renderCell: (params) => (<>{params.row.isOfficer === true ? "사정관" : ""}</>),
                    sortable: false
                },
                {
                    field: 'accountStopYn', headerName: '계정상태', width: 100, headerAlign: "center", align: 'center',
                    renderCell: (params) => (<>{params.row.accountStopYn === 'Y' ? "정지" : ""}</>),
                    sortable: false
                }
            ],
            title: "> 유저 목록",
            rowPerPageOptions: [15],
            pageSize: 15,
            Ids: []
        }
    )
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    // select box
    const selectList = [
        {value: "all", name: "전체"},
        {value: "A.email", name: "이메일"},
        {value: "A.nickname", name: "닉네임"},
        {
            value: "CASE WHEN A.major = 'NaturalSciences' THEN '이과' WHEN A.major = 'LiberalArts' THEN '문과' END",
            name: "문과/이과"
        },
        {value: "A.graduate_year", name: "졸업예정년도"},
        {value: "A.address", name: "주소"},
        {value: "CASE WHEN A.account_stop_yn = 'Y' THEN '정지' WHEN A.account_stop_yn = 'N' THEN '' END", name: "계정상태"}
    ];

    /**
     * 초기화 데이터
     */
    let initialFormData = {
        ids: "",
        title: "",
        content: "",
        sendType: ""
    };

    /**
     * 초기화 데이터
     */
    let initialFormData2 = {
        id: null,
        accountStopYn: "N"
    };

    // 다이얼로그 데이터 초기화
    const [messageData, setMessageData] = useState(initialFormData);
    const [userData, setUserData] = useState(initialFormData2);
    // 다이얼로그 Open 여부 초기화
    const [openMessageDialog, setOpenMessageDialog] = useState(false);
    const [openUserDialog, setOpenUserDialog] = useState(false);

    /** 검색조건 변경 이벤트 **/
    const onChangeSearchKey = (e) => {
        setSearchKey(e.target.value);
    }

    /** 검색어 입력 이벤트 **/
    const onChangeSearchWord = (e) => {
        setSearchWord(e.target.value);
    }

    /** 검색 버튼 이벤트 **/
    const onClickSearch = async () => {
        const newRows = await searchList();
        setRows(newRows.list);
        setTotalRows(newRows.totalCount);
    }

    const onKeyDownEnter = e => {
        if (e.key === 'Enter') {
            onClickSearch(); // Enter 입력이 되면 클릭 이벤트 실행
        }
    };

    /** 검색목록 조회 **/
    function searchList() {
        return new Promise((resolve) => {
            setTimeout(() => {
                const body = {
                    searchKey: searchKey === 'all' ? selectList.filter(item => item.value !== 'all').map(item => {
                        return item.value
                    }).join('`') : searchKey,
                    searchWord: searchWord,
                    searchSort: searchSort.sortModel ? searchSort.sortModel[0] : null,
                    page: page * userInfoGridOption.pageSize,
                    pageSize: userInfoGridOption.pageSize
                }
                requestUserList(body).then((response) => {
                    resolve(response);
                })
            }, Math.random() * 100 + 100)
        });
    }

    /**
     * 다이얼로그 선택대상 문자전송 이벤트
     */
    const sendMessage = async (e) => {
        try {
            e.preventDefault();

            sendCustomSmsMsgToUser(messageData).then(response => {
                if (response.status < 400) {
                    alert('문자전송이 완료되었습니다.');
                } else {
                    alert('문자전송에 실패하였습니다.\n실패 사유 : ' + response.message);
                }
            }).finally(async () => {
                setOpenMessageDialog(false);
                await clearMessageData();
                const newRows = await searchList();
                setRows(newRows.list);
                setTotalRows(newRows.totalCount);
            });
        } catch (err) {
            alert(err.message);
        }
    };

    /**
     * 다이얼로그 열기 이벤트
     */
    const handleClickedOpen = (eventId) => {
        if (eventId === "target_select") {//선택대상 문자전송
            if (userInfoGridOption.Ids.length) {
                const selectedRowsData = userInfoGridOption.Ids.map(
                    (id) => rows.find((row) => row.id === id)
                );

                try {
                    selectedRowsData.forEach((item) => {
                        if (item.ckSmsAgree === false) {
                            throw new Error('[닉네임 : ' + item.nickname + ', 이메일 : ' + item.email + '] sms수신 미동의 사용자입니다.')
                        }
                    });

                    setOpenMessageDialog(true);
                    setMessageData({
                        ...messageData,
                        ids: userInfoGridOption.Ids,
                        sendType: "select"
                    })
                } catch (err) {
                    alert(err.message);
                }
            } else {
                alert('1건 이상 선택해 주세요!');
            }
        } else if (eventId === "target_all") {//전체 문자전송
            setOpenMessageDialog(true);
            setMessageData({
                ...messageData,
                Ids: "",
                sendType: "all"
            })
        }
    };

    /**
     * 다이얼로그 닫기 이벤트
     */
    const handleClosed = () => {
        setOpenMessageDialog(false);
        clearMessageData();
    };

    const handleClosed2 = () => {
        setOpenUserDialog(false);
        clearUserData();
    };

    /**
     * 다이얼로그 값 초기화
     */
    const clearMessageData = async () => {
        setMessageData(initialFormData);
    };
    const clearUserData = async () => {
        setUserData(initialFormData2);
    };

    /**
     * 다이얼로그 값 변경 이벤트
     */
    const handleChanged = (event) => {
        setMessageData({
            ...messageData,
            [event.target.name]: event.target.value
        });
    };

    const handleChanged2 = (event) => {
        setUserData({
            ...userData,
            [event.target.name]: event.target.value
        });
    };

    /**
     * 그리드 헤더 정렬 이벤트
     * @type {(function(*): void)|*}
     */
    const handleSortModelChange = async (sortModel) => {
        // Here you save the data you need from the sort model
        setSearchSort({
            sortModel: [
                {
                    field: camelCaseToSnakeCase(sortModel[0].field),
                    sort: sortModel[0].sort
                }
            ]
        })
    };

    /**
     * 다이얼로그 수정 이벤트
     */
    const saveUser = async (e) => {
        try {
            e.preventDefault();

            if (userData.id) {
                requestUpdateUser(userData).then(response => {
                    if (response.status < 400) {
                        alert('수정이 완료되었습니다.');
                    } else {
                        alert('수정에 실패하였습니다.\n실패 사유 : ' + response.message);
                    }
                }).finally(async () => {
                    setOpenUserDialog(false);
                    await clearUserData();
                    const newRows = await searchList();
                    setRows(newRows.list);
                    setTotalRows(newRows.totalCount);
                });
            }
        } catch (err) {
            alert(err.message);
        }
    };

    /**
     * 다이얼로그 수정창 띄우기 이벤트
     */
    const editUser = async (userData) => {
        setOpenUserDialog(true);

        setUserData(userData);
    };

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            const newRows = await searchList();

            if (!active) {
                return;
            }

            setRows(newRows.list);
            setTotalRows(newRows.totalCount);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchSort]);

    return (
        <Grid container spacing={1}>
            <UserInfoUI
                gridOption={userInfoGridOption}
                rows={rows}
                totalRows={totalRows}
                setPage={setPage}
                handleSortModelChange={handleSortModelChange}
                loading={loading}
                setGridOption={setUserInfoGridOption}
                onChangeSearchKey={onChangeSearchKey}
                onChangeSearchWord={onChangeSearchWord}
                onKeyDownEnter={onKeyDownEnter}
                onClickSearch={onClickSearch}
                messageData={messageData}
                userData={userData}
                handleClickedOpen={handleClickedOpen}
                handleClosed={handleClosed}
                handleClosed2={handleClosed2}
                handleChanged={handleChanged}
                handleChanged2={handleChanged2}
                openMessageDialog={openMessageDialog}
                sendMessage={sendMessage}
                openUserDialog={openUserDialog}
                saveUser={saveUser}
                selectList={selectList}
            />
        </Grid>
    );
}