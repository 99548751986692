/**
 * 공통함수 정의
 */

/**
 * 123456789 => 123,456,789(###,###,###) 형식으로 변경
 *
 * @param inputNumber
 * @returns {string}
 */
export function addComma(inputNumber) {
    let returnString = inputNumber?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return returnString;
}

/**
 * file -> Base64 변환
 * @param file
 * @returns {Promise<unknown>}
 */
export function convertBase64(file) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

/**
 * 카멜케이스 스네이크케이스로 변환
 *
 * @param inputString
 * @returns {*}
 */
export function camelCaseToSnakeCase(inputString) {
    return inputString.replace(/[A-Z]/g, (c) => {return '_' + c.toLowerCase()});
}