import React from 'react';
import Box from '@mui/material/Box';
import BasicSearchListComponentV2 from '../../list/BasicSearchListComponentV2';
import {
  requestDeleteService,
  requestSaveService,
  requestServiceInfo,
  requestServiceList,
  requestProductTypeList,
  requestUpdateService,
} from '../../../api/pay/PayAPI';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

const ServiceColumns = [
  {
    field: 'id',
    headerName: '서비스ID',
    headerAlign: 'center',
    width: 70,
    align: 'center',
  },
  {
    field: 'deleteFlag',
    headerName: '삭제여부',
    headerAlign: 'center',
    width: 80,
    align: 'center',
    renderCell: (params) => (
      <>{params.row.deleteFlag === false ? '서비스중' : '중지'}</>
    ),
  },
  {
    field: 'productNm',
    headerName: '서비스이름',
    headerAlign: 'center',
    width: 180,
  },
  {
    field: 'productPrice',
    headerName: '상품가격',
    headerAlign: 'center',
    width: 100,
    align: 'center',
  },
  {
    field: 'term',
    headerName: '계약기간',
    headerAlign: 'center',
    width: 180,
    align: 'center',
  },
  {
    field: 'productTypeNm',
    headerName: '상품타입',
    headerAlign: 'center',
    width: 80,
    align: 'center',
  },
  {
    field: 'productCateNm',
    headerName: '상품 분류',
    headerAlign: 'center',
    width: 80,
    align: 'center',
  },
  {
    field: 'serviceRangeNm',
    headerName: '서비스범위',
    headerAlign: 'center',
    width: 120,
    align: 'center',
  },
];
export default function Production(props) {
  const [open, setOpen] = React.useState(false);
  const [productTypeList, setProductTypeList] = React.useState([]);
  const [productType, setProductType] = React.useState(null);

  const [productCateList, setProductCateList] = React.useState([]);
  const [productCate, setProductCate] = React.useState(null);

  const [serviceRangeList, setServiceRangeList] = React.useState([]);
  const [serviceRange, setServiceRange] = React.useState(null);

  const [selectedProduct, setSelectedProduct] = React.useState(null);

  const [productNm, setProductNm] = React.useState('');
  const [productPrice, setProductPrice] = React.useState(0);
  const [promotionDiscount, setPromotionDiscount] = React.useState(0);
  const [explainComment, setExplainComment] = React.useState('');
  const [refundPolicy, setRefundPolicy] = React.useState('');
  const [term, setTerm] = React.useState(dayjs(Date.now()));
  const [deleteFlag, setDeleteFlag] = React.useState(0);
  const [availableCount, setavailableCount] = React.useState(0);
  const [availableTerm, setAvailableTerm] = React.useState(null); // 한정 기가권 라디오 밸류

  const [serviceId, setServiceId] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
    getProducTypeList();
  };
  const handleClose = () => {
    setOpen(false);
    setProductNm('');
    setProductPrice(0);
    setPromotionDiscount(0);
    setExplainComment(0);
    setRefundPolicy(0);
    setSelectedProduct(null);
    setProductType(null);
    setProductCate(null);
    setServiceRange(null);
    setTerm(dayjs(Date.now()));
    setDeleteFlag(0);
  };

  const getProducTypeList = () => {
    requestProductTypeList().then((response) => {
      setProductTypeList(response.data[0]);
      setProductCateList(response.data[1]);
      setServiceRangeList(response.data[2]);
    });
  };

  /**
   * 상품 서비스 저장
   */
  const onClickAddService = () => {
    let params = {};
    params.productNm = productNm;
    params.productPrice = productPrice;
    params.productTypeCode = productType;
    params.productCateCode = productCate;
    params.serviceRangeCode = serviceRange;
    params.promotionDiscount = promotionDiscount;
    params.explainComment = explainComment;
    params.refundPolicy = refundPolicy;
    params.deleteFlag = 1;
    params.availableCount = availableCount;
    if (productType === 'TICKET') {
      params.availableTerm = '6M';
    } else {
      params.availableTerm = availableTerm;
    }
    params.term = dayjs(term).format('YYYY-MM-DD HH:mm:ss');

    requestSaveService(params).then((response) => {
      if (response.resultCode === 100) {
        alert('저장완료');
        handleClose();
        window.location.reload();
      }
    });
  };

  /**
   * 상품 서비스 수정
   */
  const onClickUpdateService = () => {
    let params = {};
    params.id = serviceId;
    params.productNm = productNm;
    params.productPrice = productPrice;
    params.term = dayjs(term).format('YYYY-MM-DD HH:mm:ss');
    params.productTypeCode = productType;
    params.productCateCode = productCate;
    params.serviceRangeCode = serviceRange;
    params.promotionDiscount = promotionDiscount;
    params.explainComment = explainComment;
    params.refundPolicy = refundPolicy;
    params.deleteFlag = deleteFlag;
    params.availableCount = availableCount;
    params.availableTerm = availableTerm;

    requestUpdateService(params).then((response) => {
      if (response.resultCode === 100) {
        alert('수정완료');
        handleClose();
        window.location.reload();
      }
    });
  };
  /**
   * 상품 선택
   */
  const onRowClickEvent = (id) => {
    let params = {};
    params.id = id;
    requestServiceInfo(params).then((response) => {
      console.log(response.data);
      if (response.resultCode === 100) {
        setSelectedProduct(response.data);
        setServiceId(response.data.id);
        setProductNm(response.data.productNm);
        setProductPrice(response.data.productPrice);
        setTerm(dayjs(response.data.term));
        setPromotionDiscount(response.data.promotionDiscount);
        setExplainComment(response.data.explainComment);
        setRefundPolicy(response.data.refundPolicy);
        setProductType(response.data.productTypeCode);
        setProductCate(response.data.productCateCode);
        setServiceRange(response.data.serviceRangeCode);
        setDeleteFlag(response.data.deleteFlag);
        setavailableCount(response.data.availableCount);
        setAvailableTerm(response.data.availableTerm);
      }
      handleClickOpen();
    });
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
      <Box
        sx={{
          width: '100%',
          marginBottom: '10px',
          overflowY: 'scroll',
          height: 'calc(100vh - 180px)',
        }}
      >
        <BasicSearchListComponentV2
          init={requestServiceList}
          columns={ServiceColumns}
          onRowClickEvent={onRowClickEvent}
          removeEvent={requestDeleteService}
          addClickEvent={handleClickOpen}
          title={'> 서비스 상품내역'}
          deleteYn={false}
        />
      </Box>

      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">서비스 등록</DialogTitle>
          <DialogContent>
            <Box>
              <Box></Box>
              <Box></Box>
            </Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', width: '35rem' }}
            >
              <TextField
                id="outlined-basic"
                label="서비스 이름"
                variant="outlined"
                sx={{ marginTop: '10px' }}
                value={productNm}
                onChange={(event) => setProductNm(event.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="상품가격"
                type="number"
                variant="outlined"
                sx={{ marginTop: '10px' }}
                value={productPrice}
                onChange={(event) => setProductPrice(event.target.value)}
              />

              <FormControl style={{ marginTop: '10px' }}>
                <InputLabel>상품 타입</InputLabel>
                <Select
                  value={productType}
                  label="상품 타입"
                  onChange={(event) => setProductType(event.target.value)}
                >
                  {productTypeList.map((item) => {
                    return (
                      <MenuItem value={item.productType}>
                        {item.productTypeNm}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {productType === 'FIXEDTERM' ? (
                <Box sx={{ marginTop: '5px' }}>
                  <InputLabel id="demo-simple-select-label">
                    계약 만료일
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={term}
                      onChange={(newValue) => setTerm(newValue)}
                    />
                  </LocalizationProvider>
                </Box>
              ) : productType === 'TICKET' ? (
                <TextField
                  id="outlined-basic"
                  label="사용횟수"
                  type="number"
                  variant="outlined"
                  sx={{ marginTop: '10px' }}
                  value={availableCount}
                  onChange={(event) => setavailableCount(event.target.value)}
                />
              ) : productType === 'TREM' ? (
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    기간 선택
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={availableTerm}
                    onChange={(e) => setAvailableTerm(e.target.value)}
                  >
                    <FormControlLabel
                      value="1M"
                      control={<Radio checked={availableTerm === '1M'} />}
                      label="1개월"
                    />
                    <FormControlLabel
                      value="6M"
                      control={<Radio checked={availableTerm === '6M'} />}
                      label="6개월"
                    />
                    <FormControlLabel
                      value="12M"
                      control={<Radio checked={availableTerm === '12M'} />}
                      label="12개월"
                    />
                  </RadioGroup>
                </FormControl>
              ) : productType === 'PACKAGE' ? (
                <Box>
                  <Box sx={{ marginTop: '5px' }}>
                    <InputLabel id="demo-simple-select-label">
                      계약 만료일
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={term}
                        onChange={(newValue) => setTerm(newValue)}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <TextField
                      id="outlined-basic"
                      label="사용횟수"
                      type="number"
                      variant="outlined"
                      sx={{ marginTop: '10px' }}
                      value={availableCount}
                      onChange={(event) =>
                        setavailableCount(event.target.value)
                      }
                    />
                  </Box>
                </Box>
              ) : null}

              <FormControl style={{ marginTop: '10px' }}>
                <InputLabel>상품 분류</InputLabel>
                <Select
                  value={productCate}
                  label="상품 분류"
                  onChange={(event) => setProductCate(event.target.value)}
                >
                  {productCateList.map((item) => {
                    return (
                      <MenuItem value={item.productCateCode}>
                        {item.productCateNm}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl style={{ marginTop: '10px' }}>
                <InputLabel>서비스 범위</InputLabel>
                <Select
                  value={serviceRange}
                  label="서비스 범위"
                  onChange={(event) => setServiceRange(event.target.value)}
                >
                  {serviceRangeList.map((item) => {
                    return (
                      <MenuItem value={item.serviceRangeCode}>
                        {item.serviceRangeNm}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl style={{ marginTop: '10px' }}>
                <InputLabel>사용여부</InputLabel>
                <Select
                  value={deleteFlag}
                  label="사용여부"
                  onChange={(event) => setDeleteFlag(event.target.value)}
                >
                  <MenuItem value={0}>사용</MenuItem>
                  <MenuItem value={1}>미사용</MenuItem>
                </Select>
              </FormControl>

              <TextField
                id="outlined-basic"
                label="프로모션할인율"
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                variant="outlined"
                value={promotionDiscount}
                onChange={(event) => setPromotionDiscount(event.target.value)}
                sx={{ marginTop: '10px' }}
              />

              <TextField
                id="standard-multiline-flexible"
                label="상품 설명"
                multiline
                maxRows={4}
                variant="filled"
                sx={{ marginTop: '10px' }}
                value={explainComment}
                onChange={(event) => setExplainComment(event.target.value)}
              />
              <TextField
                id="standard-multiline-flexible"
                label="환불 정책"
                multiline
                maxRows={4}
                variant="filled"
                sx={{ marginTop: '10px' }}
                value={refundPolicy}
                onChange={(event) => setRefundPolicy(event.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            {selectedProduct == null ? (
              <Button onClick={onClickAddService}>추가</Button>
            ) : (
              <Button onClick={onClickUpdateService}>수정</Button>
            )}

            <Button onClick={handleClose}>취소</Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
}
