import React, {useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {ButtonGroup} from "@mui/material";
import Button from "@mui/material/Button";
import {requestUserStatisticsubscriberList} from "../../../api/user/MemberStatisticsAPI";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);




export default function MemberIncreaseChart({getMemberIncrease}) {
    const [labels,setLabels] = useState([]);
    const [dataset,setDataSet] = useState([]);
    useEffect(()=>{
        requestUserStatisticsubscriberList().then((res)=>{
            const l = res.map((data)=>{
                return data.convert_date;
            })
            const g = res.map((data)=>{
                return data.cnt;
            })
            setLabels(l);
            setDataSet(g)

        })
    },[])

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: '회원가입 증가추이',
            },
        },
    };
    const data = {
        labels,
        datasets: [
            {
                label: '회원가입수',
                data: dataset,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },

        ],
    };

    return (
        <div style={{width:"100%",height:"100%"}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button onClick={getMemberIncrease}>일</Button>
                <Button onClick={getMemberIncrease}>월</Button>
                <Button onClick={getMemberIncrease}>년</Button>
            </ButtonGroup>
            </div>
            <div style={{width:"100%",height:"100%"}}>
                <Line options={options} data={data} />;
            </div>
        </div>
    )
}
