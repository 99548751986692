import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {requestPaymentsCancel, requestPaymentsCancelRequestList} from "../../../api/pay/PayAPI";
import PaymentCancelUI from "./PaymentCancel.presenter";
import Button from "@mui/material/Button";
import {addComma, camelCaseToSnakeCase} from "../../../util/CommonFunction";

export default function PaymentCancel(props) {
    const [loading, setLoading] = useState(false);

    const [searchKey, setSearchKey] = useState("all");
    const [searchWord, setSearchWord] = useState("");
    const [searchSort, setSearchSort] = useState({
        sortModel: [
            {
                field: camelCaseToSnakeCase('cancelRequestDt'),
                sort: 'desc'
            }
        ]
    });

    /** DataGrid 옵션 **/
    const [paymentCancelGridOption, setPaymentCancelGridOption] = useState(
        {
            columns: [
                {field: 'id', headerName: 'ID', width: 70, hide: true},
                {field: 'payOrderId', headerName: '결제 주문 id', width: 130, hide: true},
                {
                    field: "결제취소 처리",
                    renderCell: (cellValues) => {
                        if (cellValues.row.orderState === 'CANCELREQUEST') {
                            return (
                                <Button
                                    variant="contained"//"contained","outlined"
                                    color="primary"
                                    onClick={() => {
                                        payOrderCancelDialog(cellValues.row);
                                    }}
                                >
                                    주문 취소
                                </Button>
                            )
                        }

                        return ''
                    }
                },
                {field: 'nickname', headerName: '유저 닉네임', headerAlign: "center", width: 130, align: 'center'},
                {field: 'email', headerName: '유저 이메일', headerAlign: "center", width: 200},
                {field: 'phone', headerName: '휴대폰번호', width: 130, headerAlign: "center", align: 'center'},
                {
                    field: 'isThreeGrade', headerName: '생활기록부 등록여부', width: 130, headerAlign: "center", align: 'center',
                    renderCell: (params) => (<>{params.row.isThreeGrade === false ? "미등록" : "등록"}</>)
                },
                {field: 'merchantUid', headerName: '주문번호', headerAlign: "center", width: 200},
                {field: 'orderState', headerName: '주문상태', headerAlign: "center", width: 150},
                {field: 'cancelReason', headerName: '취소사유', headerAlign: "center", width: 200},
                {field: 'answer', headerName: '답변', headerAlign: "center", width: 200},
                {
                    field: 'cancelRequestDt',
                    headerName: '취소 요청일자',
                    headerAlign: "center",
                    type: 'dateTime',
                    width: 150,
                    align: "center"
                },
                {
                    field: 'cancelReceiveDt',
                    headerName: '취소 접수일자',
                    headerAlign: "center",
                    type: 'dateTime',
                    width: 150,
                    align: "center"
                },

                {field: 'payServiceId', headerName: '주문상품 타입 아이디', headerAlign: "center", width: 150},
                {
                    field: 'paidAmount', headerName: '결제 금액', headerAlign: "center", width: 130, align: 'right',
                    renderCell: (params, cellValues) => {
                        return addComma(params.value);
                    }
                },
                {field: 'productNm', headerName: '결제 상품이름', headerAlign: "center", width: 200},
                {
                    field: 'term',
                    headerName: '계약기간',
                    type: 'dateTime',
                    headerAlign: "center",
                    width: 150,
                    align: "center"
                },
                {field: 'productPaymentType', headerName: '상품 결제 방식 타입', headerAlign: "center", width: 130},
                {field: 'promotionDiscount', headerName: '프로모션 할인율', headerAlign: "center", width: 150, align: 'right'},
                {field: 'productImage', headerName: '상품 이미지', headerAlign: "center", width: 130}
            ],
            rows: [],
            title: "> 결제 취소내역",
            rowPerPageOptions: [15],
            pageSize: 15
        }
    )
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    // select box
    const selectList = [
        {value: "all", name: "전체"},
        {value: "D.nickname", name: "닉네임"},
        {value: "D.email", name: "이메일"},
        {value: "D.phone", name: "휴대폰번호"},
        {value: "B.merchant_uid", name: "주문번호"},
        {value: "B.order_state", name: "주문상태"},
        {value: "C.product_nm", name: "상품이름"},
        {value: "C.product_payment_type", name: "상품 결제 방식 타입"}
    ];

    /**
     * 초기화 데이터
     */
    let initialFormData = {
        id: null,
        payOrderId: '',     //주문ID
        merchantUid: '',    //주문번호
        cancelReason: '',   //취소사유
        cancelRequestDt: '',//취소 요청일자
        paidAmount: '',     //결제 금액
        productNm: ''       //결제 상품이름
    };

    // 다이얼로그 데이터 초기화
    const [payOrderData, setPayOrderData] = useState(initialFormData);
    // 다이얼로그 Open 여부 초기화
    const [openPayOrderDialog, setOpenPayOrderDialog] = useState(false);

    /** 검색조건 변경 이벤트 **/
    const onChangeSearchKey = (e) => {
        setSearchKey(e.target.value);
    }

    /**
     * 검색어 입력 변경 이벤트
     */
    const onChangeSearchWord = (e) => {
        setSearchWord(e.target.value);
    }

    /**
     * 검색 버튼 클릭 이벤트
     */
    const onClickSearch = async () => {
        const newRows = await searchList();
        setRows(newRows.data);
        setTotalRows(newRows.totalCount);
    }

    const onKeyDownEnter = e => {
        if (e.key === 'Enter') {
            onClickSearch(); // Enter 입력이 되면 클릭 이벤트 실행
        }
    };

    /**
     * 검색목록 조회
     */
    function searchList() {
        return new Promise((resolve) => {
            setTimeout(() => {
                const body = {
                    searchKey: searchKey === 'all' ? selectList.filter(item => item.value !== 'all').map(item => {
                        return item.value
                    }).join('`') : searchKey,
                    searchWord: searchWord,
                    searchSort: searchSort.sortModel ? searchSort.sortModel[0] : null,
                    page: page * paymentCancelGridOption.pageSize,
                    pageSize: paymentCancelGridOption.pageSize
                }
                requestPaymentsCancelRequestList(body).then((response) => {
                    resolve(response);
                })
            }, Math.random() * 100 + 100)
        });
    }

    const payOrderCancelDialogClick = async (e) => {
        try {
            e.preventDefault();

            if (payOrderData.payOrderId) {
                requestPaymentsCancel({orderId: payOrderData.payOrderId}).then(response => {
                    if (response.status < 400) {
                        alert('주문취소가 완료되었습니다.')
                    } else {
                        alert('주문취소가 실패하였습니다.\n실패 사유 : ' + response.message);
                    }
                }).finally(async () => {
                    setOpenPayOrderDialog(false);
                    const newRows = await searchList();
                    setRows(newRows.data);
                    setTotalRows(newRows.totalCount);
                })
            } else {
                setOpenPayOrderDialog(false);
                alert('주문내역을 다시 확인해주세요!')
            }
        } catch (err) {
            alert(err.message);
        }
    }

    /**
     * 다이얼로그 띄우기 이벤트
     */
    const payOrderCancelDialog = async (row) => {
        setOpenPayOrderDialog(true);

        setPayOrderData({
            ...payOrderData,
            payOrderId: row.payOrderId,             //주문ID
            merchantUid: row.merchantUid,           //주문번호
            cancelReason: row.cancelReason,         //취소사유
            cancelRequestDt: row.cancelRequestDt,   //취소 요청일자
            paidAmount: addComma(row.paidAmount),   //결제 금액
            productNm: row.productNm                //결제 상품이름
        });
    };

    /**
     * 다이얼로그 값 초기화
     */
    const clearPayOrderData = async () => {
        setPayOrderData(initialFormData);
    };

    /**
     * 다이얼로그 열기 이벤트
     */
    const handleClickedOpen = () => {
        setOpenPayOrderDialog(true);
    };

    /**
     * 다이얼로그 닫기 이벤트
     */
    const handleClosed = () => {
        setOpenPayOrderDialog(false);
        clearPayOrderData();
    };

    /**
     * 그리드 헤더 정렬 이벤트
     * @type {(function(*): void)|*}
     */
    const handleSortModelChange = async (sortModel) => {
        // Here you save the data you need from the sort model
        setSearchSort({
            sortModel: [
                {
                    field: camelCaseToSnakeCase(sortModel[0].field),
                    sort: sortModel[0].sort
                }
            ]
        })
    };

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            const newRows = await searchList();

            if (!active) {
                return;
            }

            setRows(newRows.data);
            setTotalRows(newRows.totalCount);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchSort]);

    return (
        <Grid container spacing={1}>
            <PaymentCancelUI
                gridOption={paymentCancelGridOption}
                rows={rows}
                totalRows={totalRows}
                setPage={setPage}
                handleSortModelChange={handleSortModelChange}
                loading={loading}
                setGridOption={setPaymentCancelGridOption}
                onChangeSearchKey={onChangeSearchKey}
                onChangeSearchWord={onChangeSearchWord}
                onKeyDownEnter={onKeyDownEnter}
                onClickSearch={onClickSearch}
                payOrderData={payOrderData}
                handleClickedOpen={handleClickedOpen}
                handleClosed={handleClosed}
                openPayOrderDialog={openPayOrderDialog}
                payOrderCancelDialogClick={payOrderCancelDialogClick}
                selectList={selectList}
            />
        </Grid>
    );
}