import React, {useEffect, useRef, useState} from 'react';
import Grid from "@mui/material/Grid";
import {
    requestAddNoticeBoard,
    requestNoticeBoardList,
    requestRemoveNoticeBoard,
    requestUpdateNoticeBoard
} from "../../../api/board/NoticeBoardAPI";
import NoticeBoardUI from "./NoticeBoard.presenter";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import {convertBase64} from "../../../util/CommonFunction";

/**
 * 모의고사 스케쥴 관리
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function NoticeBoard(props) {
    //변수 설정
    const imageInput = useRef();
    const [image, setImage] = useState("https://via.placeholder.com/400");
    const [imgFile, setImgFile] = useState();
    //그리드 설정
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchWord, setSearchWord] = useState("");//검색어 입력
    //그리드 옵션
    const [noticeBoardGridOption, setNoticeBoardGridOption] = useState(
        {
            columns: [
                {field: 'id', headerName: 'ID', width: 70, hide: true},
                {
                    field: "actions",
                    headerClassName: "super-app-theme--header",
                    headerName: "Actions",
                    headerAlign: "center",
                    width: 80,
                    disableColumnMenu: true,
                    editable: false,
                    renderCell: (params) => (
                        <>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <IconButton color="primary" onClick={() => editNoticeBoard(params.row)}>
                                    <EditIcon/>
                                </IconButton>
                            </Box>
                        </>
                    )
                },
                {field: 'nickname', headerName: '등록자 닉네임', headerAlign: "center", width: 130, align: 'center'},
                {field: 'title', headerName: '게시판 제목', headerAlign: "center", width: 200},
                {field: 'content', headerName: '게시판 내용', headerAlign: "center", width: 150},
                {field: 'type', headerName: '공지유형', headerAlign: "center", width: 100, align: 'center'},
                {field: 'visibleWidthSize', headerName: '이미지 너비', headerAlign: "center", width: 150, align: 'center'},
                {field: 'visibleHeightSize', headerName: '이미지 높이', headerAlign: "center", width: 150, align: 'center'},
                {field: 'startDt', headerName: '게시 시작시간', headerAlign: "center", width: 150, align: 'center'},
                {field: 'endDt', headerName: '게시 종료시간', headerAlign: "center", width: 150, align: 'center'},
                {
                    field: "status",
                    headerClassName: "super-app-theme--header",
                    headerName: "게시판 상태",
                    headerAlign: "center",
                    width: 180,
                    disableColumnMenu: true,
                    editable: false,
                    renderCell: (params) => (<>{params.row.status === 0 ? "비활성화" : "활성화"}</>)
                },
                {field: 'hit', headerName: '조회수', headerAlign: "center", width: 130, align: 'center'},
                {field: 'createDt', headerName: '생성날짜', headerAlign: "center", width: 150, align: 'center'},
                {field: 'updateDt', headerName: '수정날짜', headerAlign: "center", width: 150, align: 'center'},
                {field: 'fileKey', headerName: '파일키', headerAlign: "center", width: 150},
                {field: 'filePath', headerName: '파일경로', headerAlign: "center", width: 200},
                {field: 'fileName', headerName: '파일이름', headerAlign: "center", width: 200},
                {field: 'fileType', headerName: '파일타입', headerAlign: "center", width: 130},
                {field: 'fileSize', headerName: '파일크기', headerAlign: "center", width: 130},
                {field: 'connectionUrl', headerName: '연결URL(이미지 클릭 시 URL경로)', headerAlign: "center", width: 130}
            ],
            rows: [],
            title: "> 공지사항 목록 (조회조건 : 게시판 제목)",
            rowPerPageOptions: [15],
            pageSize: 15,
            Ids: []
        }
    )

    /**
     * 초기화 데이터
     */
    let initialFormData = {
        id: null,
        title: "",
        content: "",
        type: "popup",
        visibleWidthSize: 400,
        visibleHeightSize: 400,
        status: 1,
        connectionUrl: "",
        startDt: dayjs(Date.now()),
        endDt: dayjs(Date.now())
    };
    // 다이얼로그 데이터 초기화
    const [noticeBoardData, setNoticeBoardData] = useState(initialFormData);
    // 다이얼로그 Open 여부 초기화
    const [openNoticeBoardDialog, setOpenNoticeBoardDialog] = useState(false);

    /**
     * 검색어 입력 변경 이벤트
     */
    const onChangeSearchWord = (e) => {
        setSearchWord(e.target.value);
    }

    /**
     * 검색 버튼 클릭 이벤트
     */
    const onClickSearch = async () => {
        const newRows = await searchList();
        setRows(newRows.list);
        setTotalRows(newRows.totalCount);
    }

    /**
     * 검색목록 조회
     */
    function searchList() {
        return new Promise((resolve) => {
            setTimeout(() => {
                const body = {
                    searchWord: searchWord,
                    page: page * noticeBoardGridOption.pageSize,
                    pageSize: noticeBoardGridOption.pageSize
                }
                requestNoticeBoardList(body).then((response) => {
                    resolve(response);
                })
            }, Math.random() * 100 + 100)
        });
    }

    /**
     * 다이얼로그 추가/수정 이벤트
     */
    const saveNoticeBoard = async (e) => {
        try {
            e.preventDefault();

            let file = (imgFile?.files);

            if (noticeBoardData.id) {
                requestUpdateNoticeBoard(noticeBoardData, file !== undefined ? file[0] : null).then(response => {
                    if (response.status < 400) {
                        alert('수정이 완료되었습니다.');
                    } else {
                        alert('수정에 실패하였습니다.\n실패 사유 : ' + response.message);
                    }
                }).finally(async () => {
                    setOpenNoticeBoardDialog(false);
                    await clearNoticeBoardData();
                    const newRows = await searchList();
                    setRows(newRows.list);
                    setTotalRows(newRows.totalCount);
                });
            } else {
                requestAddNoticeBoard(noticeBoardData, file !== undefined ? file[0] : null).then(response => {
                    if (response.status < 400) {
                        alert('등록이 완료되었습니다.');
                    } else {
                        alert('등록에 실패하였습니다.\n실패 사유 : ' + response.message);
                    }
                }).finally(async () => {
                    setOpenNoticeBoardDialog(false);
                    await clearNoticeBoardData();
                    const newRows = await searchList();
                    setRows(newRows.list);
                    setTotalRows(newRows.totalCount);
                });
            }
        } catch (err) {
            alert(err.message);
        }
    };

    /**
     * 다이얼로그 수정창 띄우기 이벤트
     */
    const editNoticeBoard = async (noticeBoardData) => {
        setOpenNoticeBoardDialog(true);

        // const base64 = await convertBase64();
        setNoticeBoardData({
            ...noticeBoardData,
            type: noticeBoardData.type.toLowerCase(),
            startDt: dayjs(noticeBoardData.startDt),
            endDt: dayjs(noticeBoardData.endDt)
        });
        setImage(noticeBoardData.filePath + "");
    };

    /**
     * 삭제 버튼 클릭 이벤트
     */
    const onClickDeleteButton = e => {
        if (noticeBoardGridOption.Ids.length) {
            requestRemoveNoticeBoard({ids: noticeBoardGridOption.Ids}).then(response => {
                if (response.status < 400) {
                    alert('삭제가 완료되었습니다.');
                } else {
                    alert('삭제가 실패하였습니다.\n실패 사유 : ' + response.message);
                }
            }).finally(async () => {
                const newRows = await searchList();
                setRows(newRows.list);
                setTotalRows(newRows.totalCount);
            });
        } else {
            alert('1건 이상 선택해 주세요!');
        }
    }

    /**
     * 다이얼로그 값 초기화
     */
    const clearNoticeBoardData = async () => {
        setNoticeBoardData(initialFormData);
        setImage("https://via.placeholder.com/400");
        setImgFile("");
    };

    /**
     * 다이얼로그 값 변경 이벤트
     */
    const handleChanged = (event, value) => {
        setNoticeBoardData({
            ...noticeBoardData,
            [event.target.name]: event.target.value
        });
    };

    const handleStartDtChanged = (newValue) => {
        setNoticeBoardData({
            ...noticeBoardData,
            startDt: newValue
        });
    };

    const handleEndDtChanged = (newValue) => {
        // const dateFormat = dayjs(newValue).format("YYYY-MM-DD hh:mm:00")
        setNoticeBoardData({
            ...noticeBoardData,
            endDt: newValue
        });
    };

    const handleImage = () => {
        imageInput.current.click();
    }

    const handleImageData = (event) => {
        if (event.target.files !== null) {
            let file = event.target?.files[0];
            setImgFile(event.target);
            uploadImage(file)
        }
    }

    const uploadImage = async (file) => {
        const base64 = await convertBase64(file);
        setImage(base64);
    };

    /**
     * 다이얼로그 열기 이벤트
     */
    const handleClickedOpen = () => {
        setOpenNoticeBoardDialog(true);
    };

    /**
     * 다이얼로그 닫기 이벤트
     */
    const handleClosed = () => {
        setOpenNoticeBoardDialog(false);
        clearNoticeBoardData();
    };

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            const newRows = await searchList();

            if (!active) {
                return;
            }

            setRows(newRows.list);
            setTotalRows(newRows.totalCount);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <Grid container spacing={1}>
            <NoticeBoardUI
                gridOption={noticeBoardGridOption}
                rows={rows}
                totalRows={totalRows}
                setPage={setPage}
                loading={loading}
                setGridOption={setNoticeBoardGridOption}
                onChangeSearchWord={onChangeSearchWord}
                onClickSearch={onClickSearch}
                noticeBoardData={noticeBoardData}
                handleClickedOpen={handleClickedOpen}
                handleClosed={handleClosed}
                handleChanged={handleChanged}
                handleStartDtChanged={handleStartDtChanged}
                handleEndDtChanged={handleEndDtChanged}
                imageInput={imageInput}
                image={image}
                handleImage={handleImage}
                handleImageData={handleImageData}
                openNoticeBoardDialog={openNoticeBoardDialog}
                saveNoticeBoard={saveNoticeBoard}
                onClickDeleteButton={onClickDeleteButton}
            />
        </Grid>
    );
}