import React from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import {ButtonGroup} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DataGrid} from "@mui/x-data-grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

/**
 * 사용자 정보 관리 UI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function UserInfoUI(props) {

    return (
        <>
            <Box sx={{display: "flex", height: 40, width: '100%', justifyContent: "flex-end", marginBottom: "10px"}}>
                <Box sx={{display: "flex", flexDirection: "coulmn", alignItems: "center", minWidth: 200}}>
                    <FormControl fullWidth>
                        <InputLabel id="SearchKey-label">검색 키워드</InputLabel>
                        <Select
                            labelId="SearchKey"
                            id="SearchKey"
                            name="SearchKey"
                            label="ALL"
                            defaultValue="all"
                            onChange={props.onChangeSearchKey}
                        >
                            {props.selectList.map((item) => {
                                return (<MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{
                    justifyContent: 'flex-end',
                    flexDirection: "coulmn",
                    alignItems: "center",
                    marginRight: "10px"
                }}>
                    <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        variant="filled"
                        size="small"
                        onChange={props.onChangeSearchWord}
                        onKeyDown={(e) => props.onKeyDownEnter(e)}
                        sx={{marginLeft: "10px", marginRight: "10px"}}
                    />
                    <Button variant="contained" onClick={props.onClickSearch} endIcon={<SearchIcon/>}>
                        검색
                    </Button>
                </Box>

                <ButtonGroup variant="outlined" aria-label="outlined button group">
                    <Button id="target_all" onClick={() => props.handleClickedOpen("target_all")} type="submit">
                        전체 문자전송
                    </Button>
                    <Button id="target_select" onClick={() => props.handleClickedOpen("target_select")} type="submit">
                        선택대상 문자전송
                    </Button>
                    <>
                        <Dialog
                            // disableBackdropClick
                            open={props.openMessageDialog}
                            onClose={props.handleClosed}
                            aria-labelledby="form-dialog-title"
                            fullWidth={true}
                        >
                            <DialogTitle id="form-dialog-title">
                                {props.messageData.sendType === 'select' ? '선택대상 문자전송(sms수신동의 대상)' : '전체대상 문자전송(sms수신동의 대상)'}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {props.messageData.sendType === 'select' ? '선택대상 문자전송' : '전체대상 문자전송'}
                                </DialogContentText>
                                <div>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="title"
                                        name="title"
                                        label="메시지 제목"
                                        type="text"
                                        defaultValue={props.messageData.title}
                                        value={props.messageData.title}
                                        onChange={props.handleChanged}
                                        fullWidth
                                    />
                                </div>
                                <div>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="content"
                                        name="content"
                                        label="게시판 내용"
                                        type="text"
                                        variant="outlined"
                                        defaultValue={props.messageData.content}
                                        value={props.messageData.content}
                                        onChange={props.handleChanged}
                                        rows={2}
                                        multiline
                                        maxRows={4}
                                        fullWidth
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={props.sendMessage} variant="contained" color="primary">
                                    등록
                                </Button>
                                <Button onClick={props.handleClosed} color="primary">
                                    닫기
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                </ButtonGroup>
            </Box>
            <Box sx={{height: 400, width: '100%'}}>
                <Typography>
                    {props.gridOption.title}
                </Typography>
                <DataGrid
                    sx={{
                        boxShadow: 2,
                        border: 2,
                        borderColor: 'primary.light', '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        }
                    }}
                    autoHeight
                    checkboxSelection
                    disableColumnFilter
                    density={"compact"}
                    rows={props.rows}
                    rowCount={props.totalRows}
                    columns={props.gridOption.columns}
                    pageSize={props.gridOption.pageSize}
                    rowsPerPageOptions={props.gridOption.rowPerPageOptions}
                    paginationMode="server"
                    onPageChange={(newPage) => props.setPage(newPage)}
                    sortingMode="server"
                    onSortModelChange={props.handleSortModelChange}
                    loading={props.loading}
                    onSelectionModelChange={(newSelectionModel) => {
                        props.setGridOption({
                            ...props.gridOption,
                            Ids: newSelectionModel
                        })
                    }}
                    onPageSizeChange={(data) => {
                        console.log(data)
                    }}
                />
            </Box>

            <>
                <Dialog
                    // disableBackdropClick
                    open={props.openUserDialog}
                    onClose={props.handleClosed}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                >
                    <DialogTitle id="form-dialog-title">
                        유저 정보 수정
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            유저 정보 수정
                        </DialogContentText>
                        <TextField
                            id="accountStopYn"
                            name="accountStopYn"
                            select
                            label="계정정지 여부"
                            defaultValue={props.userData.accountStopYn}
                            value={props.userData.accountStopYn}
                            onChange={props.handleChanged2}
                            sx={{marginTop: "10px", width: "30%"}}
                        >
                            <MenuItem key="Y" value="Y">정지</MenuItem>
                            <MenuItem key="N" value="N">활동</MenuItem>
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.saveUser} variant="contained" color="warning">
                            수정
                        </Button>
                        <Button onClick={props.handleClosed2} color="primary">
                            닫기
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </>
    )
}
