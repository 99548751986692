import {API_BASE_URL} from "../../constants";
import {request} from "../../util/APIUtils";

/**
 * [관리자] 생활기록부 관리 > 생활기록부가 등록된 목록조회 API
 * @param userLifeRecordRequest
 * @returns {Promise<* | void>}
 */
export function requestUserLifeRecordList(userLifeRecordRequest) {
    return request({
        url: API_BASE_URL + "/admin/user/life-record/list",
        method: 'POST',
        body: JSON.stringify(userLifeRecordRequest)
    });
}

/**
 * [관리자] 생활기록부 관리 > 선택한 학생의 생활기록부 정보조회 API
 *
 * @param memberId
 * @returns {Promise<* | void>}
 */
export function requestUserLifeRecordInfo(memberId) {
    return request({
        url: API_BASE_URL + "/admin/user/life-record/info/" + memberId,
        method: 'GET',
    });
}