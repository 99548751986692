import { API_BASE_URL} from "../../constants";
import {request} from "../../util/APIUtils";

export function reqeustUpdateSetting(settingCode,settingFlag) {
    return request({
        url: API_BASE_URL + "/admin/setting/update?settingCode="+settingCode+"&settingFlag="+settingFlag,
        method: 'PUT',
        body: ""
    });
}

/**
 * [관리자] 환경 설정 정보 가져오기 (단일)
 *
 * @param mockExamScheduleRequest
 * @returns {Promise<* | void>}
 */
export function requestGetSettingInfo(settingCode) {
    return request({
        url: API_BASE_URL + "/setting/info?settingCode="+settingCode,
        method: 'GET',
    });
}