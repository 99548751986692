import React, {Component} from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PaymentOrder from "./paymentOrder/PaymentOrder.container";
import PaymentCancel from "./paymentCancel/PaymentCancel.container";
import Production from "./production/Production.container";
import Coupon from "./coupon/Coupon.container";

/**
 * 결제 주문 관리 Main
 */
class PaymentMain extends Component {
    constructor(props, context, _) {
        super(props, context);
        //state의 초깃값 설정하기
        this.state = {
            value: '0',
        };
    }

    render() {
        const handleChange = (event, newValue) => {
            this.setState({value: newValue});
        };

        return (
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={this.state.value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="상품 관리" value="0"/>
                            <Tab label="결제 주문내역" value="1"/>
                            <Tab label="결제 취소 관리" value="2"/>
                            <Tab label="쿠폰 관리" value="3"/>
                        </TabList>
                    </Box>
                    <TabPanel value="0"><Production/></TabPanel>
                    <TabPanel value="1"><PaymentOrder/></TabPanel>
                    <TabPanel value="2"><PaymentCancel/></TabPanel>
                    <TabPanel value="3"><Coupon/></TabPanel>
                </TabContext>
            </Box>
        );
    }
}

PaymentMain.propTypes = {};

export default PaymentMain;