import * as React from 'react';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import {logout} from '../api/LoginAPI';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Button from "@mui/material/Button";
import {ReactComponent as HeaderLogo} from "../asset/image/headerLogo.svg";
import DashBoardMain from "./dashboard/DashBoardMain";
import MockExamMain from "./mock/MockExamMain";
import EssayMain from "./essay/EssayMain";
import UserMain from "./user/UserMain";
import OfficerMain from "./officer/OfficerMain";
import PaymentMain from "./pay/PaymentMain";
import CommonCodeMain from "./commonCode/CommonCodeMain";
import StudentRecordMain from "./studentRecord/StudentRecordMain";
import SubjectMain from "./subject/SubjectMain";
import BoardMain from "./board/BoardMain";
import MainListItems from "./listItems";
import LifeRecordMain from "./lifeRecord/LifeRecordMain";

const drawerWidth = 240;

// function Copyright(props) {
//   return (
//       <Typography variant="body2" color="text.secondary" align="center" {...props}>
//         {'Copyright © '}
//         <Link color="inherit" href="https://mui.com/">
//           Your Website
//         </Link>{' '}
//         {new Date().getFullYear()}
//         {'.'}
//       </Typography>
//   );
// }

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#ffffff",
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            backgroundColor: "background.paper",
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const mdTheme = createTheme();

function DashboardContent({setIsLogin}) {

    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleLogOut = () => {
        logout().then((response) => {
            console.log("logout")
            localStorage.clear();
            setIsLogin(false);
        })
    }


    return (
        <ThemeProvider theme={mdTheme}>
            <BrowserRouter>
                <Box sx={{display: 'flex'}}>

                    <CssBaseline/>
                    <AppBar position="absolute" open={open}>
                        <Toolbar
                            sx={{
                                pr: '24px', // keep right padding when drawer closed
                            }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                sx={{
                                    marginRight: '36px',
                                    color: "#000000",
                                    ...(open && {display: 'none'}),
                                }}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Typography>
                                <HeaderLogo/>
                            </Typography>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{flexGrow: 1}}
                            >
                            </Typography>
                            <IconButton color="inherit">
                                <Badge badgeContent={4} color="secondary">
                                    <NotificationsIcon/>
                                </Badge>
                            </IconButton>

                            <Button variant="outlined" style={{
                                borderRadius: 35,
                                backgroundColor: "#ffffff",
                                fontSize: "14px",
                                marginLeft: "8px"

                            }} onClick={handleLogOut}>
                                logout
                            </Button>

                        </Toolbar>

                    </AppBar>
                    <Drawer variant="permanent" open={open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            <IconButton onClick={toggleDrawer} style={{backgroundColor: "#ffffff"}}>
                                <ChevronLeftIcon/>
                            </IconButton>
                        </Toolbar>
                        <Divider/>
                        <List component="nav" sx={{bgcolor: "background.paper"}}>
                            <MainListItems/>
                        </List>
                    </Drawer>
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: 'background.paper',
                            flexGrow: 1,
                            height: '100vh',
                        }}
                    >
                        <Toolbar/>
                        <Routes>
                            <Route exact path="/" element={<DashBoardMain/>}/>
                            {/*유저 관리*/}
                            <Route exact path="/user" element={<UserMain/>}/>
                            {/*사정단 관리*/}
                            <Route path="/officer" element={<OfficerMain/>}/>
                            {/*생기부 관리*/}
                            <Route path="/lifeRecord" element={<LifeRecordMain/>}/>
                            {/*모의 관리*/}
                            <Route path="/mockexam" element={<MockExamMain/>}/>
                            {/*논술 관리*/}
                            <Route path="/essay" element={<EssayMain/>}/>
                            {/*학종 관리*/}
                            <Route path="/studentRecord" element={<StudentRecordMain/>}/>
                            {/*교과 관리*/}
                            <Route path="/subject" element={<SubjectMain/>}/>
                            {/*결제 관리*/}
                            <Route path="/pay" element={<PaymentMain/>}/>
                            {/*공통 코드 관리*/}
                            <Route path="/commonCode" element={<CommonCodeMain/>}/>
                            {/*게시판 관리*/}
                            <Route path="/board" element={<BoardMain/>}/>
                        </Routes>
                    </Box>
                </Box>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default function Dashboard({setIsLogin}) {
    return <DashboardContent setIsLogin={setIsLogin}/>;
}
