import React, {Component} from 'react';
import Grid from "@mui/material/Grid";
import {GridColDef} from "@mui/x-data-grid";

import BasicSearchListComponent from "../list/BasicSearchListComponent";
import {requestEssayExcelUpload, requestEssayList, requestRemoveEssayList} from "../../api/essay/EssayAPI";

/**
 * 논술 관리 > 논술 통합정보 및 최저등급 관리 탭
 */
class EssayManage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            dense: false,
            secondary: false,
        }
    }

    componentDidMount() {

    }

    render() {
        const essayManageColumns: GridColDef[] = [
            //논술 통합 DB "essay_list_tb"
            {field: 'id', headerName: 'ID', width: 50, hide: true},
            {field: 'collegeCode', headerName: '대학교코드', headerAlign: "center", width: 80},
            {field: 'admissionSeriesCode', headerName: '전형계열코드', headerAlign: "center", width: 100},
            {field: 'recruitmentUnit', headerName: '모집단위명', headerAlign: "center", width: 300},
            {field: 'typeTime', headerName: '전형시간', headerAlign: "center", width: 100},
            {field: 'essaySubject', headerName: '논술과목', headerAlign: "center", width: 100},
            {field: 'examinationTendency', headerName: '출제경향', headerAlign: "center", width: 180},
            {field: 'susi', headerName: '수시', headerAlign: "center", width: 80},
            {field: 'competitionRate', headerName: '작년경쟁률', headerAlign: "center", width: 80},
            {field: 'essayType', headerName: '논술유형', headerAlign: "center", width: 80},
            {field: 'typeRate', headerName: '학생부비율', headerAlign: "center", width: 130},
            {field: 'studentRecruitmentNum', headerName: '모집인원', headerAlign: "center", width: 80},
            {field: 'admissionDate', headerName: '전형날짜', headerAlign: "center", width: 150},
            {field: 'admissionTime', headerName: '전형시각', headerAlign: "center", width: 150},
            {field: 'commonScience', headerName: '통합과학', headerAlign: "center", width: 80},
            {field: 'mulone', headerName: '물1', headerAlign: "center", width: 80},
            {field: 'hwaone', headerName: '화1', headerAlign: "center", width: 80},
            {field: 'sangone', headerName: '생1', headerAlign: "center", width: 80},
            {field: 'jiown', headerName: '지1', headerAlign: "center", width: 80},
            {field: 'mulonetwo', headerName: '물1+물2', headerAlign: "center", width: 80},
            {field: 'hwaonetwo', headerName: '화1+화2', headerAlign: "center", width: 80},
            {field: 'sangonetwo', headerName: '생1+생2', headerAlign: "center", width: 80},
            {field: 'jiowntwo', headerName: '지1+지2', headerAlign: "center", width: 80},
            {field: 'selectScienceSubject', headerName: '선택과목', headerAlign: "center", width: 80},
            {field: 'commonMath', headerName: '공통수학', headerAlign: "center", width: 80},
            {field: 'suoneSutwo', headerName: '수1+수2', headerAlign: "center", width: 80},
            {field: 'pbSt', headerName: '확통', headerAlign: "center", width: 80},
            {field: 'dfIn', headerName: '미적', headerAlign: "center", width: 80},
            {field: 'geometry', headerName: '기하', headerAlign: "center", width: 80},
            {field: 'selectMathSubject', headerName: '선택과목2', headerAlign: "center", width: 80},
            //start-논술_최저등급_테이블 "essay_lowest_grade_list_tb"
            {field: 'content', headerName: '최저내용', headerAlign: "center", width: 200},
            {field: 'lowestUse', headerName: '최저학력기준_반영여부', headerAlign: "center", width: 100},
            {field: 'lowestKorean', headerName: '최저국어', headerAlign: "center", width: 100},
            {field: 'lowestMath', headerName: '최저수학', headerAlign: "center", width: 100},
            {field: 'lowestMigi', headerName: '최저수학(미/기)', headerAlign: "center", width: 100},
            {field: 'lowestEnglish', headerName: '최저영어', headerAlign: "center", width: 100},
            {field: 'lowestSociety', headerName: '최저사탐', headerAlign: "center", width: 100},
            {field: 'lowestScience', headerName: '최저과탐', headerAlign: "center", width: 100},
            {field: 'lowestCal', headerName: '탐계산', headerAlign: "center", width: 100},
            {field: 'lowestCount', headerName: '탐 갯수', headerAlign: "center", width: 100},
            {field: 'lowestSum', headerName: '최저합 (~이내)', headerAlign: "center", width: 100},
            {field: 'lowestHistory', headerName: '한국사 (~이내)', headerAlign: "center", width: 100},

            //-end
            {field: 'suNosul', headerName: '수리논술대학', headerAlign: "center", width: 130},
            {field: 'crossSupport', headerName: '이과교차지원', headerAlign: "center", width: 130},
            {field: 'scienceDesignation', headerName: '과학필수지정대학', headerAlign: "center", width: 130},
            {field: 'exceptNonsul', headerName: '그외논술전형대학', headerAlign: "center", width: 130},
            {field: 'munMathen', headerName: '문과수리영어포함', headerAlign: "center", width: 130},
            {field: 'rthreeEtcFlag', headerName: '의치한약수기타기능선택플래그', headerAlign: "center", width: 180},
            {field: 'rthreeEtcCode', headerName: '의치한약수기타기능과목', headerAlign: "center", width: 180},
            {field: 'rthreeRegionInfo', headerName: '의치한약수고등학교소재지', headerAlign: "center", width: 180},
            {field: 'rthreeRegionFlag', headerName: '의치한약수지역인재', headerAlign: "center", width: 180}
        ];

        return (
            <Grid container spacing={1}>
                <BasicSearchListComponent
                    init={requestEssayList}
                    removeEvent={requestRemoveEssayList}
                    excelupload={requestEssayExcelUpload}
                    columns={essayManageColumns}
                    addButtonUse={false}
                    title={"> 논술 통합정보 및 최저등급 목록 (조회조건 : 대학교코드)"}
                />
            </Grid>
        );
    }
}

export default EssayManage;