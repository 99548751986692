import { API_BASE_URL } from "../../constants";
import { request } from "../../util/APIUtils";

/**
 * [관리자] 상품 코드 조회
 * @param paymentRequest
 * @returns {Promise<* | void>}
 */
export function requestProductCodeList(paymentRequest) {
  return request({
    url: API_BASE_URL + "/admin/product/productType",
    method: "GET",
  });
}

/**
 * [관리자] 결제 주문내역 조회
 *
 * @param requestPaymentsList
 * @returns {Promise<* | void>}
 */
export function requestPaymentsList(paymentRequest) {
  return request({
    url: API_BASE_URL + "/admin/pay/payments/list",
    method: "POST",
    body: JSON.stringify(paymentRequest),
  });
}

/**
 * [관리자] 결제 주문 취소 요청내역 조회
 *
 * @param paymentRequest
 * @returns {Promise<* | void>}
 */
export function requestPaymentsCancelRequestList(paymentRequest) {
  return request({
    url: API_BASE_URL + "/admin/pay/payments/cancel/request/list",
    method: "POST",
    body: JSON.stringify(paymentRequest),
  });
}

/**
 * [관리자] 결제 취소
 *
 * @param PaymentsCancel
 * @returns {Promise<* | void>}
 */
export function requestPaymentsCancel(PaymentsCancel) {
  return request({
    url: API_BASE_URL + "/pay/payments/cancel",
    method: "POST",
    body: JSON.stringify(PaymentsCancel),
  });
}

/**
 * [관리자] 서비스 취소 요청 처리
 * @param paymentRequest
 * @returns {Promise<* | void>}
 */

export function requestCancel(paymentRequest) {
  return request({
    url: API_BASE_URL + "/pay/payments/cancel/request",
    method: "POST",
    body: JSON.stringify(paymentRequest),
  });
}

/**
 * [관리자] 상품 목록 조회
 * @param paymentRequest
 * @returns {Promise<* | void>}
 */
export function requestProductionList(paymentRequest) {
  return request({
    url: API_BASE_URL + "/admin/product/productions",
    method: "POST",
    body: JSON.stringify(paymentRequest),
  });
}

/**
 * [관리자] 상품 및 결제 관리 > 상품관리 > 서비스 목록 조회
 * @param paymentRequest
 * @returns {Promise<* | void>}
 */
export function requestServiceList(paymentRequest) {
  return request({
    url: API_BASE_URL + "/admin/product/services",
    method: "POST",
    body: JSON.stringify(paymentRequest),
  });
}

/**
 * [관리자] 상품 및 결제 관리 > 상품관리 > 서비스 조회(단건)
 * @param serviceId
 * @returns {Promise<* | void>}
 */
export function requestServiceInfo(paymentRequest) {
  return request({
    url: API_BASE_URL + "admin/service/info/",
    method: "POST",
    body: JSON.stringify(paymentRequest),
  });
}

/**
 * [관리자] 상품 및 결제 관리 > 상품관리 > 싱픔 서비스 등록
 * @param paymentRequest
 * @returns {Promise<* | void>}
 */
export function requestSaveService(paymentRequest) {
  return request({
    url: API_BASE_URL + "/admin/service/add",
    method: "PUT",
    body: JSON.stringify(paymentRequest),
  });
}

/**
 * [관리자] 상품 및 결제 관리 > 상품관리 > 상품 서비스 삭제
 * @param serviceId
 * @returns {Promise<* | void>}
 */
export function requestDeleteService(serviceId) {
  console.log(serviceId);
  return request({
    url: API_BASE_URL + "/admin/service/remove/" + serviceId.ids,
    method: "DELETE",
  });
}

/**
 * [관리자] 상품 및 결제 관리 > 쿠폰관리 > 쿠폰 목록 조회 API
 *
 * @param couponRequest
 * @returns {Promise<* | void>}
 */
export function requestCouponList(couponRequest) {
  return request({
    url: API_BASE_URL + "/admin/coupon/list",
    method: "POST",
    body: JSON.stringify(couponRequest),
  });
}

/**
 * [관리자] 상품 및 결제 관리 > 쿠폰관리 > 쿠폰 등록 API
 *
 * @param couponRequest
 * @returns {Promise<* | void>}
 */
export function requestAddCoupon(couponRequest) {
  return request({
    url: API_BASE_URL + "/admin/coupon/add",
    method: "POST",
    body: JSON.stringify(couponRequest),
  });
}

/**
 * [관리자] 상품 및 결제 관리 > 쿠폰관리 > 쿠폰 수정 API
 *
 * @param couponRequest
 * @returns {Promise<* | void>}
 */
export function requestUpdateCoupon(couponRequest) {
  return request({
    url: API_BASE_URL + "/admin/coupon/update",
    method: "PUT",
    body: JSON.stringify(couponRequest),
  });
}

/**
 * [관리자] 상품 및 결제 관리 > 쿠폰관리 > 쿠폰 삭제 API
 *
 * @param couponRequest
 * @returns {Promise<* | void>}
 */
export function requestRemoveCoupon(couponRequest) {
  return request({
    url: API_BASE_URL + "/admin/coupon/delete",
    method: "POST",
    body: JSON.stringify(couponRequest),
  });
}

/**
 * [관리자] 상품 시스템코드 조회
 * @param paymentRequest
 * @returns {Promise<* | void>}
 */
export function requestProductTypeList(paymentRequest) {
  return request({
    url: API_BASE_URL + "/admin/product/productType",
    method: "GET",
  });
}

/**
 * [관리자] 상품 및 결제 관리 > 상품관리 > 싱픔 서비스 수정
 * @param paymentRequest
 * @returns {Promise<* | void>}
 */
export function requestUpdateService(paymentRequest) {
  return request({
    url: API_BASE_URL + "/admin/service/update",
    method: "POST",
    body: JSON.stringify(paymentRequest),
  });
}
