import React, {useEffect, useRef, useState} from 'react';
import Grid from "@mui/material/Grid";
import {
    requestAddMockSchedule,
    requestMockScheduleExcelUpload,
    requestMockScheduleList,
    requestRemoveMockSchedule,
    requestUpdateMockSchedule
} from "../../../api/mock/MockAPI";
import MockExamScheduleUI from "./MockExamSchedule.presenter";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import LoadingComponent from "../../common/loadding/LoadingComponent";

/**
 * 모의고사 스케쥴 관리
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function MockExamSchedule(props) {
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [searchWord, setSearchWord] = useState("");//검색어 입력
    const fileInput = useRef();//엑셀업로드
    //그리드 옵션
    const [mockExamScheduleGridOption, setMockExamScheduleGridOption] = useState(
        {
            columns: [
                {field: 'id', headerName: 'ID', width: 70, hide: true},
                {
                    field: "actions",
                    headerClassName: "super-app-theme--header",
                    headerName: "Actions",
                    headerAlign: "center",
                    width: 80,
                    disableColumnMenu: true,
                    editable: false,
                    renderCell: (params) => (
                        <>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <IconButton color="primary" onClick={() => editMockExamSchedule(params.row)}>
                                    <EditIcon/>
                                </IconButton>
                            </Box>
                        </>
                    )
                },
                {field: 'mockexamYear', headerName: '모의고사 년도', headerAlign: "center", width: 130, align: 'center'},
                {field: 'mockexamMonth', headerName: '모의고사 월', headerAlign: "center", width: 130, align: 'center'}
            ],
            rows: [],
            title: "> 모의고사 스케쥴 목록 (조회조건 : 조회년도)",
            rowPerPageOptions: [15],
            pageSize: 15,
            Ids: []
        }
    )

    /**
     * 초기화 데이터
     */
    let initialFormData = {
        id: null,
        mockexamYear: "",
        mockexamMonth: "",
    };
    // 다이얼로그 데이터 초기화
    const [mockExamScheduleData, setMockExamScheduleData] = useState(initialFormData);
    // 다이얼로그 Open 여부 초기화
    const [openMockExamScheduleDialog, setOpenMockExamScheduleDialog] = useState(false);


    /**
     * 검색어 입력 변경 이벤트
     */
    const onChangeSearchWord = (e) => {
        setSearchWord(e.target.value);
    }

    /**
     * 검색 버튼 클릭 이벤트
     */
    const onClickSearch = async () => {
        const newRows = await searchList();
        setRows(newRows.list);
        setTotalRows(newRows.totalCount);
    }

    /**
     * 검색목록 조회
     */
    function searchList() {
        return new Promise((resolve) => {
            setTimeout(() => {
                const body = {
                    searchWord: searchWord,
                    page: page * mockExamScheduleGridOption.pageSize,
                    pageSize: mockExamScheduleGridOption.pageSize
                }
                requestMockScheduleList(body).then((response) => {
                    resolve(response);
                })
            }, Math.random() * 100 + 100)
        });
    }

    /**
     * 다이얼로그 추가/수정 이벤트
     */
    const saveMockExamSchedule = async (e) => {
        try {
            e.preventDefault();

            const mockexamMonthRange = [6, 9, 11];

            if (!mockexamMonthRange.includes(mockExamScheduleData.mockexamMonth)) {
                alert('모의고사는 6월, 9월, 11월만 등록가능 합니다.')
                return
            }

            if (mockExamScheduleData.id) {
                requestUpdateMockSchedule(mockExamScheduleData).then(response => {
                    if (response.status < 400) {
                        alert('수정이 완료되었습니다.');
                    } else {
                        alert('수정에 실패하였습니다.\n실패 사유 : ' + response.message);
                    }
                }).finally(async () => {
                    setOpenMockExamScheduleDialog(false);
                    await clearMockExamScheduleData();
                    const newRows = await searchList();
                    setRows(newRows.list);
                    setTotalRows(newRows.totalCount);
                })
            } else {
                requestAddMockSchedule(mockExamScheduleData).then(response => {
                    if (response.status < 400) {
                        alert('등록이 완료되었습니다.');
                    } else {
                        alert('등록에 실패하였습니다.\n실패 사유 : ' + response.message);
                    }
                }).finally(async () => {
                    setOpenMockExamScheduleDialog(false);
                    await clearMockExamScheduleData();
                    const newRows = await searchList();
                    setRows(newRows.list);
                    setTotalRows(newRows.totalCount);
                })
            }
        } catch (err) {
            alert(err.message);
        }
    };

    /**
     * 다이얼로그 수정창 띄우기 이벤트
     */
    const editMockExamSchedule = async (mockExamScheduleData) => {
        setOpenMockExamScheduleDialog(true);
        setMockExamScheduleData(mockExamScheduleData);
    };

    /**
     * 삭제 버튼 클릭 이벤트
     */
    const onClickDeleteButton = e => {
        if (mockExamScheduleGridOption.Ids.length) {
            requestRemoveMockSchedule({ids: mockExamScheduleGridOption.Ids}).then(response => {
                if (response.status < 400) {
                    alert('삭제가 완료되었습니다.');
                } else {
                    alert('삭제가 실패하였습니다.\n실패 사유 : ' + response.message);
                }
            }).finally(async () => {
                const newRows = await searchList();
                setRows(newRows.list);
                setTotalRows(newRows.totalCount);
            });
        } else {
            alert('1건 이상 선택해 주세요!');
        }
    }

    /**
     * 엑셀 업로드 파일 클릭 이벤트
     */
    const onClickExcelUploadFile = e => {
        fileInput.current.click();
    };

    /**
     * 엑셀 업로드 이벤트
     */
    const excelUpload = e => {
        // console.log(e.target.files[0]);
        const formData = new FormData();
        formData.append("file", fileInput.length && fileInput[0].uploadedFile);
        setLoadingOpen(true);
        requestMockScheduleExcelUpload(e.target.files[0]).then(response => {
            if (response.data.status) {
                alert("엑셀 업로드가 완료되었습니다.")
            } else {
                alert('엑셀 업로드가 실패하였습니다.\n실패 사유 : ' + response.data.message);
            }
            setLoadingOpen(false);
        }).finally(async () => {
            fileInput.current.value = "";
            const newRows = await searchList();
            setRows(newRows.list);
            setTotalRows(newRows.totalCount);
        })
    }

    /**
     * 다이얼로그 값 초기화
     */
    const clearMockExamScheduleData = async () => {
        setMockExamScheduleData(initialFormData);
    };

    /**
     * 다이얼로그 값 변경 이벤트
     */
    const handleDateChange = (newValue) => {
        const inputYear = newValue.$y;
        const inputMonth = newValue.$M + 1;

        setMockExamScheduleData({
            ...mockExamScheduleData,
            mockexamYear: inputYear,
            mockexamMonth: inputMonth
        });
    };

    /**
     * 다이얼로그 열기 이벤트
     */
    const handleClickedOpen = () => {
        setOpenMockExamScheduleDialog(true);
    };

    /**
     * 다이얼로그 닫기 이벤트
     */
    const handleClosed = () => {
        setOpenMockExamScheduleDialog(false);
        clearMockExamScheduleData();
    };

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            const newRows = await searchList();

            if (!active) {
                return;
            }

            setRows(newRows.list);
            setTotalRows(newRows.totalCount);
            setLoading(false);
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <Grid container spacing={1}>
            <LoadingComponent open={loadingOpen}/>
            <MockExamScheduleUI
                gridOption={mockExamScheduleGridOption}
                rows={rows}
                totalRows={totalRows}
                setPage={setPage}
                loading={loading}
                setGridOption={setMockExamScheduleGridOption}
                onChangeSearchWord={onChangeSearchWord}
                onClickSearch={onClickSearch}

                mockExamScheduleData={mockExamScheduleData}
                handleClickedOpen={handleClickedOpen}
                handleClosed={handleClosed}
                handleDateChange={handleDateChange}
                openMockExamScheduleDialog={openMockExamScheduleDialog}
                saveMockExamSchedule={saveMockExamSchedule}
                onClickDeleteButton={onClickDeleteButton}

                excelUpload={excelUpload}
                excelUploadFile={fileInput}
                onClickExcelUploadFile={onClickExcelUploadFile}
            />
        </Grid>
    );
}